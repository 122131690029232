$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-panel {
  display: grid;
  grid-auto-rows: auto;
  border-radius: 20px;
  overflow: hidden;
  gap: 1px;

  &--item {
    padding: 20px 16px;
    background-color: #F6F6F4;
  }
}
