$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-vip {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    &__item {
        background: #FFFFFF;
        border-radius: 20px;
        padding: 23px 65px 16px 20px;
        position: relative;
        color: $color-grey;
        &:nth-child(1) {
            background: $color-yellow;
            color: $color-black;
        }
        @include bp1280 {
            padding: 23px 20px 16px;
        }
        @include bp1680 {
            padding: 23px 121px 10px 28px;
        }
    }
    &__title {
        @include text(28px, 35px, bold, $color-black);
        font-family: var(--font-family-main);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-bottom: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &:lang(el) {
            font-size: 16px;

            @include bp1680 {
                font-size: 20px;
            }
        }
    }
    &__text {
        @include text(14px, 20px, normal);
        letter-spacing: 0.03em;
        margin-bottom: 4px;
        @include bp1680 {
            @include text(16px, 24px, normal);
            margin-bottom: 7px;
        }

        &:lang(el) {
            max-width: 70%;
        }
    }
    &__value {
        @include text(48px, 56px, bold);
        font-family: var(--font-family-main);
        letter-spacing: 0.08em;
        text-transform: uppercase;
        @include bp1680 {
            @include text(72px, 72px, bold);
        }

        &:lang(el) {
            @include maxbp1440 {
                font-size: 40px;
            }
        }
    }
    &__img {
        width: 152px;
        height: 152px;
        position: absolute;
        right: -16px;
        bottom: 4px;
        @include bp1680 {
            width: 200px;
            height: 200px;
            right: -4px;
            bottom: -32px;
        }

        &:lang(el) {
            right: -20px;
        }
    }
}
