.christmas-tournament {
  &-wrap {
    width: 100%;
    margin-top: 20px;
    @include breakpoint($bp1) {
      margin-top: 38px;
    }
  }

  &-block {
    padding: 4px;
    border-radius: 12px;
    margin-bottom: 24px;
    width: 567px;
    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint($bp1) {
      width: 50%;
      margin-right: 12px;
      margin-bottom: inherit;
      &:last-child {
        margin-right: 0;
        margin-left: 12px;
      }
    }
  }

  &__content {
    @include breakpoint($bp1) {
      flex-direction: row;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

.christmas-tournament {
  background: linear-gradient(202.6deg, #f5e2c2 6.67%, #997e58 32.47%, #856243 89.66%);
  box-shadow: inset 0 4px 3px rgba(255, 255, 255, 0.4), inset 0px -3px 3px rgba(255, 255, 255, 0.26);
  border-radius: 12px;
  position: relative;
  width: 100%;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    content: '';
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background: repeating-linear-gradient(-45deg, #193c44, #193c44 6px, #13313a 6px, #13313a 15px);
  }

  &__wrap {
    display: grid;
    grid-gap: 12px;
    align-items: center;
    padding: 12px;
    position: relative;
    cursor: pointer;
    grid-template-columns: 88px 1fr 44px;
  }

  &__desc {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    text-align: left;
    letter-spacing: 0.02em;
    color: #87f0fc;
    text-shadow: 0 3px 3px rgba(2, 41, 40, 0.25);

    font-size: 20px;
  }

  &__text {
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    text-align: left;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0 3px 3px rgba(2, 41, 40, 0.25);

    font-size: 44px;
  }

  &__img {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    width: 88px;
    height: 88px;
  }

  &__arrow {
    width: 32px;
    height: 32px;
    background-image: url('#{$cdn}/holidays/christmas/arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    filter: invert(100%) sepia(38%) saturate(2%) hue-rotate(63deg) brightness(110%) contrast(101%);
  }
}
