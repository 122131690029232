.document-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid #00c853;
  padding: 12px;
  flex-wrap: wrap;

  &.has-error {
    border-color: #e66a87;
  }
  &__ico {
    width: 32px;
    height: 48px;
    background-image: url($cdn + '/dvs/i-document.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 12px;
    flex-shrink: 0;
  }
  &__info {
    flex: 1 1 auto;
    width: calc(100% - 88px);
  }
  &__title {
    font-size: 16px;
    line-height: 24px;
    color: #524e72;
    @include ellipsis;
    width: 95%;

    .has-error & {
      color: #f44336;
    }
  }
  &__size {
    font-size: 14px;
    line-height: 20px;
    color: #8c87b3;
    .has-error & {
      color: #f44336;
    }
  }
  &__close {
    width: 20px;
    height: 20px;
    background-image: url($cdn + '/dvs/i-close-rounded.svg');
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    flex-shrink: 0;
  }
  &__errors {
    margin-top: 4px;
    color: #f44336;
    font-size: 12px;
    line-height: 16px;
    flex: 0 1 100%;
  }
  .has-error {
    border: 1px solid #e66a87;
    color: #f44336;
  }
}
