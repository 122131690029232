.christmas-inactive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 290px auto 360px;
  width: 740px;
  padding: 8px;
  text-align: center;
  border-radius: 32px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 148px;
    height: 64px;
    left: calc(50% - 148px / 2);
    top: -29px;
    background-image: url($cdn + '/holidays/christmas/bells.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/holidays/christmas/bells@2x.png');
      background-repeat: no-repeat;
    background-size: contain;
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url($cdn + '/holidays/christmas/bells@3x.png');
      background-repeat: no-repeat;
    background-size: contain;
    }
  }

  &__content {
    border-radius: 24px;
    width: 100%;
    padding: 36px 52px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      border-radius: 24px;
      background-image: url($cdn + '/holidays/christmas/glitter.png');
      background-size: cover;
      background-position: center;
      z-index: 1;
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/holidays/christmas/glitter@2x.png');
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url($cdn + '/holidays/christmas/glitter@3x.png');
      }
    }
    & > * {
      z-index: 1;
    }
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 800;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #87f0fc;
    text-shadow: 0px 3px 3px rgba(2, 41, 40, 0.25);
    position: relative;
  }

  &__text {
    margin-bottom: 24px;
    font-weight: 800;
    font-size: 36px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-shadow: 0px 3px 3px rgba(2, 41, 40, 0.25);
    position: relative;
  }

  &__btn {
    display: inline-block;
    line-height: 46px;
    min-width: 318px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
  }
}
