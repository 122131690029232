$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sportpage{
  background: #E7E7E4;
  padding:24px 0;
  min-height: calc(100vh - 56px);

  @include bp1440 {
    min-height: calc(100vh - 72px);
  }
}
