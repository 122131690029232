$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
/*стили дефолтные для текста попапа - @include text(14px, 20px, null, $color-black);*/
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  padding: 60px 0;
  transition: 0.2s;
  background: rgba(0, 0, 0, 0);
  max-height: 100%;
  overflow: auto;
  animation: popupShowBg 0.2s linear;
  animation-fill-mode: forwards;
  @include text(14px, 20px, null, $color-black);

  &.is-full {
    padding: 0;
  }

  &.is-modal {
    z-index: 2000;
  }

  &_missed-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__wrap {
    position: relative;
    width: 400px;
    background: $color-gray-light;
    box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
    border-radius: 20px;
    margin: 0 auto;
    padding: 56px 60px 60px;
    animation: popupShowWrap 0.2s linear;

    &.is-euro-popup {
      padding-left: 40px;
      padding-right: 40px;
    }

    &.is-trustly {
      width: 676px;
      padding-left: 48px;
      padding-right: 48px;
      padding-top: 0;
    }
    &.is-ferrari-popup {
      max-width: 960px;
      width: 100%;
      max-height: 100%;
      padding: 0 56px;
      text-align: center;
      &::before {
        content: '';
        width: 100%;
        z-index: 1;
        filter: blur(20px);
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: 0;
        box-shadow: 0 -5px 35px 35px #19191d;
      }
      & .ferrari-terms-info {
        max-width: 100%;
        max-height: 100%;
        &::before {
          display: none;
        }
      }
    }
    &.is-wide {
      max-width: 960px;
      width: 100%;
      max-height: 100%;

      &::after {
        content: '';
        position: absolute;
        width: inherit;
        height: 24px;
        left: 50%;
        transform: translate(-50%);
        bottom: 35px;
        background: linear-gradient(180deg, rgba(246, 246, 244, 0) 0%, #f6f6f4 100%);
      }
    }

    &.has-bar {
      padding: 56px 60px 0;
    }

    &.is-big {
      padding: 72px 60px 0;
      width: 480px;
      min-height: 192px;
    }

    &.is-wcancel {
      width: 896px;
      padding: 20px 71px 80px;
    }
  }
  &__description {
    @include text(18px, 24px);
    text-align: center;
  }

  &__top {
    background-color: $color-yellow;
    @include text(32px, 44px, bold);
    @include text-dots;
    max-width: initial;
    border-radius: 20px 20px 0 0;
    padding: 10px 48px;
    text-align: center;
    margin: 0 -48px 20px;
    font-family: var(--font-family-main);
  }

  &__content {
    animation: popupShowContent 0.2s linear;
    animation-delay: 0.1s;
    animation-fill-mode: backwards;
  }

  &__item {
    .popup__text {
      display: none;
    }

    &:first-child {
      .popup__text {
        display: block;
      }
    }

    &:not(:last-child) {
      &:after {
        display: block;
        width: 100%;
        height: 20px;
        margin: 10px 0 14px;
        color: #2d2d32;
        content: '+';
        text-align: center;
        font-weight: 700;
        font-size: 30px;
      }

      .autobonus-btns__wrap {
        display: none;
      }
    }
  }

  &__close {
    position: absolute;
    top: 24px;
    inset-inline-end: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: var(--icon-close);
    filter: $filter-grey;
  }

  &__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    font-family: var(--font-family-main);
    text-transform: uppercase;
    text-align: center;

    &.is-euro-title {
      font-family: $font-roboto;
    }
  }

  &__text {
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;

    &.normal-title {
      margin: 20px 0 12px;
    }

    &.is-random {
      font-size: 18px;
      margin-top: 32px;
    }
  }

  &__bar {
    background: #ffffff;
    border-radius: 0 0 20px 20px;
    padding: 24px 30px;
    margin: 24px -60px 0;
    text-align: center;
    max-width: none;

    &.is-trustly {
      background-color: transparent;
      margin-top: 0;
      margin-bottom: -24px;
    }
  }

  &__list {
    margin-top: 8px;
    counter-reset: list;
  }

  &__list-item {
    @include text(16px, 24px);
    padding-top: 8px;
    counter-increment: list;
    display: flex;

    &::before {
      content: counter(list) '.';
      margin-right: 8px;
      font-weight: 700;
      min-width: 36px;
    }
  }

  &__btns {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    width: 100px;
    height: 100px;
    margin: -32px auto 0;
    background-image: url($cdn + '/fezbet/popups/login_2x.png');
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
  }

  &__form {
    display: block;
    margin-top: 16px;
  }
  &__block-50w {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 20px;
    align-items: baseline;
    margin-bottom: 28px;
  }
  &__links {
    margin: 20px;
    text-align: center;

    & a {
      text-decoration-line: underline;
      color: $color-grey;
    }
  }

  &__link {
    margin: 20px 5px 0;
  }

  &__trustly {
    margin-top: 12px;
    margin-bottom: 9px;
    position: relative;
  }

  &__row {
    background: $color-white;
    border-radius: 8px;
    padding: 8px 12px;
    margin-top: 16px;
    text-align: center;
  }

  &__val {
    @include text(20px, 24px, bold, $color-black);

    display: flex;
    gap: 6px;
    justify-content: center;
    padding-bottom: 4px;

    &._ltr {
      direction: ltr;
    }
  }

  &__key {
    @include text(14px, 20px, null, $color-grey);

    padding-bottom: 4px;
  }

  &__img-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }

  &__img {
    height: 38px;
    margin: 0 6px;

    &.is-lang {
      width: 52px;
    }
    &.is-trustly {
      width: 124px;
    }
  }

  .verification-popup__title:lang(el) {
    font-size: 30px;
  }
}

@keyframes popupShowBg {
  100% {
    background: rgba(25, 25, 25, 0.8);
  }
}

@keyframes popupShowWrap {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes popupShowContent {
  0% {
    opacity: 0;
  }
}
