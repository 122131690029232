$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-popup {
  position: relative;
  margin: 0 auto;
  width: 900px;
  min-height: 500px;
  background: #F6F6F4;
  box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  border-radius: 20px;
  padding: 35px 0 0;
  display: flex;
  flex-direction: column;

  &__contacts {
    width: 100%;
    border-radius: 0 0 12px 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 80px;

  }

  &__close {
    position: absolute;
    z-index: 10;
    top: 24px;
    inset-inline-end: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: var(--icon-close);
    filter: $filter-grey;
  }

  &__header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0 76px;
    margin-bottom: 4px;

    &.is-hidden {
      margin-bottom: 0;

      .cashbox-popup__link {
        pointer-events: none;
      }

      & > *:not(.cashbox-popup__ball) {
        opacity: 0;
      }
    }
  }


  &__content {
    flex-grow: 1;
    padding: 0 76px 80px;
    min-height: 300px;
  }

  &__ball {
    width: 80px;
    height: 80px;
    position: absolute;
    top: -40px;
    left: calc(50% - 40px);
    background-image: url($cdn + '/fezbet/elements/circle.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-size: 36px;
      background-position: center;
      background-repeat: no-repeat;
      filter: $filter-white;
    }
  }

  &__link {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 15px;
    margin: 0 30px;
    flex-basis: 33.3333%;
  }

  &__span {
    @include text(24px, 40px, 700, rgba(134, 138, 146, 0.3));
    @include text-dots;

    transition: 0.2s ease;
    text-transform: uppercase;
    font-family: var(--font-family-main);


    .cashbox-popup__link.is-active & {
      color: $color-black;
      font-size: 32px;

      &:lang(ar) {
        font-size: 24px;
      }
    }
  }

  &__text {
    @include text(14px, 20px, 400, $color-black);
    @include text-dots;

    margin-top: 12px;
    opacity: 0;
    transition: 0.2s ease;

    .cashbox-popup__link.is-active & {
      opacity: 1;
    }
  }

  &__label {
    @include text(12px, 14px, 700, $color-black);

    padding: 2px 8px;
    background-color: $color-yellow;
    border-radius: 18px;
    position: absolute;
    top: 0;
    right: 0;
  }
}
