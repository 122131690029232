.verification-btn {
  &_survey,
  &_doc {
    background: rgba(53, 134, 255, 1);
    border: none;
    border-radius: 30px;
    box-shadow: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    min-width: 180px;
    line-height: 20px;
    padding: 12px 24px;
    cursor: pointer;
    text-align: center;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 6px 8px rgba(30, 136, 229, 0.4);
    }

    &:focus {
      background: rgba(142, 195, 241, 1);
    }
  }
  &_again {
    background: #ffffff;
    border: 2px solid rgba(140, 135, 179, 0.2);
    border-radius: 30px;
    box-shadow: none;
    cursor: pointer;
    color: rgba(53, 134, 255, 1);
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    min-width: 86px;
    padding: 0 20px;
    text-align: center;
    transition: background-color 0.3s ease;

    &:hover {
      background: rgba(140, 135, 179, 0.1);
    }

    &:focus {
      background: rgba(140, 135, 179, 0.4);
    }
  }

  &:disabled {
    pointer-events: none;
    filter: grayscale(0.5);
    cursor: initial;
  }
}
