$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.easter-wrapper {
  min-height: calc(100vh - 106px);
  margin-bottom: -60px;
}

.popup {
  &.is-easter-popup {
    display: flex;
    padding: 22px 0;

    .easter-popup {
      margin: auto;
    }
  }
}