$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;


  @include bp1280 {
    grid-gap: 20px;
  }
  @include bp1280 {
    grid-gap: 24px;
  }
}
