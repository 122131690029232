$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-check {
  display: none;

  &__label {
    display: flex;
  }

  &__box {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 12px;
    cursor: pointer;
    border-radius: 4px;
    background-color: $color-white;
    border: 1px solid $color-grey;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 12px;
    }

    &:hover {
      border: 1px solid $color-black;
    }
  }

  &__text {
    @include text(16px, 20px, null, $color-black);

    & a {
      color: $color-black;
      text-decoration: underline;

      &:hover {
        color: $color-grey;
      }
    }
  }
}

.form-check:checked + label .form-check__box::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: var(--icon-i-choose);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  filter: $filter-green;

  &:lang(ar) {

  }
}

.form-check.ng-invalid.ng-touched + label,
.form-check.ng-invalid.ng-dirty + label {
  & .form-check__box {
    border: solid 1px $color-red;
  }
}
