$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
body.linda-has-user .reg-animation {
  & .popup-page__right {
    animation-name: registration;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 0s;
  }

  & .bnr-reg {
    animation-name: reg-bnr;
    animation-duration: 0.8s;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;

    &:lang(ar) {
      animation-name: reg-bnr-rtl;
    }
  }

  & .bnr-reg__title {
    animation-name: reg-title;
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  & .bnr-reg__subtitle {
    animation-name: reg-subtitle;
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  & .bnr-reg__top {
    animation-name: reg-top;
    animation-duration: 1.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  & .bnr-reg__description {
    animation-name: reg-desc;
    animation-duration: 1.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  & .bnr-reg__subtitle {
    animation-name: reg-subtitle;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;

    &:lang(hu),
    &:lang(el),
    &:lang(es),
    &:lang(cl),
    &:lang(de),
    &:lang(pt),
    &:lang(pe),
    &:lang(cs),
    &:lang(za) {
      animation-name: reg-subtitle-translate;
    }

    &:lang(tr) {
      animation-name: reg-subtitle-tr;
    }
  }

  & .bnr-reg__btn {
    animation-name: reg-btn;
    animation-duration: 1.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  & .bnr-reg__block-btns {
    animation-name: reg-btns;
    animation-duration: 2s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }

  & .bnr-reg__img {
    animation-name: reg-img;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;

    &:lang(ar) {
      animation-name: reg-img-rtl;
    }
  }
}

// reg-img

@keyframes reg-img {
  100% {
    z-index: 1;
    transform: translateY(-8px) scale(1.6);
  }
}

@keyframes reg-img-rtl {
  100% {
    z-index: 1;
    transform: translateY(-8px) scale(-1.6, 1.6);
  }
}

// reg-btns

@keyframes reg-btns {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  30% {
    opacity: 0;
    align-items: center;
  }
  50% {
    align-items: center;
    width: 100%;
    opacity: 0;
  }
  100% {
    align-items: center;
    opacity: 1;
  }
}

// reg-btn get bonus

@keyframes reg-btn {
  0% {
    transform: scale(0);
    margin: 0;
  }
  10% {
    height: auto;
    transform: scale(0);
  }
  40% {
    height: auto;
    opacity: 1;
    transform: scale(1);
  }
  100% {
    height: auto;
    opacity: 1;
    transform: scale(1);
    margin: 12px 0;
  }
}

// reg-title

@keyframes reg-title {
  0% {
    font-size: 16px;
  }
  100% {
    font-size: 20px;
    height: 40px;
  }
}

// reg-subtitle

@mixin reg-subtitle-animation($translateY, $font-size: 36px, $line-height: 36px) {
  0% {
    color: $color-white;
    transform: translateY(0);
    margin-bottom: 0;
  }
  100% {
    color: $color-yellow;
    transform: translate(0, $translateY);
    font-size: $font-size;
    height: 140px;
    margin-bottom: 8px;
    max-width: 264px;
    line-height: $line-height;
  }
}

@keyframes reg-subtitle {
  @include reg-subtitle-animation(25px);
}

@keyframes reg-subtitle-translate {
  @include reg-subtitle-animation(6px);
}

@keyframes reg-subtitle-tr {
  @include reg-subtitle-animation(6px, 30px, 30px);
}

// reg-desc

@keyframes reg-desc {
  0% {
    opacity: 0;
    height: 0;
  }
  50% {
    opacity: 1;
    height: 84px;
  }
  100% {
    opacity: 1;
    height: 84px;
    color: $color-white;
  }
}

// top

@keyframes reg-top {
  0% {
    opacity: 0;
    height: 0;
  }
  50% {
    opacity: 1;
    height: 64px;
  }
  100% {
    height: 64px;
    color: $color-black;
    opacity: 1;
  }
}

//весь баннер

@keyframes reg-bnr {
  0% {
    transform: translate(0, 0);
    position: absolute;
    z-index: 3;
    bottom: 0;
  }
  70% {
    bottom: 50%;
    transform: translate(433px, 62%);
    position: absolute;
    width: 400px;
  }
  80% {
    bottom: 50%;
    transform: translate(433px, 62%);
    position: absolute;
    width: 400px;
  }
  100% {
    bottom: 50%;
    transform: translate(433px, 62%);
    position: absolute;
    width: 400px;
    overflow: initial;
  }
}

@keyframes reg-bnr-rtl {
  0% {
    transform: translate(0, 0);
    position: absolute;
    z-index: 3;
    bottom: 0;
  }
  70% {
    bottom: 50%;
    transform: translate(-433px, 62%);
    position: absolute;
    width: 400px;
  }
  80% {
    bottom: 50%;
    transform: translate(-433px, 62%);
    position: absolute;
    width: 400px;
  }
  100% {
    bottom: 50%;
    transform: translate(-433px, 62%);
    position: absolute;
    width: 400px;
    overflow: initial;
  }
}

//исчезает блок регистации

@keyframes registration {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}
