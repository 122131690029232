$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.world-cup-page {
  & .wc-page__hero {
    z-index: 1;
  }
  & .wc-slider .wc-slider__slide-item {
    margin: 0 auto;
  }
}
