$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-num-calc {
  width: 44px;
  height: 44px;
  background-color: rgba(255, 220, 38, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px;
  cursor: pointer;
  position: absolute;
  top: 0;

  .trustly-form__form & {
    background-color: rgba(45, 45, 50, 0.8);
    &:hover {
      background-color: rgba(25, 25, 29, 0.8);
    }
  }

  &:hover {
    background-color: rgba(255, 220, 38, 0.5);
  }

  &.is-minus {
    background-image: var(--icon-i-minus);
    left: -60px;
  }
  &.is-plus {
    background-image: var(--icon-i-plus);
    right: -60px;
  }

}