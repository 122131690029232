$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.no-accordion {
  & > div > div:not(.change-cookies-link-wrapper) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    margin-bottom: 16px;
    padding-left: 0;

    & > a {
      @include text(13px, 20px, normal, $color-white);

      display: flex;
      align-items: center;
      background: rgba(255, 220, 38, 0.1);
      border-radius: 12px;
      justify-content: center;
      padding: 12px 0;

      &:hover {
        background-color:  rgba(255, 220, 38, 0.3);
        background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 220, 38, 0.2) 55.73%, rgba(45, 45, 50, 0) 100%);
      }

      img {
        display: none;
      }

      & > div {
        text-align: center;

        & > div:first-child {
          @include text-dots;
          @include text(18px, normal, normal,  $color-yellow);

          display: flex;
          align-items: center;
          justify-content: center;
          background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(45, 45, 50, 0) 100%);
          background-size: 36px 36px;
          background-repeat: no-repeat;
          background-position: 0 0;
          min-height: 36px;
          padding-left: 40px;
          font-family: var(--font-family-main);

          &:lang(ar) {
            padding-left: 0;
            padding-right: 2px;
            background-position: 100% 0;

            &::before {
              margin: 0 4px;
            }
          }

          &::before {
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: contain;
            content: "";
            filter: $filter-yellow;
            height: 24px;
            margin: 0 4px 0 -34px;
            width: 24px;
          }
        }
      }

      &:first-child {
        & > div {
          & > div:first-child {
            &::before {
              background-image: var(--icon-i-live-chat);
            }
          }
        }
      }

      &:last-child {
        & > div {
          & > div:first-child {
            &::before {
              background-image: var(--icon-i-email);
            }
          }
        }
      }
    }

    @include bp1280 {
      margin-bottom: 20px;
    }

    @include bp1440 {
      margin-bottom: 24px;
      grid-gap: 20px;
    }
  }

  .cookie_link {
    font-size: 18px;
    font-weight: 400;
    color: $color-yellow;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
