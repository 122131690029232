$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-btn {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 4px;
  top: 6px;
  border: none;
  outline: none;
  background: transparent;
  background-image: url('/joxi/fezbet/ferrari-promo/Info-icon.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
