$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-stage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 56px 0 40px;

  @include bp1280 {
    max-width: 840px;
  }

  @include bp1440 {
    max-width: 800px;
  }

  &__level-stage {
    color: rgba(134, 138, 146, 0.3);
    text-align: center;
    font-size: 59px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 2.88px;
    text-transform: uppercase;
    margin: 56px 0 16px 0;
  }

  &__title {
    border-radius: 22px;
    background: #ffdc26;
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    color: #19191d;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 24px;
  }
}
