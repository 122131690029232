$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
///padding 40px - место для иконки на фоне на псевдоэлементе

.link-icon {
  @include text(18px, 36px);
  font-family: var(--font-family-main);
  padding-left: 40px;
  cursor: pointer;
  color: $color-yellow;
}
