$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.gh-sidebar{
  &__title{
    margin: 20px 0 12px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #868A92;
    font-family: var(--font-family-main);
  }

  &__item{
    display: flex;
    align-items: center;
    padding-left:40px;
    height: 56px;
    background-size: 32px;
    background-position: left center;
    background-repeat: no-repeat;
    cursor:pointer;

    &:lang(ar) {
      padding-left: 0;
      padding-right: 40px;
      background-position: right center;
    }

    &.provider__item {
      padding-left: 0;
      padding-right: 0;

      img {
        display: block;
        width: 32px;
        height: 32px;
        padding: 6px;
        margin-right: 8px;
        background-color: rgba(65, 65, 65, 1);
        border-radius: 8px;
        box-shadow: 0px -4px 6px 0px rgba(34, 34, 34, 1) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.23) inset;

        &:lang(ar) {
          margin-right: 0;
          margin-left: 8px;
        }
      }
    }
  }

  &__text{
    @include text-dots;

    font-weight: bold;
    font-size: 15px;
    line-height: 28px;
    color: #FFFFFF;
    margin-right: 8px;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 8px;
    }

    .gh-sidebar__item:hover &{ color:$color-grey;}
    .gh-sidebar__item.active &{ color:$color-yellow;}
  }

  &__amount{
    @include text-dots;

    min-width: 37px;
    margin-left:auto;
    text-align: right;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #868A92;

    &:lang(ar) {
      margin-left: unset;
      margin-right: auto;
      text-align: left;
    }
  }

  &__hr{
    margin-top:8px;
    width: 100% ;
    height: 2px;
    background-image: linear-gradient( to right , var(--grLineBlack) ) ,  linear-gradient( to right , var(--grLineWhite) ) ;
    background-size: 100% 1px;
    background-position: center top , center 1px;
    background-repeat: no-repeat;
  }
}
