.bonus-info {
  $root: &;
  background: rgba(102, 207, 133, 0.6);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(3px);
  border-radius: 16px;
  margin-top: 26px;
  padding: 30px 10px 10px;
  position: relative;

  @include breakpoint($bp4){
    padding: 42px 16px 20px;
  }

  &__reward {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    @include breakpoint($bp4){
      justify-content: space-between;
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__descr {
    @include text(14px, 16px, 600, $lightGreen);
    margin: 0 28px;
    text-align: center;
  }

  &__icon {
    width: 52px;
    height: 54px;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
  }

  &__timer {
    margin: 0 16px;
    @include text(16px, 23px, 400, $lightGreen);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.is-one-row {
      flex-direction: row;
      margin-bottom: 8px;
    }

    span {
      font-weight: 700;
      color: $c-white;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;

    .bonus-info__text {
      width: 50%;
    }
  }

  &__receive {
    @include text(14px, 16px, 600, $c-white);
    opacity: .6;
    margin-bottom: 8px;
    text-align: center;
  }

  &__text {
    @include text(14px, 16px, 600, $c-white);
    padding: 0 7px;
    text-align: center;

    &.is-divide{
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255,255,255, .2);
      }
    }

    &.is-big {
      @include text(18px, 26px, 700)
    }

    &.is-clamp2 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .is-one-bonus & {
      @include text(16px, 23px, 700, $c-white);
      padding: 0;
    }
  }

  &__img {
    position: absolute;
    top: -28px;
    left: 50%;
    background-position: center;
    width: 48px;
    height: 50px;
    transform: translateX(-50%);
    background-size: cover;
  }

  &__btn {
    position: absolute;
    left: 50%;
    top: -33px;
    transform: translateX(-50%);
  }

  &.is-one-bonus {
    padding: 39px 24px 12px;

    @include breakpoint($bp4){
      padding: 47px 32px 20px;
    }
  }

  &.is-missed-day {
    padding: 12px 10px;

    @include breakpoint($bp4){
      padding: 20px 32px;
    }

    &.is-missed-one-bonus {
      padding: 12px 24px;

      #{$root}__descr {
        text-align: center;
        @include text(16px, 23px, 400, $lightGreen);
        margin-bottom: 8px;
      }
      #{$root}__text {
        @include text(16px, 23px, 700, $c-white);
      }
    }
  }

  &.is-received {
    padding: 27px 12px 10px;

    @include breakpoint($bp4){
      padding: 30px 32px 20px;
    }
  }

  &.is-done {
    padding: 12px 27px 37px;
    margin-top: 0;

    @include breakpoint($bp4){
      padding: 20px 32px 46px;
    }

    .bonus-info__text {
      margin-bottom: 4px;
    }

    .summer-btn {
      position: absolute;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      max-width: none;
    }
    .summer-btn__inner {
      padding: 0 53px;
    }
  }
}