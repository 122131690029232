$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-wcancel {
    margin: 40px 0 64px;
    &__item {
        width: 100%;
        height: 72px;
        padding: 16px 23px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:nth-child(odd) {
            background: #FFFFFF;
            border-radius: 8px;
        }
        .btn-32-yellow {
            min-width: 172px;
            max-width: 25%;
        }
    }
    &__i {
        @include text(14px, 20px, bold, $color-black);
        width: 168px;
        max-height: 100%;
        overflow: hidden;
        &.is-center {
            text-align: center;
        }
        strong {
            font-size: 18px;
            font-family: var(--font-family-main);
        }
    }
    &__img {
        width: 118px;
        height: 31px;
        object-fit: contain;
    }
    &__stub {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 80px 0 100px;
        .btn-60-yellow {
            min-width: 300px;
            max-width: 100%;
            @include text-dots;
        }

    }
    &__stub-text {
        @include text(14px, 20px, normal, $color-black);
        text-align: center;
        letter-spacing: 0.03em;
        max-width: 360px;
        margin-bottom: 40px;
    }
}
