$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-league {
  cursor: pointer;
  background: linear-gradient(90deg, rgba(146, 66, 21, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
  border: 3px solid rgba(255, 220, 38, 0.5);
  border-radius: 20px;
  padding: 20px 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  &__img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 13px solid rgba(25, 25, 29, 0.4);
    margin-bottom: 8px;
  }
  &__title {
    @include text(14px, 20px, bold, $color-yellow);
    text-transform: uppercase;
    font-family: var(--font-family-main);
    height: 40px;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  &__arr {
    width: 44px;
    height: 44px;
    background-image: var(--icon-arrow-all);
    background-color: rgba(255, 220, 38, 0.1);
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;

    .widget-league:hover & {
      background-color: rgba(255, 220, 38, 0.5);
    }
  }

  @include bp1280 {
    &__img {
      width: 92px;
      height: 92px;
      border-width: 15px;
      margin-bottom: 11px;
    }
    &__title {
      @include text(16px, 24px);
      height: 48px;
      margin-bottom: 11px;
    }
  }
}
