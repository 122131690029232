$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-radio {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  width: 100%;

  &__item {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__input {
    width: 20px;
    height: 20px;
    background: $color-white;
    margin-right: 12px;
    border-radius: 50%;
    transition: .2s all;
    position: relative;
    border: 1px solid $color-grey;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 12px;
    }

    &:hover {
      border: 1px solid $color-black;
    }

    input:checked ~ & {
      &::after {
        content: '';
        background-image: linear-gradient(180deg, #FFF0A2 0%, #F0AA17 100%);
        border-radius: 20px;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 3px;
        left: 3px;
      }
    }
  }
  &__text {
    @include text(16px, 20px, $color-black);
    @include text-dots;
    max-width: 112px;
  }
  input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
  }
}
