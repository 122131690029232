$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.is-ferrari-popup-promo {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.ferrari-popup {
  font-family: 'Roboto Condensed', sans-serif;
  color: #19191d;
  display: flex;
  justify-content: space-between;
  width: 573px;
  position: relative;
  padding: 52px 24px 60px;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: #f6f6f4;
  box-shadow: 0px 4px 8px 0px rgba(25, 25, 29, 0.5);
  &__close {
    top: 42px;
    background: var(--icon-close) center no-repeat;
    right: 42px;
    width: 24px;
    height: 24px;
    z-index: 2;
    background-size: contain;
    position: absolute;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  &__title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
    color: #19191d;
    max-width: 410px;
  }
  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 235px;
    width: 100%;
    max-width: 574px;
    margin: 24px 0;
  }
  &__text {
    text-align: center;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.03px;
    font-weight: 500;
    &.is-bold {
      font-weight: 700;
    }
    &.is-red {
      color: #ff1e60;
    }
  }
  &__btn {
    margin-top: 24px;
    padding: 0 44px;
    flex-shrink: 0;
  }
}
