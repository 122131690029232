$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.activation-rules {
  &__wrapper {
    background: #ffffff;
    border-radius: 8px;
    text-align: center;
    padding: 8px 12px 12px;
    margin-top: 16px;
  }
  &__key {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #868A92;
    margin-top: 0;
    margin-bottom: 0;
  }
  &__val {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #19191D;
    margin-top: 4px;
    margin-bottom: 0;
  }
  &__link {
    font-size: 18px;
    font-weight: 400;
    color: #19191D;
    text-transform: uppercase;
    margin-top: 22px;
    background: none;
    border: none;
  }
}