$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sport-wgt{

  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  overflow: auto;

  &:nth-child(1) {
    .sport-wgt__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 72px;
      grid-column-gap: 20px;
      grid-auto-flow: dense;
      min-height: auto;
      @include bp1280 {
        display: block;
        min-height: 216px;
      }
    }

  }


  &__title{
    position: relative;
    padding-left: 40px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #19191D;
    font-family: var(--font-family-main);
    @include text-dots;
    .is-live &{
      &::before{
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $color-red;
        background-image: var(--icon-sport-live-i)  , var(--icon-sport-live)  ;
        background-repeat: no-repeat;
        background-position: center 6px , center 17px;
        vertical-align: middle;
        margin: 0 8px 0 0;
        box-shadow: inset 0px 4px 6px rgba(255, 255, 255, 0.3), inset 0px -4px 6px rgba(60, 18, 5, 0.4);
      }
    }
  }


  &__filters{

    overflow: hidden;
    margin:4px -24px 0;
    padding:0 24px;
    display: flex;
    border-bottom: solid 1px #F6F6F4;


  }
  &__filter{
    margin-bottom:-1px;

    font-weight: bold;
    font-size: 14px;
    color: #868A92;

    margin-right: 24px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;

    @include text-dots;

    &:hover{
      color: #19191D;
    }
    &.active{
      border-bottom:solid 4px $color-yellow;
      color: #19191D;
    }
  }


  &__list{
    margin:20px -16px 0;
    min-height: 216px;
  }

  &__l{
    width: 160px;
    flex-shrink: 0;
  }
  &__r{
    max-width: calc(100% - 174px);
    position:relative;
    flex-grow: 1;
    margin-left: 14px;
  }
  &__item{
    display: flex;
    align-items: flex-end;
    padding:8px 16px;
    border-radius: 12px;
    color: #19191D;
    grid-column: 1 / 2;
    overflow: hidden;
    &:nth-of-type(1n + 3){ grid-column: 2 / 3;}
    &:nth-of-type(2n + 1){ background: #F6F6F4;}
  }


  &__date{
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
    display: flex;
    align-items: center;
    @include text-dots;
    .is-live &{
      color:$color-red;
      &::before{
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $color-red;
        background-image: var(--icon-sport-live-i)  ;
        background-repeat: no-repeat;
        background-position: center center ;
        background-size: 12px 10px;
        vertical-align: middle;
        margin: 0 3px 0 0;
        box-shadow: inset 0px 4px 6px rgba(255, 255, 255, 0.3), inset 0px -4px 6px rgba(60, 18, 5, 0.4);
        flex-shrink: 0;
      }
    }
  }

  &__row{
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin-top:4px;
    letter-spacing: 0.03em;
  }
  &__key{
    @include text-dots
  }
  &__val{
    flex-shrink: 0;
    margin-left:3px;
  }

  &__champ{
    padding-top:4px;
    font-size: 10px;
    line-height: 12px;
    color: #868A92;
    padding-right:60px;
    @include text-dots
  }

  &__rate{
    display: grid;
    grid-template-columns: repeat( 3 , 1fr );
    grid-gap: 8px;
    margin-top:12px;
  }

  &__coef{
    display: block;
    background: #E7E7E4;
    border: 1px solid  #E7E7E4;
    border-radius: 4px;
    padding:4px;
    text-align: center;
    height: 28px;
    line-height: 20px;
    box-sizing: border-box;
    cursor:pointer;
    & strong{ margin-left:4px; }
    &:hover{ border: 1px solid #868A92; }
  }



  &__logos{
    position:absolute;
    top: -3px;
    right: 0;
    display: flex;
  }
  &__logo{
    width: 27px;
    height: 27px;
    border: solid 1px #868A92;
    background-color: #F6F6F4;
    border-radius: 50%;
    &:first-child{ margin-right:-3px;}

    padding: 4px;
    background-repeat: no-repeat;
    background-position: center;

    background-size: contain;

    .is-live &{  border: solid 1px $color-red; }
  }
  &__logo-i{
    width: 100% ;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }




  @include bp1280 {
    &__l{ width: 130px;}
    &__r{ max-width: calc(100% - 144px);}
  }

  @include bp1440 {
    &__l{ width: 140px;}
    &__r{ max-width: calc(100% - 154px);}
  }


}
