.verification-title {
  padding: 20px 20px 16px;
  color: #000000;
  text-align: center;
  background: #ffffff;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
    padding-bottom: 12px;
  }

  &__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
}