$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.week-game-item {
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 213px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &__video {
    width: 192px;
    height: 128px;
    border-radius: 12px;
    margin-top: 8px;
    margin-bottom: 24px;
    transition: 0.3s;
    cursor: pointer;
    position: relative;
    z-index: 11;

    @media (min-width: 1280px) and (max-width: 1440px) {
      width: 150px;
      height: 100px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 46px;
      height: 46px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s;
    }
    &::before {
      background-color: rgba(45, 45, 50, 0.8);
      border-radius: 50%;
    }
    &::after {
      background-image: var(--icon-arrow-right);
      background-repeat: no-repeat;
      background-size: 23px;
      background-position: center;
      filter: $filter-white;
    }

    .week-game-item:hover & {
      &::before {
        transform: scale(0.61) translate(-38px, -38px);
      }
      &::after {
        transform: scale(0.61) translate(-38px, -38px);
      }
      transform: scale(1.63) translate(-36px, -25px);
    }
  }
  &__btns {
    max-width: 236px;
  }
  &__btn {
    transform: translateY(0px);
  }
}
