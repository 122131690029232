$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.santa-banner {
  height: 250px;
  width: 100%;
  position: relative;
  border-radius: 12px;
  border: 2px solid rgba(207, 30, 30, 0.70);
  box-shadow: 0px -8px 10px 0px rgba(207, 30, 30, 0.40) inset, 0px 8px 10px 0px rgba(255, 255, 255, 0.30) inset;
  background: image-set(url('/joxi/fezbet/santa-slope/santa-promo-banner-bg-desk.webp') 1x, url('/joxi/fezbet/santa-slope/santa-promo-banner-bg-desk@2x.webp') 2x);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  z-index: 20;
  margin-top: 40px;
  padding: 40px;

  @include bp1280 {
    padding-right: 80px;
  }

  @include bp1440 {
    padding-right: 100px;
  }

  @include bp1680 {
    padding-right: 140px;
  }

  &__santa-image {
    position: absolute;
    top: -10px;
    inset-inline-start: -120px;
    background-image: image-set(url('/joxi/fezbet/santa-slope/santa-promo-banner-md.webp') 1x, url('/joxi/fezbet/santa-slope/santa-promo-banner-md@2x.webp') 2x);
    background-position: center;
    background-size: contain;
    width: 685px;
    height: 418px;
    clip-path: polygon(100% 0, 94% 18%, 63% 60%, 0 100%, 0 0);

    @include bp1280 {
      top: 0;
      width: 723px;
      inset-inline-start: -50px;
      clip-path: unset;
    }

    @include bp1680 {
      width: 750px;
      height: 383px;
      top: -5px;
      inset-inline-start: 30px;
      background-image: image-set(url('/joxi/fezbet/santa-slope/santa-promo-banner-lg.webp') 1x, url('/joxi/fezbet/santa-slope/santa-promo-banner-lg@2x.webp') 2x);
    }

    &:lang(ar){
      transform: scaleX(-1);
    }

  }

  &__gifts-image {
    position: absolute;
    width: 234px;
    height: 168px;
    right: 240px;
    bottom: -20px;
    background: var(--bg-image);
    display: none;
    clip-path: polygon(49% 36%, 100% 33%, 100% 100%, 46% 100%);

    @include bp1280 {
      display: block;
    }

    @include bp1440 {
      clip-path: unset;
    }
  }

  &__cta {
    margin-top: 24px;

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: inline-block;
      }
    }

    &.was-logged {
      display: none;
    }
  }

  &__info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-width: 450px;
  }

  &__title {
    color: $color-yellow;
    font-size: 30px;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;

    @include bp1280 {
      font-size: 34px;
    }

    @include bp1440 {
      font-size: 36px;
    }
  }

  &__rules {
    font-size: 46px;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;

    @include bp1280 {
      font-size: 48px;
    }

    @include bp1440 {
      font-size: 52px;
    }
  }
}
