$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.slider-gh-wrap {
  max-height: 240px;

  @include bp1440 {
    max-height: 320px;
  }
}
