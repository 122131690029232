$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-arr {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: $color-black;

  padding-right: 16px;
  background-repeat: no-repeat;
  background-image: var(--icon-arrow-right);
  background-size: 16px;
  background-position: center right;
  @include text-dots;

  &:lang(ar) {
    padding-right: 0;
    padding-left: 16px;
    background-image: var(--icon-arrow-left);
    background-position: center left;
  }

  &:hover {
    filter: $filter-grey;
  }

  &_yellow {
    filter: $filter-yellow;

    &:hover {
      filter: $filter-yellow;
      opacity: .3;
    }
  }
  &.is-yellow {
    filter: $filter-yellow;
    font-weight: bold;

    &:hover {
      filter: $filter-yellow;
      opacity: .3;
    }
  }
}
