$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.trustly-widget {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 18px 43px;
  @include text-dots;
  border-bottom: 1px solid #2D2D32;
  background-image: url($cdn + '/fezbet/bankid/bankid.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &__form {
    display: flex;
    align-items: flex-start;
    order: -1;
    margin-right: 20px;
  }

  &__logo {
    margin-top: 4px;
  }

  &__title {
    @include text(24px, 44px, bold);
    font-family: var(--font-family-main);
    padding: 0 16px;
    @include text-dots;
    max-width: 50%;
  }

  &__btn {
    margin-left: 16px;
    .is-banner {
      display: none;
    }
    .is-widget {
      display: block;
    }
  }
}
