$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.contact-bar {
    background-color:rgba(255,220,38,0.1);
    border-radius: 0px 0px 20px 20px;

    width: 100%;
    height: 80px;

    padding: 10px 24px;
    position: absolute;
    left: 0;

    &__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
    }

    &__item {
        @include text(18px, 32px, normal, $color-black);
        font-family: var(--font-family-main);
        background-position: left center;
        background-size: 24px auto;
        background-repeat: no-repeat;
        margin: 0 8px;
        padding-left: 32px;
        transition: 0.2s;
        @include text-dots;
        max-height: 32px;

        &:hover {
            filter: $filter-grey;
        }
    }

    &__copyright {
        @include text(14px, 20px, normal, $color-black);
        font-family: var(--font-family-main);
        text-align: center;
        @include text-dots;
    }
}
