$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-how {
  counter-reset: section;

  &--header {
    margin-top: 34px;
    margin-bottom: 16px;
    @include text(24px, normal, 700, $color-black);
    font-family: $font-roboto;
    letter-spacing: 0.72px;
    text-transform: uppercase;
  }

  &--item {
    counter-increment: section;
  }
  &--item-wrapper {
    display: grid;
    grid-template-areas:
      "counter content arrow"
      "counter content arrow";
    grid-template-columns: auto 1fr;
    grid-auto-rows: auto;
    column-gap: 12px;

    &::before {
      content: counter(section);
      grid-area: counter;
      display: flex;
      width: 28px;
      height: 28px;
      padding: 0px 4px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 20px;
      background: $color-white;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25) inset;
      letter-spacing: -0.48px;
      @include text(16px, 120%, 700, $color-yellow-dark);
    }
  }
  &--title {
    grid-column: content;
    font-family: $font-roboto;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    @include text(16px, 175%, 700, null);
  }
  &--no-link {
    color: $color-black;
  }
  &--link {
    color: $color-black;
    transition: color .15s ease-out;
  }
  &--item:hover &--link {
    color: $color-yellow-dark;
    cursor: pointer;
  }
  &--text {
    grid-column: content;
    letter-spacing: 0.48px;
    @include text(16px, 150%, null, $color-grey);
  }
  &--icon {
    grid-area: arrow;
    align-self: center;
    width: 24px;
    height: 24px;
    fill: currentColor;
    transition: fill .2s ease-out;

    &:dir(rtl) {
      scale: -1;
    }
  }
}
