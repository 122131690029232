$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-control {
  display: inline-grid;
  grid-template-areas:
    'btn space title'
    'btn space subtitle';
  grid-template-columns: 48px 20px auto;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 40px;

  &_next {
    grid-template-areas:
      'title space btn'
      'subtitle space btn';
    grid-template-columns: auto 20px 48px;
    padding-left: 40px;
    padding-right: 20px;
  }
  &_next &__btn::after {
    transform: rotate(0);
  }

  border-radius: 40px;
  border-width: 2px;
  border-style: solid;
  border-image-source: var(--icon-i-border-btn);
  border-image-slice: 22;
  border-image-width: 80px;

  &__btn {
    grid-area: btn;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;

    border-radius: 100%;
    background-color: rgba(255, 220, 38, 0.1);

    &::after {
      content: '';

      flex: 0 0 24px;
      height: 24px;

      transform: rotate(180deg);
      background-image: var(--icon-arrow-right);
      background-size: 24px;
      filter: $filter-yellow;
    }
  }

  &:hover &__btn {
    background-color: rgba(255, 220, 38, 0.5);

    &::after {
      filter: $filter-white;
    }
  }

  &__title {
    grid-area: title;

    font-family: var(--font-family-main);
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: $color-yellow;
  }

  &__subtitle {
    grid-area: subtitle;

    font-size: 14px;
    line-height: 20px;
    color: $color-grey;
  }
}
