$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.home-category {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 16px;

  &__item {
    background-color: rgba(255, 220, 38, 0.1);
    background-repeat: no-repeat;
    background-size: 84px;
    background-position: left center;
    border-radius: 23px;
    padding-right: 8px;
    padding-left: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: rgba(255, 220, 38, 0.3);
    }

    &:nth-child(5) ~ & {
      display: none;
    }

  }

  &__text {
    @include text(16px, 20px, bold, $color-white);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-family: var(--font-family-main);
    overflow: hidden;
    max-height: 60px;
  }

  @include bp1280 {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;

    &__item:nth-child(5) ~ .home-category__item {
      display: flex;
    }
    &__item:nth-child(6) ~ .home-category__item {
      display: none;
    }

  }
  @include bp1440 {
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 24px;

    &__item:nth-child(6) ~ .home-category__item {
      display: flex;
    }
    &__item:nth-child(7) ~ .home-category__item {
      display: none;
    }
  }
  @include bp1680 {
    grid-template-columns: repeat(8, 1fr);

    &__item:nth-child(7) ~ .home-category__item {
      display: flex;
    }
    &__item:nth-child(8 ) ~ .home-category__item {
      display: none;
    }
  }
}
