.christmas-days {
  padding: 48px 35px;
  position: relative;
  border-radius: 10px;

  &.is-line {
    border-radius: 0;
    overflow-x: scroll;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 13px;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px 4px;
    &::before {
      content: '';
      grid-area: 1 / 1 / 2 / 4;
    }
  }

  &__line {
    display: flex;
    .christmas-days__item {
      width: 68px;
      flex-shrink: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
    &::after {
      content: '';
      flex-shrink: 0;
      display: block;
      width: 55px;
    }
  }

  &__item {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    padding: 6px;
    font-size: 20px;
    font-weight: 900;
    background: linear-gradient(148.27deg, #52b0ad 19.1%, #299c98 101.19%);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08), inset 0px 1px 0px #62deda, inset 0px -2px 4px rgba(21, 136, 143, 0.6);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.1s all;
    color: #1f8e8a;
    pointer-events: none;

    &.is-active {
      border: 2px solid #8edac3;
    }

    &.is-status-yesterday,
    &.is-status-previous {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08), inset 0px 1px 0px #419c99;
      background: linear-gradient(148.27deg, rgba(82, 176, 173, 0.5) 19.1%, rgba(41, 156, 152, 0.5) 101.19%),
        url($cdn + '/holidays/christmas/gift.svg') center no-repeat;
      background-size: auto, 20px;
      pointer-events: inherit;
      span {
        display: none;
      }

      &:hover {
        background: linear-gradient(148.27deg, rgba(82, 176, 173, 0.7) 19.1%, rgba(41, 156, 152, 0.7) 101.19%),
          url($cdn + '/holidays/christmas/gift.svg') center no-repeat;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08), inset 0px 1px 0px #419c99;
        background-size: auto, 20px;
      }
    }

    &.is-status-today {
      background: linear-gradient(141.79deg, #de523f 8.09%, #d64239 74.45%);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08), inset 0px 1px 0px #ff986c;
      pointer-events: inherit;
      span {
        color: #ffffff;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.26);
      }

      &:hover {
        background: linear-gradient(141.79deg, #ff6c3d 8.09%, #ef421b 74.45%);
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08), inset 0px 1px 0px #ffb494;
      }
    }

    &.is-status-next {
      pointer-events: inherit;
      span {
        color: #ffffff;
        text-shadow: 0px 1px 2px #0c838a;
      }
      &:hover {
        background: linear-gradient(148.27deg, #67e0dc 19.1%, #2ebab5 101.19%);
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08), inset 0px 1px 0px #75fffb,
          inset 0px -2px 4px rgba(12, 131, 138, 0.6);
      }
    }

    &.is-inactive {
      color: #1f8e8a;
      pointer-events: none;
    }

    &.is-next-days {
      pointer-events: none;
    }

    &.is-today {
      color: #fff;
      background: linear-gradient(141.79deg, #de523f 8.09%, #d64239 74.45%);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08), inset 0px 1px 0px #ff986c;
    }

    &.is-previous {
      font-size: 0;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-image: var(--icon-easter-calendar-egg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        filter: brightness(0) saturate(100%) invert(35%) sepia(62%) saturate(488%) hue-rotate(340deg) brightness(92%)
          contrast(92%);
      }
    }

    &.is-special {
      position: relative;
      background: #6ccf6d;
      box-shadow: 2px 3px 0px #259725;
      color: #fff;
      &::before {
        content: '';
        position: absolute;
        top: -3px;
        right: -2px;
        width: 18px;
        height: 18px;
        background-image: var(--icon-easter-star);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        filter: drop-shadow(1px 1px 0px #f39f20) drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.25));
      }
    }

    &:hover,
    &.active {
      background: #ab6120;
      color: #ffffff;
      box-shadow: 2px 3px 0px #623208;

      &.is-previous::before {
        filter: invert(1) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.26));
      }
    }
  }
}
