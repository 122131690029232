$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
p {
  margin: 0;
}

.popup__wrap.shop-confirm {
  padding: 56px 40px 60px;
  font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;

  .popup__title {
    text-transform: none;
    margin-bottom: 16px;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
  }
}

.shop-confirm {
  &__info {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $color-black;
    margin-bottom: 16px;
    text-align: center;
    font-family: $font-alternative;
    letter-spacing: 0.4px;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto 28px;
    color: $color-grey-dark;
  }

  &__content-new {
    border-radius: 2px;
    height: 110px;
    position: relative;
    margin: 0 auto 24px;

    &.is-exchange {
      padding: 8px 16px 14px;
      max-width: 247px;
      background: linear-gradient(90deg, #36273B 0%, #0F0E26 76.04%), $color-white;
    }

    &.is-freebet {
      padding: 14px 20px 9px;
      background-image: url($cdn + '/zet/shop/bonus-freebet-bg.png');
      background-color: transparent;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__sums {
    position: relative;
    width: 100%;
    height: 214px;
    border: 4px solid $color-red;
    border-radius: 16px;
    padding: 24px 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    &._active {
      border-color: $color-yellow;
    }

    &._new {
      margin-bottom: 16px;
    }
  }

  &__sums-ovarlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(#2d2d32 0%, #2d2d3299 100%);
  }

  &__sums-image {
    position: absolute;
    left: -4px;
    top: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    pointer-events: none;
    filter: blur(2px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.is-casino,
    &.is-exchange {
      background-image:  url( $cdn + '/fezbet/bonuses/casino.jpg');
    }

    &.is-sport,
    &.is-freebet {
      background-image:  url( $cdn + '/fezbet/bonuses/sport.jpg');
    }
  }

  &__bonus-type {
    position: relative;
    width: 32px;
    height: 32px;
    filter: $filter-white;
    margin-bottom: 20px;

    &.is-casino,
    &.is-freespin,
    &.is-exchange {
      background-image: var(--icon-type-casino);
    }

    &.is-sport,
    &.is-freebet {
      background-image: var(--icon-type-sport);
    }
  }

  &__bonus-amount {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;
    color: $color-yellow;
    margin-bottom: 16px;
    position: relative;
    text-align: center;
  }

  &__bonus-name {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $color-white;
    position: relative;
    display: flex;
    justify-content: center;
    font-family: $font-alternative;

    .freespin-link {
      color: $color-yellow;
      margin-left: 4px;
      text-decoration: underline;
    }
  }

  &__label {
    position: absolute;
    width: 115px;
    height: 31px;
    @include text-dots;
    transform: rotate(-45deg);
    transform-origin: left bottom;
    left: 0;
    top: 50px;
    line-height: 31px;
    font-weight: 700;
    font-size: 16px;
    padding: 0 21px;
    text-align: center;
    text-transform: uppercase;
    font-family: var(--font-family-main);

    &.new {
      color: $color-white;
      background: $color-red;
    }

    &.active {
      color: $color-black;
      background: $color-yellow;
    }
  }

  &__content-money {
    display: flex;
    justify-content: space-around;
    padding-bottom: 12px;
    align-items: center;
    border-bottom: 1px solid $color-yellow;
  }

  &__icon-wrapper {
    position: relative;
    width: 240px;
    height: 160px;
    display: block;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    width: 300px;
    height: 230px;
    pointer-events: none;
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    color: $color-black;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;

    &-icon {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 8px;
    }
  }

  &__arrows {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url($cdn + '/fezbet/shop/shop-arrows-bottom.webp');
    width: 48px;
    height: 30px;
    margin: 8px auto;
  }

  &__count {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    align-items: center;
    justify-content: center;
    display: flex;
    color: $color-black;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 28px;

    letter-spacing: 0.5px;

    &-icon {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 4px 0 8px;
    }
  }

  &__btn {
    button {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }

  &__btns_vertical {
    display: grid;
    grid-gap: 16px;
    justify-content: center;

    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $color-grey;
      text-align: center;
    }
  }
}
