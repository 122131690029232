.drag-and-drop {
  margin: 12px 0;
  background-color: rgba(140, 135, 179, 0.08);
  border: 1px dashed #8c87b3;
  text-align: center;
  padding: 32px 0;
  body.rtl-lang & * {
    text-align: center;
  }
  &__icon {
    margin-bottom: 8px;
    background-image: url($cdn + '/dvs/i-upload.svg');
    height: 48px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &__title {
    color: #524e72;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 8px;
  }
  &__label {
    color: #8c87b3;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
    label {
      color: #3586ff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__file-types {
    color: #8c87b3;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &.dragging {
    background: #eee;
  }
  &.error {
    background: rgba(244, 67, 54, 0.08);
  }

  &.is-disabled {
    opacity: .4;
    pointer-events: none;
  }
}

