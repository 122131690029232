.filter-canvas {
  height: 100vh;
  width: 100vw;
  position: absolute;
  pointer-events: none;
  &.is-filter {
    background: rgba(0, 0, 0, 0.5);
    &.is-disabled {
      opacity: 0;
    }
    &.is-filter {
      opacity: 0.5;
    }
  }
}

.mini-game {
  min-height: 100vh;
  font-family: $f-jost;
  background-color: #40a65e;
  background-image: url($cdn + '/summer-promo/game.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-height: 600px) and (max-width: 1280px) and (orientation: landscape) {
    position: absolute;
    z-index: -1;
  }
  canvas#summerCanvas {
    max-height: 100%;
    max-width: 100%;
  }
  &__coin-spline {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%) scale(1);
    width: 100px;
    height: 100px;
    visibility: hidden;
    &[data-index='1'] {
      animation: 1s 0s animDown ease-in-out;
    }
    &[data-index='2'] {
      animation: 1s 1s animDown ease-in-out;
    }
    &[data-index='3'] {
      animation: 1s 2s animDown ease-in-out;
    }
    &[data-index='4'] {
      animation: 1s 3s animDown ease-in-out;
    }
    &[data-index='5'] {
      animation: 1s 4s animDown ease-in-out;
    }
  }
  &__head {
    position: relative;
    &.is-disabled {
      opacity: 0;
    }
  }
  @include breakpoint($bp1) {
    background-image: url($cdn + '/summer-promo/back-desk.png');
  }
  @keyframes blinker {
    0% {
      opacity: 1;
      transform: scale(1);
      background-size: 0, 35px 39px;
    }
    25% {
      opacity: 1;
      transform: scale(1);
      background-size: 0, 35px 39px;
    }
    50% {
      opacity: 1;
      transform: scale(1);
      background-size: 0, 35px 39px;
    }
    75% {
      opacity: 1;
      transform: scale(1.5);
      background-size: 100% auto, 0;
    }
    100% {
      opacity: 1;
      transform: scale(1);
      background-size: 100% auto, 0;
    }
  }

  .btn-summer {
    position: fixed;
    display: flex;
    padding: 12px 12px 0;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    background: transparent;
    @include breakpoint($bp1) {
      padding: 24px 24px 0;
    }
    button {
      border: none;
    }
    &__back {
      background-image: url($cdn + '/summer-promo/arrowl.svg');
      background-position: center;
      background-repeat: no-repeat;
      height: 40px;
      transition: 0.2s background-color;
      width: 40px;
      background-color: rgba(102, 207, 133, 0.6);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
      backdrop-filter: blur(3px);
      border-radius: 50%;
      &.is-opacity {
        opacity: 0.5;
      }
      @include breakpoint($bp3) {
        background-position: 20px;
        display: flex;
        justify-content: flex-end;
        color: $c-white;
        font-family: $f-jost;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        padding: 10px 20px 10px 48px;
        width: auto;
        height: 44px;
        border-radius: 24px;
        &:hover {
          background-color: $green2;
        }
      }
      span {
        display: none;
        @include breakpoint($bp3) {
          display: block;
          cursor: pointer;
        }
      }
    }
    &__small {
      display: flex;
      width: 100%;
      position: fixed;
      top: 55px;
      left: 50%;
      transform: translate(-50%, -50%);
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &__title {
      font-weight: 800;
      font-size: 36px;
      line-height: 52px;
      text-transform: uppercase;
      color: #ffffff;
      &.is-opacity {
        opacity: 0.5;
      }
    }
    &__time {
      font-weight: 600;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 16px;
      width: 72px;
      height: 28px;
      background: rgba(102, 207, 133, 0.6);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
      border-radius: 30px;
      color: #ffffff;
      &.is-hidden {
        display: none;
      }
      &.is-finish {
        display: none;
      }
    }
    &__work {
      background-image: url($cdn + '/summer-promo/quest.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-color: rgba(102, 207, 133, 0.6);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
      backdrop-filter: blur(3px);
      border-radius: 50%;
      transition: 0.2s background-color;
      height: 40px;
      margin-bottom: 10px;
      width: 40px;
      &.is-opacity {
        opacity: 0.5;
      }
      @include breakpoint($bp3) {
        background-position: 20px;
        color: $c-white;
        font-family: $f-jost;
        font-weight: 800;
        font-size: 20px;
        margin-right: 20px;
        line-height: 24px;
        padding: 10px 20px 10px 48px;
        width: auto;
        height: 44px;
        display: flex;
        justify-content: flex-end;
        border-radius: 24px;
        &:hover {
          background-color: $green2;
        }
      }
      span {
        display: none;
        @include breakpoint($bp3) {
          display: block;
          cursor: pointer;
        }
      }
    }
    &__sound {
      background-image: url($cdn + '/summer-promo/sound-on.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-color: rgba(102, 207, 133, 0.6);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
      backdrop-filter: blur(3px);
      border-radius: 50%;
      height: 40px;
      width: 40px;
      transition: 0.2s background-color;
      &.unmute {
        background-image: url($cdn + '/summer-promo/sound-off.svg');
      }
      &.is-opacity {
        opacity: 0.5;
      }
      @include breakpoint($bp3) {
        background-position: 20px;
        color: $c-white;
        font-family: $f-jost;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        padding: 10px 20px 10px 48px;
        width: auto;
        height: 44px;
        display: flex;
        justify-content: flex-end;
        border-radius: 24px;
        &:hover {
          background-color: $green2;
        }
      }
      span {
        display: none;
        @include breakpoint($bp3) {
          display: block;
          cursor: pointer;
        }
      }
    }
  }
}
@keyframes onboarding {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animProgressBar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes coinBlink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animDown {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translate(-30%, -30%) scale(0.5);
  }
  20% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(2);
  }
  40% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(2);
  }
  60% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(2);
  }
  80% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(2);
  }
  90% {
    transform: translate(-50%, 500%) scale(1.5);
  }
  100% {
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 500%) scale(0.5);
  }
}

@keyframes animUp {
  0% {
    opacity: 0.5;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes animProgress {
  0% {
    opacity: 0.5;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes animTop {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animCoin {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translate(-30%, -30%) scale(0.5);
  }
  20% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 1;
  }
  40% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 1;
  }
  60% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 1;
  }
  80% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 1;
  }
  90% {
    transform: translate(-50%, 500%) scale(1.5);
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, 500%) scale(0.5);
  }
}
.button-summer-block {
  z-index: 1;
  @include breakpoint($bp3) {
    width: 100%;
    max-width: 261px;
  }
}
.onboarding {
  min-height: 80px;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 50%;
  left: 50%;
  bottom: 35px;
  width: 100%;
  max-width: 350px;
  animation: onboarding 3.5s forwards;
  color: $c-white;
  font-family: $f-jost;
  background: #40a65e;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(3px);
  border-radius: 16px;
  transition: all 0.5s ease;
  @media (max-height: 600px) and (max-width: 1280px) and (orientation: landscape) {
    position: absolute;
    z-index: -1;
  }
  &.is-height {
    height: 80px;
  }
  &.is-finish {
    display: none;
  }
  &.is-top {
    animation: animTop 0.5s linear forwards;
    opacity: 0;
    top: 160px;
  }
  &.is-up {
    margin-bottom: 60px;
    animation: animUp 1.5s linear forwards;
    opacity: 0;
  }
  &__item--text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    padding: 12px;
    padding-bottom: 45px;
    text-align: center;
  }
}

.progress-bar-summer {
  backdrop-filter: blur(3px);
  width: 100%;
  max-width: 330px;
  height: 26px;
  background: #48b96a;
  border-radius: 16px;
  margin-left: -15px;
  align-items: center;
  display: flex;
  bottom: 35px;
  position: absolute;
  right: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 3px #0000002e;
  left: 50%;
  @media (max-height: 600px) and (max-width: 1280px) and (orientation: landscape) {
    display: none;
  }
  &.is-disabled {
    opacity: 0;
  }
  &.is-filter {
    opacity: 0.4;
  }

  &::before {
    content: '';
    display: block;
    width: 70px;
    position: absolute;
    z-index: 9999;
    height: 64px;
    background-image: var(--data-casino), var(--data-sport);
    background-repeat: no-repeat;
    background-size: 50px 50px, 50px 50px;
    background-position: 20px, -3px;
    display: block;
    position: absolute;
    top: -22px;
    right: -33px;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(0deg, #31974f, #31974f), linear-gradient(272.44deg, #072a68 4.15%, #174c8e 87.73%);
    box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    top: 2px;
    right: 2px;
    left: 2px;
    bottom: 2px;
  }
  &__item {
    position: relative;
    z-index: 1;
    margin-right: 10px;
  }

  &__coin {
    background-image: url($cdn + '/summer-promo/active.png'), url($cdn + '/summer-promo/in-active.png');
    height: 55px;
    width: 49px;
    z-index: 1;
    background-size: 0, 35px 39px;
    background-position: left;
    background-repeat: no-repeat;
    &.in-active {
      visibility: hidden;
      z-index: 1;
      background-size: 100% auto, 0;
      &[data-index='1'] {
        animation: 1s 1s animUp ease forwards;
      }
      &[data-index='2'] {
        animation: 1s 2s animUp ease forwards;
      }
      &[data-index='3'] {
        animation: 1s 3s animUp ease forwards;
      }
      &[data-index='4'] {
        animation: 1s 4s animUp ease forwards;
      }
      &[data-index='5'] {
        animation: 1s 5s animUp ease forwards;
      }
      &::before {
        content: attr(data-index);
        font-family: $f-jost;
        font-weight: 800;
        font-size: 22px;
        line-height: 32px;
        position: absolute;
        top: 10px;
        left: 22px;
        color: #fceec7;
      }
    }
  }
}
.button-summer {
  display: flex;
  flex-direction: column;
  @include breakpoint($bp3) {
    flex-direction: row;
  }
}
.progress-bar-summer__onboarding {
  width: 89px;
  position: absolute;
  z-index: -1;
  height: calc(100% - 36px);
  border-radius: 20px;
  background: repeating-linear-gradient(-45deg, #f1b314, #f1b314 10px, #f59a11 10px, #f59a11 20px);
  top: 18px;
  visibility: hidden;
  left: 3px;
  &[data-index='1'] {
    animation: 2s 1s animProgress ease forwards;
  }
  &[data-index='2'] {
    animation: 2s 2s animProgress ease forwards;
  }
  &[data-index='3'] {
    animation: 2s 3s animProgress ease forwards;
  }
  &[data-index='4'] {
    animation: 2s 4s animProgress ease forwards;
  }
  &[data-index='5'] {
    animation: 2s 5s animProgress ease forwards;
  }
  &.is-display {
    display: none;
  }
}
.spline-summer-animation.active {
  position: absolute;
  left: 50%;
  top: 50%;
  visibility: hidden;
  transform: translate(-50%, -50%) scale(1);
  width: 100px;
  height: 100px;
  &[data-index='1'] {
    animation: 2s 0s animCoin ease;
  }
  &[data-index='2'] {
    animation: 2s 0s animCoin ease;
  }
  &[data-index='3'] {
    animation: 2s 0s animCoin ease;
  }
  &[data-index='4'] {
    animation: 2s 0s animCoin ease;
  }
  &[data-index='5'] {
    animation: 2s 0s animCoin ease;
  }
}
.spline-summer-animation.inactive {
  display: none;
}
.summer-coin-number {
  display: none;
}
.progress-bar-summer__item.active {
  .summer-coin-number {
    display: block;
    animation: 0.5s 1.8s coinBlink ease forwards;
    opacity: 0;
    font-family: $f-jost;
    font-weight: 800;
    font-size: 22px;
    line-height: 32px;
    position: absolute;
    top: 10px;
    left: 22px;
    color: #fceec7;
  }
  .progress-bar-summer__coin {
    animation: 3s 0s blinker ease forwards;
    opacity: 0;
    position: relative;
  }
  .progress-bar-summer__progress {
    width: 89px;
    animation: 0.5s 1.8s animProgressBar ease forwards;
    opacity: 0;
    position: absolute;
    z-index: -1;
    height: calc(100% - 36px);
    border-radius: 20px;
    background: repeating-linear-gradient(-45deg, #f1b314, #f1b314 10px, #f59a11 10px, #f59a11 20px);
    top: 18px;
    left: 3px;
  }
}
