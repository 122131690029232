$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.header-game {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  background-color: $color-grey-dark;
  padding: 0 21px;

  .link-back {
    @include text-dots;
    flex-shrink: 1;
  }

  &__l,
  &__r {
    width: calc(50% - 48px);
    display: flex;
    align-items: center;
  }

  &__r {
    justify-content: flex-end;
  }

  &__title {
    max-width: 50%;
    margin-left: 24px;
    padding-right: 10px;

    &:lang(ar) {
      margin-left: 0;
      margin-right: 24px;
    }
  }

  &__name {
    @include text(18px, 24px, bold, $color-yellow);
    @include text-dots;

    max-width: 300px;
  }

  &__brand {
    @include text(14px, 20px, normal, $color-white);
    @include text-dots;

    max-width: 300px;
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 96px;
    height: 32px;
    background: var(--icon-logo);
    background-size: contain;
    background-position: left center;
    margin: 0 auto;

    &:hover {
      opacity: 0.5;
    }
  }

  &__btns {
    display: flex;
  }

  .btn-stroke {
    margin-left: 16px;

    &:lang(ar) {
      margin-right: 16px;
      margin-left: unset;
    }
  }
}
