$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.gh-fav {
  position:relative;
  background: #fff;
  border-radius: 20px;
  padding:14px 20px 40px;
  min-height: 320px;

  &__title {
    @include text-dots;

    height: 36px;
    background-image: url($cdn + '/fezbet/gamehall/2x_mygames.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 36px;
    padding-left: 48px;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 38px;
    color: #19191D;
    font-family: var(--font-family-main);
  }

  &__box {
    display: flex;
    flex-wrap: wrap;
    margin:8px -8px 0;
  }
  &__item {
    margin:8px;
    width: calc(25% - 16px);
    box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  }

  &__btn {
    position:absolute;
    left: calc( 50% - 40px);
    bottom: 0;
    width: 80px;
    height: 80px;
    background: #FFDC26;
    box-shadow: inset 0 12px 15px rgba(255, 255, 255, 0.8), inset 0px -12px 15px #E88E0F;
    border-radius: 50px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #19191D;
    text-shadow: 0 0 6px #FFDC26;
    cursor: pointer;
    font-family: var(--font-family-main);
    box-sizing: border-box;
    padding:5px;

    &:lang(el),
    &:lang(at),
    &:lang(de),
    &:lang(ru) {
      font-size: 12px;
      letter-spacing: 0;
    }

    &:lang(hu) {
      font-size: 10px;
      letter-spacing: 0;
      width: 84px;
      height: 84px;
    }
  }

  @include bp1280 {
    min-height: 600px;
    padding:14px 24px 40px;

    &__box{ margin:10px -10px 0; }

    &__item{
      width: calc(33.3334% - 20px);
      margin:10px;

      &.game:nth-of-type(7),
      &.game:nth-of-type(8){ display: none;}
    }
  }

  @include bp1440 {
    padding:14px 32px 40px;

    &__box{ margin:4px -12px 0; }

    &__item{
      width: calc(33.3334% - 24px);
      margin:12px;
    }
  }

  @include bp1680{
    &__item{
      width: calc(25% - 24px);
      &.game:nth-of-type(7),
      &.game:nth-of-type(8){ display: block;}
    }
  }
}
