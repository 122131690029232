$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.search {
    padding-bottom: 72px;

    &__input-wrap {
        @include text(16px, 20px, normal, $color-black);

        width: 100%;
        background: $color-white;
        border: 1px solid $color-grey;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 12px 0;
        padding-inline-end: 36px;
        padding-inline-start: 24px;
        display: flex;
        align-items: center;
        position: relative;
        height: 48px;
        line-height: 48px;

        &:hover {
            border: 1px solid $color-black;
        }

        &:focus,
        &:active {
            border: 1px solid $color-yellow;
            box-shadow: 0 0 0 1px $color-yellow;
        }

        &.icon-search-big {
            background-size: 24px;
            background-repeat: no-repeat;
            background-position: right 12px center;

            &:lang(ar) {
              background-position: left 12px center;
            }
        }
    }

    &__result {
        display: none;
        flex-direction: column;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 20px;
            width: 100%;
            height: 40px;
            background-image: linear-gradient(180deg,rgba(246,246,244,0) 0, #f6f6f4 70%, #f6f6f4 100%);
            z-index: 3;
        }

        .linda-has-games &,
        .linda-not-found & {
            display: flex;
        }

        &.no-more-games {
            max-height: 460px;

            .search__games {
                overflow-y: hidden;
                margin-bottom: 32px;
            }

            &::after {
                content: none;
            }

            .search__btn {
                display: flex;
            }
        }
    }

    &__btn {
        display: none;
        justify-content: center;
        width: 480px;
        max-width: none;
        height: 60px;
        margin-inline-start: -60px;
        background: $color-white;
        border-radius: 0 0 20px 20px;
        flex-shrink: 0;

        &:hover {
            cursor: pointer;

            .btn-more-load {
                filter: invert(56%) sepia(7%) saturate(335%) hue-rotate(182deg) brightness(95%) contrast(92%);
            }
        }

        .btn-more-load {
            @include text(16px, 24px, bold, $color-black);
            border: none;
            background-color: transparent;
            background-position: right center;
            background-repeat: no-repeat;
            background-size: 16px 16px;
            padding-inline-end: 16px;

            &:lang(ar) {
                background-position: left center;
            }
        }
    }
    &__empty {
        display: none;
        max-height: 460px;
        overflow: hidden;

        @include text(16px, 24px, normal, $color-black);
        margin: 32px 0 60px;
        text-align: center;

        .linda-not-found & {
            display: block;
        }

    }
    &__games {
        display: none;
        flex-direction: column;

        width: calc(100% + 26px);
        max-height: 408px;
        overflow-y: scroll;
        overscroll-behavior: none;

        margin: 32px 0 20px;

        .game-search {
            &:last-of-type {
                margin-bottom: 20px;
                background-image: none;
            }
        }

        .linda-has-games & {
            display: flex;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: transparent;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $color-grey-middle;
            border-radius: 4px;
        }
    }
}
