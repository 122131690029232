$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.paralax{
  position:absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
  z-index: 7;
  height: 740px;
  &__wrap{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__i{
    position:absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;

    background-image: url( $cdn + '/fezbet/elements/circle.png');


    &:nth-child(1){
      background-size: 135px;
      background-position: calc(50% - 20px) 400px;
    }
    &:nth-child(2){
      background-size: 68px;
      background-position: calc(100% - 20px) 200px;
    }
    &:nth-child(3){
      background-size: 36px;
      background-position: 65% 20px;
    }
  }


  @include bp1280 {
    &__i{
      &:nth-child(1){
        background-position: 55% 400px;
      }
      &:nth-child(2){
        background-position: calc(50% + 600px) 200px;
      }
      &:nth-child(3){
        background-position: 62% 30px;
      }
    }
  }
  @include bp1440 {
    height: 840px;
    &__i{
      &:nth-child(1){
        background-size: 180px;
        background-position: 50% 548px;
      }
      &:nth-child(2){
        background-size: 92px;
        background-position: calc(50% + 675px) 320px;
      }
      &:nth-child(3){
        background-size: 48px;
        background-position: 60% 50px;
      }
    }
  }

  @include bp1680{
    height: 840px;
    &__i{
      &:nth-child(1){
        background-position: 54% 548px;
      }
      &:nth-child(2){
        background-size: 92px;
        background-position: calc(50% + 750px) 320px;
      }
      &:nth-child(3){
        background-position: 63% 50px;
      }
    }
  }

}
