.christmas-title {
  position: relative;
  border-radius: 28px;
  width: 100%;
  text-align: center;
  height: 100%;

  &--wrap {
    border-radius: 32px;
    padding: 4px;
    display: inline-flex;
    min-width: 420px;
    height: 67px;
    margin: 0 auto;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: -27px;
      left: -12px;
      width: 88px;
      height: 87px;
      background-image: url($cdn + '/holidays/christmas/christmas-lights-ball.png');
      background-repeat: no-repeat;
      background-size: contain;
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/holidays/christmas/christmas-lights-ball@2x.png');
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url($cdn + '/holidays/christmas/christmas-lights-ball@3x.png');
      }
    }
    &:first-child {
      margin-top: 68px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -43px;
    left: 50%;
    margin-left: -210px;
    width: 420px;
    height: 58px;
    background-image: url($cdn + '/holidays/christmas/logo-decor-back.png');
    background-repeat: no-repeat;
    background-size: 420px;
    background-position: center;
    z-index: -1;
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/holidays/christmas/logo-decor-back@2x.png');
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url($cdn + '/holidays/christmas/logo-decor-back@3x.png');
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -38px;
    left: 50%;
    margin-left: -34px;
    width: 68px;
    height: 67px;
    background-image: url($cdn + '/holidays/christmas/mistletoe.png');
    background-repeat: no-repeat;
    background-size: contain;
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url($cdn + '/holidays/christmas/mistletoe@2x.png');
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url($cdn + '/holidays/christmas/mistletoe@3x.png');
    }
  }

  &__text {
    color: #ffffff;
    font-family: 'Pacifico';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 52px;
    text-shadow: 0px 3px 3px rgba(3, 68, 66, 0.3);
    margin: 0;
  }

  &__promo {
    position: absolute;
    right: 20px;
    padding: 7px 12px;
    background: #ffedcc;
    border-radius: 6px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 800;
    text-align: center;
    color: #4d3522;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}

.christmas-subtitle {
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #9c8952;
  margin-top: 20px;
  @include breakpoint($bp1) {
    display: none;
  }
}
