$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.slider-gh {
  max-width: 100%;
  height: 240px;
  max-height: 240px;
  border-radius: 12px;
  padding: 28px 0 12px 52px;
  background-size: cover;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(74.01deg, rgba(25, 16, 80, 0.8) -0.51%, rgba(25, 16, 80, 0) 71.91%);
    position: absolute;
    top: 0;
    left: 0;
  }

  &__icon {
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 10;
    top: 4px;
    left: 4px;
    background: var(--icon-nav_promotions_1);
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #19191D;
    border-radius: 50%;
  }

  &__wrap {
    width: 390px;
    padding-bottom: 24px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    max-width: 100%;
    @include text(18px, 24px, bold, $color-white);
    font-family: var(--font-family-main);
    overflow: hidden;
    height: 24px;
  }

  &__summ {
    max-width: 100%;
    @include text(36px, 44px, bold, $color-yellow);
    font-family: var(--font-family-main);
    padding: 4px 0 12px 0;

    &:lang(ar) {
      white-space: unset;
    }
  }

  &__subtitle {
    @include text(12px, 16px, normal, $color-white);
    max-height: 32px;
    overflow: hidden;
  }

  &__btn {
    margin-top: auto;
    max-width: 100%;
  }

  &__arr {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgba(45, 45, 50, 0.8);
    border: none;
    z-index: 1;
    opacity: 0;
    transition: 0.3s;

    @include bp1440 {
      width: 48px;
      height: 48px;
    }

    &:hover {
      background: rgba(25, 25, 29, 0.8);
    }

    linda-slick:hover & {
      opacity: 1;
      transition: 0.3s;
    }

    &::before {
      content: "";
      width: 24px;
      height: 24px;
      filter: $filter-yellow;
      background-image: var(--icon-arrow-left);
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.is-prev {
      left: 8px;
    }
    &.is-next {
      right: 8px;

      &::before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }
  &__dots{
    position: absolute;
    left: 52px;
    bottom: 12px;
    min-width: 58px;
    display: flex;
    height: 12px;
    align-items: center;

    li:not(:last-of-type) {
      margin-right: 17px;
    }

    li.slick-active button{
      width: 12px;
      height: 12px;
      background: linear-gradient(180deg, #FFF0A2 0%, #F0AA17 100%);
      border: 3px solid $color-black;
      border-radius: 50%;

      &:hover {
        cursor: pointer;
      }
    }

    li button {
      display: block;
      width: 8px;
      height: 8px;
      background: rgba(25, 25, 29, 0.5);
      font-size: 0;
      cursor: pointer;
      border-radius: 50%;
      border: none;
      padding: 0;
    }
  }
  @include bp1440 {
    height: 320px;
    max-height: 320px;
    padding: 48px 0 20px 64px;

    &__wrap {
      width: 470px;
      padding-bottom: 28px;
    }

    &__title {
      @include text(24px, 32px, null, null);
      height: 32px;
    }

    &__summ {
      @include text(48px, 60px, null, null);
      padding-bottom: 8px;
    }

    &__subtitle {
      @include text(14px, 20px, null, null);
      max-height: 40px;
    }

    &__dots {
      bottom: 20px;
      left: 64px;
    }
  }
}

.slider-gh-container {
  .linda-has-remembered-login & {
    &.wasnt-logged {
      display: none;
    }

    &.was-logged {
      display: block;
    }
  }

  &.was-logged {
    display: none;
  }
}

