$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
// Breakpoints
@mixin bp540 {
  @media screen and (min-width: 540px) {
    @content;
  }
}

@mixin bp768 {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin bp1024 {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin bp1280 {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin bp1440 {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

@mixin bp1680 {
  @media screen and (min-width: 1680px) {
    @content;
  }
}

@mixin bp1920 {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

@mixin maxbp1024 {
    @media screen and (max-width: 1023px) {
        @content;
    }
}

@mixin maxbp1280 {
    @media screen and (max-width: 1279px) {
        @content;
    }
}

@mixin maxbp1440 {
    @media screen and (max-width: 1439px) {
        @content;
    }
}
////////////////////////////

@mixin text-dots {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin text( $size: null, $lineHeight: null, $weight: null, $color: null ) {
    @if $size != null {
        font-size: $size;
    }

    @if $lineHeight != null {
        line-height: $lineHeight;
    }

    @if $weight != null {
        font-weight: $weight;
    }

    @if $color != null {
        color: $color;
    }
}



@mixin scroll {
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar-button {
        width: 8px;
        height: 0px
    }

    &::-webkit-scrollbar-track {
        background: rgba(41, 50, 65, 0.1);
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #A7AFBE;
        border-radius: 8px;
    }

    &::-webkit-resizer {
        background-image: none;
        background-repeat: no-repeat;
        width: 8px;
        height: 8px
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-moz-scrollbarbutton {
        background-image: none;
        background-repeat: no-repeat;
        width: 4px;
        height: 0px
    }

    &::-moz-scrollbartrack {
        background: rgba(41, 50, 65, 0.1);
        border-radius: 8px;
    }

    &::-moz-scrollbarthumb {
        background: #A7AFBE;
        border-radius: 8px;
    }

    &::-moz-scrollbar {
        width: 8px;
        height: 8px;
    }
}

// Breakpoints
@mixin bp540 {
    @media screen and (min-width: 540px) {
        @content;
    }
}

@mixin bp768 {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin bp1024 {
    @media screen and (min-width: 1024px) {
        @content;
    }
}

@mixin bp1280 {
    @media screen and (min-width: 1280px) {
        @content;
    }
}

@mixin bp1440 {
    @media screen and (min-width: 1440px) {
        @content;
    }
}

@mixin bp1680 {
    @media screen and (min-width: 1680px) {
        @content;
    }
}

@mixin bp1920 {
    @media screen and (min-width: 1920px) {
        @content;
    }
}
