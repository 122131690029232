.easter-slider {
  overflow: hidden;
  display: flex;
  align-items: center;
  min-width: 1024px;
  &__inner {
    box-sizing: border-box;
  }

  &__circle{
    background-image: url("/joxi/easter/2023/icons/slider-arrow.svg");
    width: 46px;
    height: 46px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px;
    background-color: #F8EEDA;
    border-radius: 50%;
    position: relative;
    top: 0;
    transform: translateY(-2px);
    display: block;
    &:hover {
      background-color: #EFDFBF;
      box-shadow: inset 1px 3px 4px #FFFFFF, inset 0px -2px 4px #94763A;
    }
    &:active {
      background-color: #EFDFBF;
      box-shadow: inset 0px -2px 13px #94763A;
    }
  }

  &__btn {
    background-color: #C8B389;
    border: none;
    width: 50px;
    height: 53px;
    border-radius: 50%;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    display: flex;
    padding: 0;
    &.prev{
      position: absolute;
      left: calc(50% - 315px);
      z-index: 100;
      transform: translateX(-50%) scale(-1, 1);
    }

    &.next{
      position: absolute;
      right: calc(50% - 315px);;
      z-index: 100;
      transform: translateX(50%)
    }

  & img{
    height: 24px;
    background-color: red;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 0;
    transition: opacity .2s ease;
    position: relative;
    &.swiper-slide-active {

      .easter-box__inner {
        opacity: 1;
      }
    }
    &.is-today {
      min-height: 630px;
    }
    &.is-today,
    &.is-yesterday,
    &.is-previous {
      .easter-box__gift-img {
        filter: none;
      }

      .easter-box__gift-shine {
        display: block;
      }
    }
  }
}