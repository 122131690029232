.map-progress {
  width: 100%;
  max-width: 350px;
  min-height: 56px;
  background: rgba(102, 207, 133, 0.6);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(3px);
  border-radius: 16px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  @include breakpoint($bp4){
    max-width: 510px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 6px;
    width: 58px;
    height: 65px;
    background-image: url($cdn + '/summer-promo/active.png');
    background-size: cover;
    top: -11px;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    height: 69px;
    width: 69px;
    top: -12.5px;
    background-image: url($cdn + '/summer-promo/Gift_yellow.png');
    background-size: cover;
    z-index: 1;
  }

  &__inner {
    background: linear-gradient(0deg, #31974F, #31974F), linear-gradient(272.44deg, #072A68 4.15%, #174C8E 87.73%);
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    position: absolute;
    top: 17px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 274px;
    width: 100%;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint($bp4){
      max-width: 434px;
    }

    &::after {
      content: '';
      position: absolute;
      right: 24%;
      top: -11px;
      width: 36px;
      height: 39px;
      background-image: url($cdn + '/summer-promo/Gift_red.png');
      background-size: cover;
    }
  }

  &__range {
    position: absolute;
    left: 0;
    height: calc(100% - 4px);
    border-radius: 20px;
    transition: width .5s;
    background: repeating-linear-gradient(-45deg, #F1B314, #F1B314 10px, #F59A11 10px, #F59A11 20px);
  }

  &__text {
    @include text(14px, 14px, 700, $crema);
    font-family: $f-ubuntu;
    z-index: 1;
  }
}