$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.container {
  --container-gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 1536px;
  margin: 0 auto;
  padding: 0 var(--container-gap);

  @include bp1440 {
    --container-gap: 36px;
  }

  &_info {
    max-width: 1335px;
  }

  &_tournament,
  &_casino {
    max-width: 1872px;
  }

  &_main {
    max-width: 1512px;
  }
  &_vip {
    max-width: 1608px;
  }
  &_promotions {
    max-width: 1645px;
  }
}
