$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.footer {
  background-color: $color-grey-dark;
  padding: 30px 36px 40px;

  &__wrap {
    display: grid;
    grid-template-columns: minmax(224px, 264px) 1fr;
    grid-column-gap: 20px;
    width: 100%;
    margin: auto;
  }

  &__left-top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__chat {
    display: block;
    position: relative;
    height: 36px;
    width: 36px;
    margin-right: 16px;
    border: none;
    background-color: transparent;
    background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(45, 45, 50, 0) 100%);
    background-repeat: no-repeat;
    background-position: center;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 16px;
    }

    &:hover {
      background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 220, 38, 0.2) 55.73%, rgba(45, 45, 50, 0) 100%);
    }

    &::before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      filter: $filter-yellow;
    }
  }

  &__lang-wrap {
    display: flex;
    align-items: center;

    @include text(18px, 21px, 400, $color-white);
    text-transform: uppercase;
  }

  &__lang-img {
    margin-right: 4px;
    width: 26px;
    height: 26px;
    border: 2px solid $color-black;
    border-radius: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__list-item {
    @include text(13px, 32px, 400, $color-white);
    letter-spacing: 0.05em;
    transition: color .1s linear;
    width: 100%;
    &:hover,
    &.active {
      color: $color-yellow;
    }
  }

  &__right-wrap {
    padding-top: 8px;
  }

  &__payments {
    margin-bottom: 20px;
  }

  &__partners {
    margin-top: 32px;
    margin-bottom: 20px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footerLicense-icons {
    padding-left: 0;
    gap: 20px;
    justify-content: flex-start;

    li {
      padding-bottom: 0;
      height: 28px;
    }

    &__license {
      width: 34px;
      height: 34px;
    }
  }

  .footerLicense-hiddenText{
    margin-bottom: 20px;

    button{
      padding: 0;
    }
  }

  .footerLicense-hiddenText__inner{
    padding: 10px 10px 0;
  }

  .footerLicense-text p,
  .footerLicense-hiddenText {
    @include text(12px, 16px, normal, $color-white);

    direction: ltr;
    text-align: left;

    &:lang(ar) {
      direction: ltr;
      text-align: right;
    }
  }

  .footerLicense-text {
    p {
      &:not(&:last-child) {
        margin-bottom: 20px;
      }

      &:last-child {
        letter-spacing: 0.05em;
        color: #A4A4A4;
      }
    }
  }

  @include bp1440 {
    &__wrap {
      grid-column-gap: 24px;
    }
    &__payments {
      margin-bottom: 28px;
    }
    &__partners {
      margin-top: 40px;
      margin-bottom: 28px;
    }
  }

  @include bp1680 {
    &__partners {
      margin-bottom: 38px;
    }
  }
  @include bp1920 {
    &__wrap {
      max-width: 1752px;
    }
    &__partners {
      margin-bottom: 44px;
    }
  }
}
