$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-sidebar{
  padding-left:56px;

  &__content{
    min-height: 100vh;
    background-image: url( $cdn + '/fezbet/elements/pattern.png');
    background-size: 1000px auto;
  }

  @include bp1440 {
    padding-left: 72px;
  }
}
