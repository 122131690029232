$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-page {
  position:relative;
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 72px );
  padding: 53px 24px 0;
  background-image: url( $cdn + '/fezbet/elements/pattern.png');
  background-size: 1000px auto;

  &.is-demo {
    .game-page__cta-info {
      display: flex;
      position: initial;
      transform: translate(0px);
      padding: 0;
      height: 100%;
    }
  }

  &::before {
    content: "";
    position: absolute;
    background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 228, 88, 0.9) 0%, rgba(117, 0, 1, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 999px;
    height: 597px;
    top: 0;
    left: -500px;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(191, 0, 1, 0.9) 0%, rgba(25, 25, 29, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 1747px;
    height: 1044px;
    top: -385px;
    left: -1000px;
    z-index: -2;
  }

  &__game {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  &__game-wrap {
    margin: 0 auto;
    position: relative;
    border-top: 12px solid $color-grey-dark;
    border-radius: 12px 12px 0 0;
    background-color: #2d2d32;
  }

  &__frame {
    width: 100%;
    height: 100%;
  }

  &__cta {
    width: 100%;
    height: 56px;
    background-color: $color-grey-dark;
    border-radius: 0 0 12px 12px;
    margin-top: -3px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    max-width: 728px;
    min-width: 100%;

    .btn-favourite {
      max-width: 210px;
      @include text-dots;
      position: relative;
      z-index: 1;
      flex-shrink: 0;
    }
  }

  &__cta-info {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 230px;
    flex-grow: 1;
    margin-left: 10px;
    overflow: hidden;

  }
  &__cta-info_w {
    display: flex;
    align-items: center;
  }

  &__cta-text {
    @include text(16px, 20px, normal, $color-white);
    @include text-dots;

    margin: 0 12px;
  }

  &__cta-link {
    max-width: 210px;
    flex-shrink: 0;
  }

  &__sidebar {
    width: 240px;
  }
}
