@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@800&display=swap');

.christmas-popup {
  font-family: 'Jost';
  & * {
    font-family: 'Jost';
  }
}

.christmas-popup {
  background: linear-gradient(202.6deg, #f5e2c2 6.67%, #997e58 32.47%, #856243 89.66%);
  box-shadow: inset 0 4px 3px rgba(255, 255, 255, 0.4), inset 0px -3px 3px rgba(255, 255, 255, 0.26);
  border-radius: 32px;
  position: relative;
  z-index: 0;
  padding: 8px;
  margin: 0 auto 20px;
  width: 100%;
  max-width: 740px;

  &__elements {
    position: absolute;
    width: 100%;
    background-image: url('#{$cdn}/holidays/christmas/logo-decor-back.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    top: -44px;
    left: 0;
    height: 44px;
    @media (-webkit-max-device-pixel-ratio: 2) {
      background-image: url('#{$cdn}/holidays/christmas/logo-decor-back@2x.png');
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background-image: url('#{$cdn}/holidays/christmas/logo-decor-back@3x.png');
    }

    &:before {
      position: absolute;
      content: '';
      width: 68px;
      height: 68px;
      background-image: url('#{$cdn}/holidays/christmas/mistletoe.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      top: 14px;
      z-index: 10;
      left: 50%;
      transform: translateX(-50%);
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url('#{$cdn}/holidays/christmas/mistletoe@2x.png');
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url('#{$cdn}/holidays/christmas/mistletoe@3x.png');
      }
    }
  }

  &__past {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    width: fit-content;
    margin: 0 auto;
    padding: 8px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: rgba(255, 255, 255, 0.7);
  }

  &__close {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 20px;
    right: 20px;
    background-color: rgba(4, 33, 59, 0.4);
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    &:before {
      position: absolute;
      content: '';
      width: 28px;
      height: 28px;
      background-image: url($cdn + '/holidays/christmas/close.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      top: 50%;
      left: 50%;
      filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(305deg) brightness(104%) contrast(101%);
      transform: translate(-50%, -50%);
    }
  }

  &__body {
    background-image: url($cdn + '/holidays/christmas/glitter-popup_t_desk.png'), url($cdn + '/holidays/christmas/glitter-popup_b_desk.png'), linear-gradient(180deg, #357371 0%, #166B69 48.44%, #025855 100%);
    position: relative;
    border-radius: 24px;
    padding: 36px 24px 46px;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: top left, left bottom;
    background-size: contain;
  }

  &__title {
    font-weight: 800;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    text-shadow: 0 3px 3px rgba(2, 41, 40, 0.25);
  }

  &__subtext {
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 3px 3px rgba(2, 41, 40, 0.25);
  }

  &__img {
    background-size: cover, 0;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;

    &.is-previous,
    &.is-yesterday {
      background-image: url('#{$cdn}/holidays/christmas/img-gift/past.png');

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url('#{$cdn}/holidays/christmas/img-gift/past@2x.png');
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url('#{$cdn}/holidays/christmas/img-gift/past@3x.png');
      }
    }
    &.is-today {
      background-image: url('#{$cdn}/holidays/christmas/img-gift/today.png');

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url('#{$cdn}/holidays/christmas/img-gift/today@2x.png');
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url('#{$cdn}/holidays/christmas/img-gift/today@3x.png');
      }
    }
    &.is-next,
    &.is-future {
      background-image: url('#{$cdn}/holidays/christmas/img-gift/future.png');

      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url('#{$cdn}/holidays/christmas/img-gift/future@2x.png');
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url('#{$cdn}/holidays/christmas/img-gift/future@3x.png');
      }
    }

    width: 228px;
    height: 190px;
    margin: 18px auto;
  }

  &__btns {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }

  &__cont {
    display: grid;
    grid-gap: 4px;
    position: relative;
    z-index: 2;
    margin-top: 36px;
    margin-bottom: 25px;

    grid-template-columns: repeat(2, 1fr);
  }

  &__text {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__value {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #ffffff;
    opacity: 1;
    margin-left: 7px;
  }

  &__terms {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  &__terms-title {
    font-weight: 800;
    font-size: 16px;
    line-height: 120%;
    cursor: pointer;
    color: #87f0fc;
    position: relative;
    z-index: 2;
    width: fit-content;
    margin-bottom: 25px;
    overflow: unset;
    margin-right: 24px;

    &:before {
      position: absolute;
      content: '';
      background-image: url('#{$cdn}/holidays/christmas/arrow.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 24px;
      height: 24px;
      top: -2px;
      filter: invert(72%) sepia(9%) saturate(2681%) hue-rotate(173deg) brightness(119%) contrast(106%);
      right: -24px;
      transform: rotate(90deg);
    }

    &.open {
      &:before {
        transform: rotate(-90deg);
      }
    }

    &.open ~ .christmas-popup__terms-text {
      display: block;
    }
  }

  &__terms-text {
    display: none;

    p {
      display: grid;
      grid-template-columns: 16px 1fr;
      grid-gap: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #f6f6f6;
      margin-bottom: 16px;

      strong {
        font-weight: 700;
      }
    }
  }

  .christmas-count__mob {
    display: inline-flex;
    line-height: 33px;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin: 0 auto;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.03em;
    color: rgba(255, 255, 255, 0.7);
    padding-left: 16px;
    padding-right: 16px;
    p {
      color: #ffffff;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 5px;
    }
  }

  .christmas-count {
    display: flex;
  }

  .christmas-count__tab {
    display: none;
  }
}
