$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-homepage {
  padding: 0 36px;
  margin: 0 auto;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-image: url($cdn + "/fezbet/elements/circle.png"), radial-gradient(50% 50% at 50% 50%, rgba(255, 228, 88, 0.9) 0%, rgba(117, 0, 1, 0) 100%);
    background-repeat: no-repeat;
    background-size: 336px 336px, 100%;
    background-position: left 300px bottom, center;
    width: 999px;
    height: 597px;
    top: 50%;
    left: -540px;
    transform: translateY(-50%);
    z-index: 2;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(191, 0, 1, 0.9) 0%, rgba(25, 25, 29, 0) 100%);
    width: 1747px;
    height: 1044px;
    top: 50%;
    transform: translateY(-50%);
    left: -1200px;
    z-index: 1;
    pointer-events: none;
  }

  &__section{
    position:relative;
    z-index: 40;
    margin-top:40px;
    &.is-small{ margin-top: 28px; }
    &.is-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  }

  &__banner {
    display: block;
    margin-bottom: -56px;
    position: relative;
    z-index: 20;
  }

  @include bp1280 {
    &__section.is-grid{
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include bp1440 {
    padding: 0 60px;

    &__section{
      margin-top:60px;
      &.is-small{ margin-top: 48px; }
    }
  }

  @include bp1680 {
    max-width: 1512px;
    padding: 0;
  }
}

.home-banner {
  display: flex;
  align-items: center;

  &__wrapper-img {
    flex: 0 1 100%;
    transform: translateX(-5px);

    @include bp1280 {
      transform: translateX(24px);
    }

    @include bp1440 {
      transform: translateX(32px);
    }
  }

  &__img {
    display: block;

    @include bp1440 {
      width: 375px;
    }
  }

  &__text {
    flex: 0 1 100%;
    text-transform: uppercase;
    text-align: center;
  }

  &__type {
    @include text(18px, 133%, 700, $color-white);

    opacity: 0.5;
    margin-bottom: 4px;

    @include bp1440 {
      font-size: 24px;
    }
  }

  &__name {
    @include text(32px, 125%, 700, $color-white);

    max-width: 300px;
    margin: 0 auto;

    @include bp1440 {
      font-size: 48px;
      line-height: 1;
      max-width: 432px;
    }
  }

  &__amount {
    @include text(60px, 120%, 700, $color-yellow);

    margin-bottom: 16px;

    @include bp1440 {
      font-size: 72px;
    }
  }

  &__wrapper-logo {
    flex: 0 1 100%;
    display: flex;
    justify-content: center;
    transform: translateX(-10px);
  }

  &__logo {
    width: 260px;

    @include bp1440 {
      width: 290px;
    }
  }
}
