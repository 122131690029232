.summer-info {
  background-image: url($cdn + '/summer-promo/comming-bg.png');
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
  background-color: #40a65e;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 11px 15px;
  color: $c-white;
  font-family: $f-jost;
  @include breakpoint($bp2) {
    justify-content: center;
  }

  &__text {
    font-weight: 800;
    font-size: 56px;
    word-break: break-word;
    line-height: 56px;
    text-align: center;
    width: 100%;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
    max-width: 252px;
    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      width: 100%;
      max-width: 220px;
      height: 8px;
      right: 8px;
      bottom: 3px;
      background: #000000;
      opacity: 0.2;
      filter: blur(2px);
      border-radius: 6px;
    }
  }
  &__inner {
    background: rgba(102, 207, 133, 0.6);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
    backdrop-filter: blur(3px);
    width: 100%;
    max-width: 350px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
  }

  &__town {
    width: 324px;
    height: 324px;
  }
  .info-summer {
    margin-bottom: 16px;
    &__title {
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      line-height: 28px;
    }
    &__completed {
      width: 100%;
      max-width: 294px;
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
    }
    &__countdown {
      background: #31974f;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
      border-radius: 60px;
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      padding: 10px 24px;
    }
  }
  .summer-item {
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 10px;
    }
  }
}
