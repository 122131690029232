$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.preloader{
  position:relative;
  & > * {
    opacity: 0.4;
  }

  &::before{
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-image: var(--icon-preloader);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: 900;
    .popup &{ z-index: 1900;}
    .is-modal &{ z-index: 2900;}

    position:absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }
}

.has-preloader-btn {

  color: transparent !important;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: var(--icon-preloader-dots);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 34px;
  }

}
