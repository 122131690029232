.christmas-count {
  &__mob {
    display: none;
  }

  &__tab {
    display: grid;
    grid-template-columns: repeat(3, 48px);
    column-gap: 21px;
    justify-content: center;
  }

  &__number {
    position: relative;
    width: 48px;
    height: 52px;
    font-size: 24px;
    line-height: 52px;
    font-weight: 800;
    color: #fff;
    letter-spacing: 0.08em;
    text-align: center;
    background: linear-gradient(148.27deg, #389591 19.1%, #2e9692 101.19%);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08), inset 0px -2px 4px rgba(12, 131, 138, 0.6);
    border-radius: 5px 5px 0px 0px;
    border-radius: 5px;
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 2px;
      background: #44aaa6;
      z-index: -1;
    }

    &:not(:last-child):after {
      content: ':';
      position: absolute;
      top: 0;
      right: -13px;
      height: 100%;
      width: 5px;
      font-size: 20px;
      line-height: 58px;
      color: rgba(255, 255, 255, 0.4);
      text-align: center;
    }
  }

  &__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50px;
    margin-top: 10px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    color: #8d5224;
  }
}
