.map-icon {
  position: fixed;
  background-color: rgba(102, 207, 133, 0.6);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(3px);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transition: 0.2s background-color;
  cursor: pointer;

  &:active {
    background-color: $green2;
  }

  @include breakpoint($bp3) {
    &:hover {
      background-color: $green2;
    }
  }

  &--long {
    @include breakpoint($bp3) {
      width: auto;
      height: 44px;
      border-radius: 24px;
      padding: 10px 20px 10px 48px;
      background-size: 20px;
      background-position: 20px center;
    }
  }

  &__text {
    font-family: $f-jost;
    @include text(20px, 24px, 800, $c-white);
    display: none;

    @include breakpoint($bp3) {
      display: block;
    }
  }

  &.icon-next {
    background-image: url($cdn + '/summer-promo/arrowl.svg');
    transform: rotate(90deg) translateY(50%);
    top: 12px;
    left: 50%;

    @include breakpoint($bp3) {
      top: 24px;
    }

    &.not-today {
      background-color: $orange;

      &:active {
        background-color: $green2;
      }

      @include breakpoint($bp3) {
        &:hover {
          background-color: $green2;
        }
      }
    }
  }

  &.icon-prev {
    background-image: url($cdn + '/summer-promo/arrowl.svg');
    transform: rotate(270deg) translateY(-50%);
    bottom: 84px;
    left: 50%;
    cursor: pointer;

    @include breakpoint($bp3) {
      bottom: 92px;
    }

    &.not-today {
      background-color: $orange;

      &:active {
        background-color: $green2;
      }

      @include breakpoint($bp3) {
        &:hover {
          background-color: $green2;
        }
      }
    }
  }

  &.icon-home {
    top: 12px;
    left: 12px;
    background-image: url($cdn + '/summer-promo/home.svg');

    @include breakpoint($bp3) {
      top: 24px;
      left: 24px;
    }
  }

  &.icon-info {
    position: absolute;
    background-image: url($cdn + '/summer-promo/quest.svg');
    right: 0;

    @include breakpoint($bp3) {
      position: relative;
    }
  }

  &.icon-sound {
    position: absolute;
    background-image: url($cdn + '/summer-promo/sound-on.svg'), url($cdn + '/summer-promo/sound-off.svg');
    background-size: auto, 0;
    top: 48px;
    right: 0;
    &.unmute {
      background-size: 0, auto;
    }
    @include breakpoint($bp3) {
      position: relative;
      top: 0;
      margin-left: 24px;
    }
  }

  &.icon-i {
    position: absolute;
    right: -16px;
    top: -16px;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 12px;
      width: 3px;
      height: 4px;
      transform: translateX(-50%);
      background-color: $c-white;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 19px;
      width: 3px;
      height: 11px;
      transform: translateX(-50%);
      background-color: $c-white;
    }
  }
}

.map-icon-wrapper {
  position: fixed;
  right: 12px;
  top: 12px;
  z-index: 2;

  @include breakpoint($bp3) {
    display: flex;
    align-items: center;
    top: 24px;
    right: 24px;
  }
}
