.summer-day {
  font-family: $f-jost;
  max-width: 350px;
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  position: relative;

  @include breakpoint($bp1){
    height: 880px;
  }

  @include breakpoint($bp2) {
    height: 900px;
  }

  @include breakpoint($bp4) {
    max-width: 510px;
  }

  &:nth-child(even) {
    align-self: flex-start;
    height: 373px;

    @include breakpoint($bp1){
      height: 636px;
    }

    @include breakpoint($bp2) {
      height: 613px;
    }

    @include breakpoint($bp5) {
      height: 653px;
    }
  }

  &__img-wrap {
    position: relative;
  }

  &__img {
    width: 280px;
    height: 268px;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
    background-image: url($cdn + '/summer-promo/islands/monaco.png');
    animation: flyingTown 2s ease-in-out infinite alternate;

    // &.is-active {

    // }

    &.is-missed {
      background-image: url($cdn + '/summer-promo/test-prev-unknown.png');
    }

    @include breakpoint($bp2) {
      width: 426px;
      height: 408px;
    }

    @include breakpoint($bp3) {
      width: 280px;
      height: 268px;
    }

    @include breakpoint($bp4) {
      width: 426px;
      height: 408px;
    }

    @media screen and (max-width: 1440px) and (max-height: 800px) {
      width: 280px;
      height: 268px;
    }

    @include breakpoint($bp5) {
      width: 512px;
      height: 490px;
    }
  }

  &__shadow {
    background-image: url($cdn + '/summer-promo/town-shadow.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 268px;
    height: 157px;
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    animation: flyingShadow 2s ease-in-out infinite alternate;

    @include breakpoint($bp2) {
      top: 170px;
      width: 408px;
      height: 239px;
    }

    @include breakpoint($bp3) {
      top: 130px;
      width: 268px;
      height: 157px;
    }

    @include breakpoint($bp4) {
      top: 170px;
      width: 408px;
      height: 239px;
    }

    @media screen and (max-width: 1440px) and (max-height: 800px) {
      top: 130px;
      width: 268px;
      height: 157px;
    }

    @include breakpoint($bp5) {
      top: 200px;
      width: 490px;
      height: 280px;
    }
  }

  &__date {
    margin-top: -90px;
    background: rgba(102, 207, 133, 0.7);
    backdrop-filter: blur(3px);
    border-radius: 60px;
    padding: 10px 24px;
    position: relative;
    @include text(20px, 24px, 800, $c-white);

    &.is-missed,
    &.is-next {
      padding-left: 52px;

      &:before {
        content: '';
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background-size: cover;
        background-position: center;
      }
    }
    &.is-next {
      &::before {
        background-image: url($cdn + '/summer-promo/lock.png');
      }
    }
    &.is-missed {
      &::before {
        background-image: url($cdn + '/summer-promo/close.svg');
      }
    }
    &.is-prev-taken {
      &::after {
        content: '';
        background-image: url($cdn + '/summer-promo/check.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 15px 10px;
        position: absolute;
        top: -12px;
        right: -12px;
        width: 29px;
        height: 29px;
        border-radius: 50%;
        box-shadow: 0 5.18905px 14.2699px rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(12.9726px);
        background-color: $c-white;
      }
    }
  }

  &__name {
    @include text(56px, 81px, 800, $c-white);
    text-transform: uppercase;
    z-index: 1;
  }

  &__info {
    position: relative;
    max-width: 350px;
    width: 100%;

    @include breakpoint($bp4) {
      max-width: 510px;
    }
  }
}

@keyframes flyingTown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-30px);
  }
}

@keyframes flyingShadow {
  from {
    transform: translateX(-50%) scale(1);
  }
  to {
    transform: translateX(-50%) scale(1.2);
  }
}
