$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.header-nav{
  --menu-h:28px;

  position:relative;
  margin:0 8px 0 16px;
  height: var(--menu-h);
  overflow: hidden;
  padding:0 10px;

  &:lang(ar) {
    margin: 0 16px 0 8px;
  }

  &__scroll{
    height: 50px;
    max-width: 100% ;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__list{
    height: var(--menu-h);
    display: flex;

  }

  &__item-tooltip {
    height: 24px;
    color: #ffdc26;
    white-space: nowrap;
    z-index: 21;
    background: #2d2d32;
    border-radius: 4px;
    padding: 0 8px;
    font-size: 12px;
    font-weight: bold;
    line-height: 24px;
    display: none;
    position: absolute;
    text-transform: none;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.2));

    &::after {
      content: "";
      width: 12px;
      height: 12px;
      background: #2d2d32;
      border-radius: 2px;
      position: absolute;
      top: 1px;
      left: calc(50% - 6px);
      transform: translateY(-50%)rotate(-45deg);
    }
  }

  &__item{
    flex-shrink: 0;
    display: flex;
    align-items: center;
    font-family: var(--font-family-main);
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding:4px 8px 4px 4px;

    height:(--menu-h);
    border-radius:  var(--menu-h) ;

    &-label {
      margin-left: 8px;
      color: $color-yellow;
      font-size: 12px;
      font-style: italic;
    }

    &:hover,
    &.active,{ color: $color-yellow; }

    &:hover {
      .header-nav__item-tooltip {
        display: block;
      }
    }

    &.summer {
      &::before{
        display: none;
      }
    }

    //&.is-promotions{ background: rgba(255, 220, 38, 0.1); }
    &:not(:first-of-type){ margin-left:4px; }

    &::before{
      flex-shrink: 0;
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right:4px;
    }

    &:lang(ar) {
      &::before {
        margin-right: 0;
        margin-left: 4px;
      }
    }

    &.is-sport::before{ background-image: var(--icon-nav_sports_1); }
    &.is-sport:hover::before,
    &.is-sport.active::before{ background-image: var(--icon-nav_sports_2); }

    &.is-live::before{ background-image: var(--icon-nav_live_1); }
    &.is-live:hover::before,
    &.is-live.active::before{ background-image: var(--icon-nav_live_2); }

    &.is-virtual::before{ background-image: var(--icon-nav_virtual_1); }
    &.is-virtual:hover::before,
    &.is-virtual.active::before{ background-image: var(--icon-nav_virtual_2); }

    &.is-casino::before{ background-image: var(--icon-nav_casino_1); }
    &.is-casino:hover::before,
    &.is-casino.active::before{ background-image: var(--icon-nav_casino_2); }

    &.is-livecasino::before{ background-image: var(--icon-nav_livecasino_1); }
    &.is-livecasino:hover::before,
    &.is-livecasino.active::before{ background-image: var(--icon-nav_livecasino_2); }

    &.is-shop::before{ background-image: var(--icon-shop-icon); }
    &.is-shop:hover::before,
    &.is-shop.active::before{ background-image: var(--icon-shop-icon-hover); }

    &.is-halloween::before{ background-image: var(--icon-nav_halloween_1); }
    &.is-halloween:hover::before,
    &.is-halloween.active::before{ background-image: var(--icon-nav_halloween_2); }
    &.is-holiday {
      position: relative;
      margin-left: 12px;
      padding: 0;
      width: auto;
      height: 36px;
      background-color: transparent;
      justify-content: center;

      &::before {
        display: none;
      }

      .is-holiday__img {
        display: block;
        width: 20px;
        height: 20px;
        background-image: var( --icon-summer-icon-normal);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        animation: rotateIcon 3s linear infinite;
        margin-right: 4px;
      }

      .is-seasons {
        position: absolute;
        transform: translateX(-50%);
        background-color: $color-red;
        border-radius: 35px;
        color: $color-white;
        margin: 0;
        font-style: normal;
        display: flex;
        line-height: 10px;
        padding: 3px 4px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        left: 10px;
        bottom: 4px;
        font-size: 7px;
        height: 9px;
      }
    }

    &.is-holiday:hover {
      .is-holiday__img {
        background-image: var( --icon-summer-icon-hover);
      }
    }
  }

  &__arr{
    cursor:pointer;
    position:absolute;
    width: 30px;
    height: 100%;
    background-image: linear-gradient(to right,   #2D2D32 50% , rgba(45, 45, 50, 0) 100%) ;
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-image:  var(--icon-arrow-left);
      background-position: -2px center;
      background-repeat: no-repeat;
      filter: $filter-grey;
    }
    &:hover::before{
      filter: $filter-white;
    }

    &.is-left {
      left: 0;

      &:lang(ar) {
        right: 0;
        left: unset;
        transform: rotate(180deg);
      }
    }

    &.is-right {
      right: 0;
      transform: rotate(180deg);

      &:lang(ar) {
        left: 0;
        right: unset;
        transform: unset;
      }
    }

    &.is-disable {
      display: none;
    }
  }


  @include bp1440 {
    --menu-h:38px;
    margin:0 45px 0 24px;

    &:lang(ar) {
      margin: 0 24px 0 45px;
    }

    &__scroll{ height: 50px;}
    &__item{
      font-size: 18px;
      line-height: 28px;
      padding:5px 10px 5px 5px;

      &::before{
        width: 28px;
        height: 28px;
      }
    }
  }

  @include bp1680 {
    &__item {
      &:not(:first-of-type){ margin-left:9px; }
    }
  }
}

.header__crab {
  &.is-crab {
    position: relative;
    &:hover {
      .header-nav__item-tooltip {
        display: block;
      }
    }
  }
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
