.christmas-arr-btn {
    position: relative;
    display: block;
    width: 63px;
    height: 55px;
    padding: 4px;
    border: none;
    text-decoration: none;
    border-radius: 15px;
    background: linear-gradient(#f09e56 0%, #96541a 41.21%, #683305 100%);
    filter: drop-shadow(0px 3px 8px rgba(98, 50, 8, 0.4));
    transition: 0.2s;
  
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-image: linear-gradient(#d2843e 13.26%, #874912 100%);
    }
  
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-image: var(--icon-easter-arr);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30px;
      filter: invert(1);
      transform: rotate(-180deg);
    }
  
    &:hover {
      filter: drop-shadow(0px 3px 8px rgba(98, 50, 8, 0.6));
    }
}