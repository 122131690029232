.christmas-calendar {
  margin-top: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoint($bp1) {
    margin-top: 70px;
  }

  &__bonus {
    flex-grow: 0;
    @include breakpoint($bp1) {
      flex-grow: 1;
    }

    z-index: 1;
    min-height: 472px;
    padding: 8px;
    border-radius: 32px;
    &::after {
      content: '';
      position: absolute;
      width: 148px;
      height: 64px;
      left: calc(50% - 148px / 2);
      top: -29px;
      background-image: url($cdn + '/holidays/christmas/bells.png');
      background-repeat: no-repeat;
      background-size: contain;
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/holidays/christmas/bells@2x.png');
        background-repeat: no-repeat;
      background-size: contain;
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url($cdn + '/holidays/christmas/bells@3x.png');
        background-repeat: no-repeat;
      background-size: contain;
      }
    }
  }

  &__content {
    padding: 28px 34px 20px;
    position: relative;
    border-radius: 24px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      border-radius: 24px;
      background-image: url($cdn + '/holidays/christmas/glitter.png');
      background-size: cover;
      background-position: center;
      z-index: 1;
      @media (-webkit-max-device-pixel-ratio: 2) {
        background-image: url($cdn + '/holidays/christmas/glitter@2x.png');
        background-size: cover;
      background-position: center;
      }
      @media (-webkit-max-device-pixel-ratio: 3) {
        background-image: url($cdn + '/holidays/christmas/glitter@3x.png');
        background-size: cover;
      background-position: center;
      }
    }
    &::after {
      content: '';
      position: absolute;
      width: 390px;
      height: 390px;
      left: 50%;
      top: 50%;
      margin-left: -195px;
      margin-top: -195px;
      background: radial-gradient(50% 50% at 50% 50%, #40a8a6 0%, rgba(32, 90, 89, 0) 100%);
      z-index: 0;
    }
  }

  &__slider {
    z-index: 1;
  }

  &__tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 48px;
    border-radius: 5px;
    background: #52b0ad;
    width: max-content;
  }

  &__tab {
    min-width: 120px;
    font-weight: 800;
    font-size: 20px;
    line-height: 48px;
    font-weight: 900;
    background: #207774;
    color: #58c6c2;
    text-align: center;
    margin: 0;
    padding: 0 16px;
    cursor: pointer;
    text-shadow: 0px 1px 2px rgba(11, 69, 67, 0.5);
    border: 2px solid #52b0ad;
    border-radius: 0px 5px 5px 0px;

    &:first-child {
      border-radius: 5px 0px 0px 5px;
    }

    &.is-active {
      background: #52b0ad;
      color: #fff;
    }
  }

  &__days {
    position: relative;
    left: -9px;
    width: 466px;
    height: 364px;
    flex-shrink: 0;
    padding: 4px;
    border-radius: 12px;
    display: none;
    @include breakpoint($bp1) {
      display: block;
    }
    &--line {
      display: block;
      @include breakpoint($bp1) {
        display: none;
      }
    }
  }

  &__timer {
    margin: 12px 0 16px;
  }
}
