$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #ffdc26;
  border-radius: 4px;
}
.scroll:hover::-webkit-scrollbar-thumb {
  background-color: #ffdc26;
  border-radius: 4px;
}

.scroll.is-grey::-webkit-scrollbar-thumb {
  border-radius: 4px;
}
.scroll.is-grey:hover::-webkit-scrollbar-thumb {
  background-color: #E7E7E4;
  border-radius: 4px;
}

.scroll.is-lightyellow::-webkit-scrollbar-thumb {
    background-color: rgba(255, 220, 38, 0.5);
}
