$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.trustly-currency {
  border-radius: 0 12px 12px 0;
  @include text(24px, 44px, bold, $color-black);
  font-family: var(--font-family-main);
  width: 32px;
  text-align: center;
  top: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
}
