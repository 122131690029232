$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.article-info {
  margin: 40px 0;
  z-index: 2;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  section {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
  }

  div {
    padding-left: 45px;
  }

  & h1,
  & h2 {
    background-image: url($cdn + '/fezbet/elements/small_circle.png');
    background-repeat: no-repeat;
    background-size: 45px 45px;
    background-position: left top 10px;
    @include text(20px, 28px, bold);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: $ff;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding-bottom: 15px;
    padding-top: 20px;
    margin-left: -45px;
    padding-left: 45px;
  }

  p,
  li,
  strong {
    @include text(16px, 24px);
    padding-bottom: 8px;
  }
  strong {
    font-weight: bold;
  }
  h4,
  h3 {
    @include text(16px, 24px);
    padding-bottom: 8px;
    text-align: center;
    font-weight: bold;
  }
  ul {
    list-style: disc;
    list-style-position: inside;

    &.is-50w {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 24px;
    }
  }

  button {
    display: none;
  }
  img {
    position: absolute;
    width: 21px;
    height: 21px;
    top: 22px;
    left: 12px;
  }

  //** для сео-текста в 2 колонки **//

  &.is-50w {
    main {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 28px 0;
    }

    div {
      max-height: 115px;
      overflow: hidden;
      order: 0;
      position: relative;
    }

    & button {
      display: block;
      @include text(16px, 24px, bold, $color-yellow);
      position: relative;
      border: none;
      background-color: transparent;
      padding: 0;
      margin-left: 45px;
      margin-top: 9px;
      order: 1;
      width: fit-content;

      &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background-image: var(--icon-arrow-down);
        background-repeat: no-repeat;
        background-size: 100%;
        top: 4px;
        right: -17px;
        background-position: right center;
        filter: $filter-yellow;
      }

      &:hover {
        opacity: 0.3;
      }

      & span {
        display: none;
        pointer-events: none;

        &:first-child {
          display: block;
        }
      }
    }


    @include bp1440 {
      main {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 28px 64px;
      }
      div {
        max-height: 208px;

        &:lang(pt-BR) {
          max-height: 217px;
        }
      }
      button {
        margin-top: 15px;
      }

      &.is-50w button {
        margin-left: 60px;
      }
    }
  }

  @include bp1440 {
    section {
      max-width: 912px;
    }

    .rtl-lang &.ferrari-terms-info section {
      margin: 0;
    }

    & h1,
    & h2 {
      @include text(28px, 32px);
      padding-bottom: 20px;
      padding-top: 24px;
      background-size: 60px 60px;
      background-position: left top 17px;
      margin-left: -60px;
      padding-left: 60px;
    }
    img {
      width: 28px;
      height: 28px;
      top: 32px;
      left: 16px;
    }
    div {
      padding-left: 60px;
    }
  }
}

///*****HELPERS*****///

.article-info.is-50w button.open {
  & span:first-child {
    display: none;
  }
  & span:last-child {
    display: block;
  }
  &::after {
    filter: $filter-grey;
    transform: rotate(180deg);
  }
  & + div {
    max-height: 100%;
  }
}
