.christmas-btn {
  box-sizing: border-box;
  display: block;
  height: 52px;
  padding: 4px;
  border: none;
  text-decoration: none;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  border-radius: 5px;
  min-width: 157px;
  background: linear-gradient(141.79deg, #2e918d 8.09%, #177673 74.45%);
  box-shadow: 0px 2px 3px rgba(2, 41, 40, 0.08), inset 0px 1px 0px #41b7b3;
  cursor: pointer;
  transition: 0.2s;
  color: #ffffff;
  text-shadow: 0px 1px 3px rgba(47, 7, 4, 0.5);

  &:hover {
    background: linear-gradient(141.79deg, #4fc7c3 8.09%, #249995 74.45%);
    box-shadow: 0px 2px 3px rgba(2, 41, 40, 0.08), inset 0px 1px 0px #66f5f1;
  }

  &.is-disabled {
    background: linear-gradient(131.03deg, #697f8c 14.66%, #4a5f6e 90.05%);
    box-shadow: 0px 2px 3px rgba(2, 41, 40, 0.08), inset 0px 1px 0px #8f9faa;
    pointer-events: none;
  }
  &.is-active-bonus,
  &.is-red {
    background: linear-gradient(141.79deg, #de523f 8.09%, #d64239 74.45%);
    box-shadow: 0px 2px 3px rgba(2, 41, 40, 0.08), inset 0px 1px 0px #ff986c;
    transition: 0.2s;
    pointer-events: auto;
    color: #ffffff;
    text-shadow: 0px 1px 3px rgba(47, 7, 4, 0.5);
    &:hover {
      background: linear-gradient(141.79deg, #ff6c3d 8.09%, #ef421b 74.45%);
      box-shadow: 0px 2px 3px rgba(2, 41, 40, 0.08), inset 0px 1px 0px #ffb494;
    }
  }

  &__text {
    display: block;
    font-weight: 800;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 1px 3px rgba(47, 7, 4, 0.5);
    .is-disabled & {
      color: #8297a6;
      text-shadow: none;
    }
  }
}
