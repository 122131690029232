$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-vip-page {
  position: relative;
  padding-bottom: 60px;

  &::before {
    content: "";
    position: absolute;
    background-image:
      url($cdn + "/fezbet/elements/circle.png"),
      radial-gradient(50% 50% at 50% 50%, rgba(255, 228, 88, 0.9) 0%, rgba(117, 0, 1, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    background-repeat: no-repeat;
    background-size: 336px 336px, 100%;
    background-position: left 300px bottom, center;
    width: 999px;
    height: 597px;
    top: 0px;
    left: -500px;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(191, 0, 1, 0.9) 0%, rgba(25, 25, 29, 0) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 1747px;
    height: 1044px;
    top: -385px;
    left: -1000px;
    z-index: -2;
  }

  @include bp1280 {
    &::before {
      top: 108px;
    }
  }

  @include bp1680 {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 60px;
  }

  @include bp1920 {
    &::before {
      left: -560px;
    }
  }
}
