$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-terms-info div {
  padding: 0 !important;
}
.ferrari-terms-title {
  text-align: center;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  background-image: none;
  margin: 0 0 15px;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}
.ferrari-terms-info {
  max-height: 300px;
  margin: 0 0 20px;
  overflow: hidden;
  transition: max-height 0.8s ease;
  position: relative;
  &.is-terms-2-stage {
    padding: 0;
    max-width: 640px;

    @include bp1280 {
      padding: 0 20px;
      max-width: 840px;
    }

    @include bp1440 {
      padding: 0;
      max-width: 800px;
    }
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    filter: blur(20px);
    box-shadow: 0 -5px 35px 35px #19191d;
    pointer-events: none;
  }
  h2 {
    background-image: none;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
  p {
    position: relative;
    text-align: start;
    padding-inline-start: 24px;
  }
  span {
    position: absolute;
    inset-inline-start: 0;
  }
}
