dvs-page {
  color: #000000;
  font-family: $ff;
}

.verification-content-wrap {
  background-color: #ffffff;
  padding: 20px 20px 20px 20px;

  .verification-content-status:first-child {
    .verification-status {
      margin-top: 0;
    }
  }
}
