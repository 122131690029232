$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.trustly-wrap {
  background-image: url( $cdn + '/fezbet/elements/gradient_circle.png');
  background-size: contain;
  background-position: left -80px top -300px;
  background-repeat: no-repeat;

  @include bp1440 {
    background-position: left -25px top -300px;
  }


}
