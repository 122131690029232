$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vertical-steps {
  margin-inline-start: 4px;
  background-repeat: no-repeat;
  background-image: linear-gradient(188deg, var(--grLineWhite)), linear-gradient(184deg, var(--grLineBlack));
  background-position: 2px 100%, 3px 100%;
  background-size: 1px calc(100% - 0px);

  &:lang(ar) {
    background-position: right 2px top 100%, right 3px top 100%;
  }

  &__item {
    @include text(14px, 24px, bold, rgba(255, 255, 255, 0.3));
    font-family: var(--font-family-main);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-top: 28px;
    padding-inline-start: 19px;
    position: relative;

    .trustly-page__steps & {
      margin-top: 44px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 6px;
      height: 6px;
      border: 1px solid #6B6B6E;
      background-color: #2B2B30;
      border-radius: 50%;
      inset-inline-start: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      color: $color-white;
      opacity: 1;
      cursor: pointer;

      &::before {
        border: 1px solid $color-white;
      }
    }

    .popup-page__steps.is-step-1 &:first-child,
    .popup-page__steps.is-step-2 &:nth-child(2),
    .popup-page__steps.is-step-3 &:nth-child(2),
    .popup-page__steps.is-step-4 &:nth-child(3),
    .popup-page__steps.is-step-5 &:nth-child(3),
    body:not(.linda-has-user) .popup-page__steps.is-step-6 &:nth-child(3),
    body.linda-has-user .popup-page__steps &:nth-child(4),
    .trustly-page__steps.is-step-0 &:first-child,
    .trustly-page__steps.is-step-1 &:nth-child(2) {
      @include text(18px, 24px, null, $color-white);

      &::before {
        width: 8px;
        height: 8px;
        border: 2px solid #999999;
        background-color: $color-white;
      }
    }

    .popup-page__steps.is-step-2 &:first-child,
    .popup-page__steps.is-step-3 &:nth-child(1),
    .popup-page__steps.is-step-4 &:nth-child(1),
    .popup-page__steps.is-step-4 &:nth-child(2),
    .popup-page__steps.is-step-5 &:nth-child(1),
    .popup-page__steps.is-step-5 &:nth-child(2),
    .popup-page__steps.is-step-6 &:nth-child(1),
    .popup-page__steps.is-step-6 &:nth-child(2),
    body.linda-has-user .popup-page__steps &:nth-child(1),
    body.linda-has-user .popup-page__steps &:nth-child(2),
    body.linda-has-user .popup-page__steps &:nth-child(3),
    .trustly-page__steps.is-step-1 &:nth-child(1) {

      &::before {
        background-color: transparent;
        background-image: var(--icon-i-choose);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        filter: $filter-green;
        width: 24px;
        height: 24px;
        border-color: transparent;
        left: -9px;
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        background-color: $color-black;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        left: -9px;
      }
    }
  }
}
