$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.gh-box{
  position:relative;
  display: flex;
  flex-wrap: wrap;
  margin:0 -8px;

  &__item{
    margin:8px;
    width: calc(25% - 16px);
    box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  }

  @include bp1280 {
    margin:0 -10px;
    &__item{
      margin:10px;
      width: calc(20% - 20px);
    }
  }

  @include bp1440 {
    margin:0 -12px;
    &__item{
      margin:12px;
      width: calc(20% - 24px);
    }
  }

  @include bp1680{
    margin:0 -12px;
    &__item{
      margin:12px;
      width: calc(16.6667% - 24px);
    }
  }

  @include bp1920 {
    &__item{ width: calc(14.2857% - 24px); }
  }
}
