.summer-calendar {
  display: flex;
  flex-direction: column-reverse;
  padding: 12.5% 10%;
  overflow: hidden;

  @include breakpoint($bp1){
    padding: 12% 13%;
  }

  @include breakpoint($bp2){
    padding: 12% 15%;
  }

  @include breakpoint($bp3){
    padding: 12% 25%;
  }

  @include breakpoint($bp4){
    padding: 12% 28%;
  }
}