$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.autobonus-btns {
  padding-top:18px;
  text-align: center;

  &__wrap {
    margin-top: 12px;
    text-align: center;
    @include text(14px, 20px, normal, $color-grey);
  }

  &__btn {
    min-height: 44px;
    height: auto;
    padding-top: 11px;
    padding-bottom: 11px;
    line-height: 122%;
    white-space: initial;
  }

  &__link {
    display: block;
    margin-top: 6px;
    text-decoration: underline;
    color: $color-grey;
  }
}
