$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
//// .btn-60-yellow   -  высота 60px, на всех bp одинаковая ////
//// .btn-44-yellow   -  высота 44px, на всех bp одинаковая ////
//// .btn-36-yellow  -  высота 36px переходит на 44px, средняя с желтым градиентом ////
//// .btn-32-yellow  - высота 32px, на всех bp одинаковая, маленькая с желтым градиентом  ////
//// .btn-stroke.is-h36  -  высота 36px переходит на 44px, большая прозрачная ////
//// .btn-stroke.is-h32  - маленькая прозрачная ////
//// .btn-44-red  - красная с градиентом ////

.btn {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  border-radius: 50px;
  font-family: var(--font-family-main);
  font-weight: bold;
  border: none;
  text-align: center;
  @include text-dots;
  cursor: pointer;
  max-width: 100%;

  &:disabled,
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}

////////// .btn-60-yellow  //////////

.btn-60-yellow {
  @include text(24px, 60px, null, $color-black);
  background: $color-yellow;
  box-shadow: 0 0 0 rgba(25, 25, 29, 0.25), inset 0 12px 15px rgba(255, 255, 255, 0.8), inset 0 -12px 15px $color-orange;
  padding: 0 36px;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 2px 8px rgba(25, 25, 29, 0.25), inset 0 12px 15px rgba(255, 255, 255, 0.8), inset 0px -12px 15px $color-orange;
    transform: translateY(-2px);
    transition: 0.3s;
  }

  &:active {
    background: #EEA315;
    box-shadow: inset 0 12px 15px rgba(232, 142, 15, 0.8), inset 0 -12px 15px rgba(255, 255, 255, 0.4);
  }
}


////////// .btn-44-yellow  //////////

.btn-44-yellow {
  @include text(18px, 44px, null, $color-black);
  background-color: $color-yellow;
  box-shadow: 0 0 0 rgba(25, 25, 29, 0.25), inset 0 10px 12px rgba(255, 255, 255, 0.8), inset 0px -10px 12px $color-orange;
  padding: 0 28px;
  min-width: 150px;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 2px 8px rgba(25, 25, 29, 0.25), inset 0 10px 12px rgba(255, 255, 255, 0.8), inset 0px -10px 12px $color-orange;
    transform: translateY(-2px);
    transition: 0.3s;
  }

  &:active {
    background: #EEA315;
    box-shadow: inset 0 10px 12px rgba(232, 142, 15, 0.8), inset 0px -10px 12px rgba(255, 255, 255, 0.4);
  }
  &.is-sticky-ferrari {
      bottom: 0;
      z-index: 999;
      position: sticky;
  }
  &.is-trustly {
    padding-left: 44px;

    &::before {
      content: "";
      position: absolute;
      left: 4px;
      top: 4px;
      display: block;
      width: 36px;
      height: 36px;
      background-color: $color-white;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      background-image: var(--icon-i-zimpler);
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      z-index: 10;
    }

  }


}

////////// .btn-36-yellow  //////////

.btn-36-yellow {
  @include text(16px, 36px, null, $color-black);
  background: $color-yellow;
  box-shadow: 0 0 0 rgba(25, 25, 29, 0.25), inset 0 10px 12px rgba(255, 255, 255, 0.8), inset 0 -10px 12px $color-orange;
  padding: 0 24px;
  transition: 0.3s;


  &:hover {
    box-shadow: 0 2px 8px rgba(25, 25, 29, 0.25), inset 0 10px 12px rgba(255, 255, 255, 0.8), inset 0px -10px 12px $color-orange;
    transform: translateY(-2px);
    transition: 0.3s;
  }

  &:active {
    background: #EEA315;
    box-shadow: inset 0 10px 12px rgba(232, 142, 15, 0.8), inset 0 -10px 12px rgba(255, 255, 255, 0.4);
  }

  @include bp1440 {
    min-width: 150px;
    padding: 0 28px;
    @include text(18px, 44px);
  }
}

////////// .btn-32-yellow  //////////

.btn-32-yellow {
  @include text(14px, 32px, null, $color-black);
  background: $color-yellow;
  box-shadow: 0 0 0 rgba(25, 25, 29, 0.25), inset 0 6px 8px rgba(255, 255, 255, 0.8), inset 0 -6px 8px $color-orange;
  padding: 0 20px;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 2px 8px rgba(25, 25, 29, 0.25), inset 0 6px 8px rgba(255, 255, 255, 0.8), inset 0px -6px 8px $color-orange;
    transform: translateY(-2px);
    transition: 0.3s;
  }

  &:active {
    background: #EEA315;
    box-shadow: inset 0 6px 8px rgba(232, 142, 15, 0.6), inset 0 -6px 8px rgba(255, 255, 255, 0.4);
  }
}


////////// .btn-stroke  //////////

.btn-stroke {
  background-color: transparent;
  padding: 0 28px;
  border-radius: 40px;
  border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-image-source: var(--icon-i-border-btn);
  border-image-slice: 22;
  border-image-width: 22px;

  &.is-h36 {
    @include text(16px, 36px, null, $color-yellow);
    height: 36px;

    @include bp1440 {
      min-width: 150px;
      height: 44px;
      @include text(18px, 43px, null, $color-yellow);
    }
  }

  &.is-h32 {
    @include text(14px, 28px, null, $color-yellow);
    height: 32px;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px #827E6E;
    border-width: 1px;
    padding: 0 29px;
  }

  &:active {
    border-image: none;
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: $color-black;
    border-width: 0px;
    box-shadow: inset 0 0 0 1px $color-yellow;
  }
}

////////// .btn-44-red  //////////

.btn-44-red {
  @include text(18px, 44px, null, $color-white);
  background-color: $color-red;
  box-shadow: 0 0 0 $color-black, inset 0 10px 12px rgba(255, 255, 255, 0.5), inset 0 -10px 12px #9F1007;
  padding: 0 28px;
  min-width: 150px;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 4px 12px $color-black, inset 0 10px 12px rgba(255, 255, 255, 0.5), inset 0 -10px 12px #9F1007;
    transform: translateY(-2px);
    transition: 0.3s;
  }

  &:active {

    color: rgba(255, 255, 255, 0.6);
    box-shadow: inset 0 -10px 12px rgba(255, 255, 255, 0.2), inset 0 10px 12px #9F1007;
  }
}

////////// .btn-36-orange  //////////

.btn-36-orange {
  @include text(16px, 36px, null, $color-white);
  background: linear-gradient(180deg, #FFC440 0%, #DD251A 82%);
  box-shadow: 0 0 0 rgba(25, 25, 29, 0.25), inset 0 6px 8px rgba(255, 246, 215, 0.6), inset 0 -6px 10px rgba(40, 13, 2, 0.5);
  padding: 0 24px;
  transition: 0.3s;


  &:hover {
    background: linear-gradient(180deg, #FFC440 0%, #DD251A 82%);
    box-shadow: 0 2px 8px rgba(25, 25, 29, 0.25), inset 0 6px 8px rgba(255, 246, 215, 0.6), inset 0 -6px 10px rgba(40, 13, 2, 0.5);
    transform: translateY(-2px);
    transition: 0.3s;
  }

  &:active {
    background: linear-gradient(180deg, #E64E24 0%, #F9A93A 100%);
    box-shadow: none;
    color: rgba(255, 255, 255, 0.5);
  }

  @include bp1440 {
    min-width: 150px;
    padding: 0 28px;
    @include text(18px, 44px);
  }
}

////////// .btn-44-orange  //////////

.btn-44-orange {
  @include text(18px, 44px, null, $color-white);
  background: linear-gradient(180deg, #FFC440 0%, #DD251A 82%);
  box-shadow: 0 0 0 rgba(25, 25, 29, 0.25), inset 0 6px 8px rgba(255, 246, 215, 0.6), inset 0 -6px 10px rgba(40, 13, 2, 0.5);
  padding: 0 28px;
  transition: 0.3s;


  &:hover {
    background: linear-gradient(180deg, #FFC440 0%, #DD251A 82%);
    box-shadow: 0 2px 8px rgba(25, 25, 29, 0.25), inset 0 6px 8px rgba(255, 246, 215, 0.6), inset 0 -6px 10px rgba(40, 13, 2, 0.5);
    transform: translateY(-2px);
    transition: 0.3s;
  }

  &:active {
    background: linear-gradient(180deg, #E64E24 0%, #F9A93A 100%);
    box-shadow: none;
    color: rgba(255, 255, 255, 0.5);
  }


  @include bp1440 {
    &.is-responsive {
      @include text(24px, 60px);
      min-width: 200px;
      padding: 0 36px;
      box-shadow: 0 0 0 rgba(25, 25, 29, 0.25), inset 0 12px 10px rgba(252, 254, 165, 0.4), inset 0 -12px 15px #8C1212;

      &:hover {
        box-shadow: 0 2px 8px rgba(25, 25, 29, 0.25), inset 0 12px 10px rgba(252, 254, 165, 0.4), inset 0 -12px 15px #8C1212;
      }
    }

  }
}

////////// .btn-60-orange  //////////

.btn-60-orange {
  @include text(24px, 150%, 700, $color-white);
  background: linear-gradient(180deg, #FFC440 0%, #DD251A 82%);
  box-shadow: 0 0 0 rgba(25, 25, 29, 0.25), inset 0 6px 8px rgba(255, 246, 215, 0.6), inset 0 -6px 10px rgba(40, 13, 2, 0.5);
  padding: 12px 36px;
  transition: 0.3s;

  &:hover {
    background: linear-gradient(180deg, #FFC440 0%, #DD251A 82%);
    box-shadow: 0 2px 8px rgba(25, 25, 29, 0.25), inset 0 6px 8px rgba(255, 246, 215, 0.6), inset 0 -6px 10px rgba(40, 13, 2, 0.5);
    transform: translateY(-2px);
    transition: 0.3s;
  }

  &:active {
    background: linear-gradient(180deg, #E64E24 0%, #F9A93A 100%);
    box-shadow: none;
    color: rgba(255, 255, 255, 0.5);
  }
}
