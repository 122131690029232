$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-bonus-stub {
  width: 800px;
  max-width: 100%;
  height: 280px;
  max-height: 100%;
  border: 4px solid rgba(255, 220, 38, 0.5);
  border-radius: 20px;
  background-image: url($cdn + '/fezbet/bonuses/promo.png'), linear-gradient(90deg, rgba(146, 66, 21, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 36px 100px 48px;

  &.on-board {
    width: 100%;
    height: 100%;
    padding: 32px 16px 40px;
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: var(--font-family-main);
    @include text-dots;
  }

  &__text {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: #FFDC26;
    font-family: var(--font-family-main);
    margin: 16px 0 28px 0;
    @include text-dots;

    &:lang(hu) {
      font-size: 42px;
    }
  }

  @include bp1440 {
    &__title {
      @include text(28px, 36px);
    }
    &__text {
      @include text(64px, 84px);
      margin: 8px 0 24px 0;
    }
  }
}
