$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-back {
  font-family: var(--font-family-main);
  @include text(18px, 20px, null, $color-black);
  padding-inline-start: 24px;
  filter: $filter-white;
  background-image: var(--icon-arrow-left);
  background-size: 20px;
  background-repeat: no-repeat;
  text-transform: uppercase;

  &:lang(ar) {
    background-image: var(--icon-arrow-right);
    background-position: center right;
  }

  &:hover {
    filter: $filter-yellow;
  }

  &.is-black {
    filter: none;

    &:hover {
      filter: $filter-grey-light;
    }
  }
}
