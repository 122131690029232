$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
html {
  font-size: 12px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

body {
  font-family: $font-alternative;
  font-weight: 400;
  line-height: normal;
  min-height: 100%;
  background-color: $color-black;
  min-width: 1024px;
  color: $color-white;
  overflow-x: hidden;

  &:has(summer-promo-wrapper) {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

a,
button {
  cursor: pointer;
}

linda-view-layer-one,
linda-view-layer-two,
linda-view-layer-three,
linda-view-layer-four,
linda-view-layer-five,
ui-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ss {
  height: 200px;
  border: solid 1px red;
}
