$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.gh-box-row{
  margin-top:16px;
  display: grid;
  grid-gap: 31px;
  grid-template-columns: repeat( 6 , 1fr );

  &__item:nth-of-type(7),
  &__item:nth-of-type(8) {
    display: none;
  }

  @include bp1440 {
    grid-template-columns: repeat( 7 , 1fr );

    &__item:nth-of-type(7){ display: block; }
  }

  @include bp1680{
    grid-template-columns: repeat( 8 , 1fr );

    &__item:nth-of-type(8){ display: block; }
  }
}
