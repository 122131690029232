$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.home-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left:16px;
  height: 44px;
  background-image: linear-gradient( to top , var(--grLineWhite)) , linear-gradient( to top , var(--grLineBlack)) ;
  background-size: 1px 100%;
  background-repeat: no-repeat;
  background-position: left center , 1px center;
  margin-bottom:16px;

  &:lang(ar) {
    direction: rtl;
    background-image: none;
    padding-left: 0;
  }

  & span {
    @include text(24px, 44px, bold, null);
    @include text-dots;

    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-family: var(--font-family-main);
    margin-right: 17px;
  }

  & a {
    flex-shrink: 0;
  }
}
