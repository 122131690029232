$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-support {
    height: 68px;
    margin-top: -40px;
    @include text(14px, 20px, normal, $color-black);
    @include text-dots;
    text-align: center;
    background: $color-white;
    padding: 24px 60px;
    border-radius: 0px 0px 20px 20px;

    a {
        text-decoration: underline;
    }
    @include bp1280 {
        margin-top: -32px;
    }
    @include bp1440 {
        margin-top: -16px;
    }
}
