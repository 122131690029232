$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sport-category {
    display: flex;
    justify-content: space-between;
    &.has-b-m {
        margin-bottom: 40px;
        @include bp1440 {
            margin-bottom: 60px;
        }
    }
    &__item {
        width: calc((100% - 100px) / 6);
        position: relative;
        flex-shrink: 0;
        &::before {
            content: '';
            padding-top: 100%;
            display: block;
        }
        &.is-last {
            .sport-category__item-wrap {
                background-color: transparent;
                border: 1px solid #000;
                border-image-source: var(--icon-border);
                border-image-slice: 32;
                border-image-width: 32px;
                box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.75);
                padding: 16px;
            }
            .sport-category__text {
                max-height: 72px;
                overflow: hidden;
            }
        }
        @include bp1280 {
            width: calc((100% - 120px) / 7);
        }
        @include bp1440 {
            width: calc((100% - 144px) / 7);
        }
        @include bp1680 {
            width: calc((100% - 168px) / 8);
        }
        &:nth-last-of-type(2) {
            display: none;
            @include bp1680 {
                display: flex;
            }
        }
        &:nth-last-of-type(3) {
            display: none;
            @include bp1280 {
                display: flex;
            }
        }

        &:hover {
            .sport-category__img {
                transform: translateY(-20px);
                transition: transform .3s;
            }
            .sport-category__shadow {
                transform: scale(0.7);
                transition: transform .3s;
            }
        }
        &:not(:hover) {
            .sport-category__img {
                animation: bounce 1.15s;
                transition: transform .3s;
            }
            .sport-category__shadow {
                animation: shadow 1.15s;
                transform: scale(1);
                transition: transform .7s .7s;
            }
        }

    }
    &__item-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        padding: 12px;
        width: 100%;
        height: 100%;
        background-color: $color-yellow;
        box-shadow: inset 0px 8px 10px rgba(255, 255, 255, 0.8), inset 0px -8px 10px #FF7A00;
        border-radius: 32px;
    }
    &__icon {
        position: relative;
        text-align: center;
        margin-bottom: 14px;
    }
    &__img {
        width: 48px;
        height: 48px;
        transform-origin: center bottom;
        transition: transform .3s;
        @include bp1440 {
            width: 60px;
            height: 60px;
        }
    }
    &__shadow {
        width: 58px;
        height: 4px;
        background-image: radial-gradient(50% 50% at 50% 50%, rgba(165, 77, 14, 0.4) 0%, rgba(171, 72, 16, 0) 100%);
        background-repeat: no-repeat;
        transition: transform .3s;
        @include bp1440 {
            width: 76px;
            height: 4px;
        }
    }
    &__title {
        max-width: 100%;
        @include text(18px, 20px, bold, $color-black);
        @include text-dots;
        font-family: var(--font-family-main);
        text-transform: uppercase;
        z-index: 2;
        @include bp1440 {
            font-size: 20px;
            line-height: 24px;
        }
    }
    &__text {
        max-width: 100%;
        @include text(18px, 24px, bold, $color-yellow);
        word-wrap: break-word;
        letter-spacing: 0.05em;
        font-family: var(--font-family-main);
        text-transform: uppercase;
        margin-bottom: 12px;
        text-align: center;
    }
    .link-next {
        flex-shrink: 0;
    }
}
@keyframes bounce {
    from {
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }

    20% {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
        transform: translate3d(0, 0, 0) scaleY(0.95);
    }

    63% {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0) scaleY(0.98);
        transform: translate3d(0, 0, 0) scaleY(0.98);
    }

    40% {
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
}
@keyframes shadow {
    from {
        -webkit-transform: scaleX(0.7);
        transform: scaleX(0.7);
    }

    20%,
    63% {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: scaleX(0.85);
        transform: scaleX(0.85);
    }

    40% {
        -webkit-transform: scaleX(0.7);
        transform: scaleX(0.7);
    }

    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
}
