.verification-status {
  align-items: center;
  background: #ffffff no-repeat 14px 50% ;
  border: 10px solid transparent;
  font-weight: bold;
  font-size: 22px;
  display: flex;
  line-height: 26px;
  margin-top: 16px;
  min-height: 88px;
  padding-left: 80px;
  body.rtl-lang & {
    direction: rtl;
    background-position: calc(100% - 14px) 50%;
    padding-right: 80px;
  }

  &_not_verified {
    background-image: url($cdn + '/dvs/i-status-not-verified.svg');
    border-color: rgba(79, 195, 247, 0.2);

    .rtl-lang & {
      background-image: url($cdn + '/dvs/i-status-not-verified-rtl.svg');
    }
  }

  &_failed_verification,
  &_requires_verification {
    background-image: url($cdn + '/dvs/i-status-requires.svg');
    border-color: rgba(251, 192, 45, 0.2);
  }

  &_pending_verification {
    background-image: url($cdn + '/dvs/i-status-pending.svg');
    border-color: rgba(255, 160, 0, 0.2);
  }

  &_verified, &_none {
    background-image: url($cdn + '/dvs/i-status-verified.svg');
    border-color: rgba(0, 200, 83, 0.2);
  }
}

