$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
:root {
  --grLineWhite:
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 51.04%,
    rgba(255, 255, 255, 0) 100%;
  --grLineBlack:
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 51.04%,
    rgba(0, 0, 0, 0) 100%;
  --grCircleDark: radial-gradient(#2B2B30 calc(60% - 1px), #6B6B6E 60%);
  --grCircleLight: radial-gradient(#fff calc(50% - 1px), #999999 50%);
  --headerHeight: 56px;
  --sidebarWidth: 56px;
  --font-family-main: 'Fez','Roboto Condensed', Arial, Helvetica, sans-serif;

  &:lang(cs) {
    --font-family-main: 'Roboto Condensed', Helvetica, Arial, sans-serif;
  }

  @include bp1440 {
    --headerHeight: 72px;
    --sidebarWidth: 72px;
  }
}
