$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link {
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  color: $color-grey;

  &.is-black {
    color: $color-black;
  }
  &:hover {
    color: $color-black;
  }
}
