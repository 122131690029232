$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.info {
  width: 100%;
  padding: 0 0 60px 16px;
  position: relative;

  &:lang(ar) {
    padding: 0 16px 60px 0;
  }

  @include bp1280 {
    padding-left: 24px;

    &:lang(ar) {
      padding-left: 0;
      padding-right: 24px;
    }
  }

  @include bp1440 {
    max-width: 1020px;
    padding-left: 32px;

    &:lang(ar) {
      padding-left: 0;
      padding-right: 32px;
    }
  }

  @include bp1920 {
    padding-left: 36px;

    &:lang(ar) {
      padding-left: 0;
      padding-right: 36px;
    }
  }
}
