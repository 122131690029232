$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-bonus {
  margin-bottom: 16px;

  &--title {
    margin-bottom: 8px;
    @include text(16px, 150%, 700, $color-grey-dark);
    letter-spacing: 0.48px;
  }
  &--text {
    @include text(18px, 28px, null, $color-black);
  }
  &--opted-in {
    margin-bottom: 4px;
    @include text(16px, 100%, 400, $color-grey);
    letter-spacing: 0.48px;
  }
  &--link {
    color: inherit;
    text-decoration: underline;
    transition: color .2s ease-out;

    &:hover {
      color: $color-grey-dark;
    }
  }
  &--icon {
    width: 16px;
    height: 16px;
    vertical-align: text-top;
    color: $color-green;
  }
}
