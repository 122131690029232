$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.santa-popup-wrapper {
  padding: 56px 40px 0
}

.santa-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding-bottom: 48px;

  &__title {
    color: #19191D;
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
  }

  &__description {
    font-family: Arial, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.42px;
    text-align: center;
  }

  &__cta {
    margin-top: 12px;
  }

  &__details {
    margin-top: 4px;
  }

  &__image {
    filter: drop-shadow(0px 3px 1.5px rgba(0,0,0,0.25));
  }
}
