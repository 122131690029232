.summer-welcome {
  font-family: $f-jost;
  max-width: 303px;
  width: 100%;
  min-height: 500px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  background-image: url($cdn + '/summer-promo/welcome-bg.jpg');
  background-position: -1px -2px;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint($bp3){
    max-width: 400px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 303px;
    border-radius: 20px;
    background: linear-gradient(180deg, rgba(5, 67, 88, 0) 0%, rgba(5, 67, 88, 0.691303) 50%, rgba(5, 67, 88, 0.8) 76.56%, #054358 85.94%, #054358 100%);
  }

  & > * {
    position: relative;
    z-index: 1;
  }

  &__close {
    position: absolute;
    width: 40px;
    top: 10px;
    right: 10px;
    background: rgba(102, 207, 133, 0.6) url($cdn + '/easter/2023/icons/close.svg') no-repeat center;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
    backdrop-filter: blur(3px);
    height: 41px;
    z-index: 2;
    border: none;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0;
    cursor: pointer;

    &:active {
      background-color: $green2;
    }

    @include breakpoint($bp3) {
      &:hover {
        background-color: $green2;
      }
    }
  }

  &__img {
    width: 309px;
    height: 357px;
    margin-top: -95px;
    margin-left: -45px;
  }

  &__label {
    border-radius: 23px;
    @include text(14px, 1, 700, $c-white);
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background-color: #D12924;
    margin-bottom: 13px;
    text-transform: uppercase;
    margin-top: -30px;
  }

  &__title {
    @include text(42px, 1, 800, $c-white);
    margin-bottom: 6px;
    text-transform: uppercase;
    text-align: center;
    max-width: 300px;
  }

  &__text {
    @include text(18px, 26px, 700, $c-white);
    margin-bottom: 12px;
    padding: 0 30px;
  }

  &-wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0,0,0,.6);
  }
}