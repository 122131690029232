.container-reward {
  position: relative;
  margin: auto;
}
.summer-popup {
  $root: &;
  width: 100%;
  max-width: 350px;
  font-family: $f-jost;
  position: relative;
  padding: 16px;
  background: #40a65e;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
  border-radius: 16px;
  &.is-reward {
    padding: 24px 16px 0;
    min-height: 430px;
  }

  &.is-padding {
    padding: 16px 54px;
    min-height: 214px;
  }

  &__reward-image {
    display: block;
    width: 195px;
    position: absolute;
    height: 195px;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    top: -65px;
    z-index: -1;
  }
  &__item {
    color: $c-white;
  }
  .box-item {
    text-align: center;
    margin-bottom: 16px;
    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
    }
    &__text {
      font-size: 16px;
      line-height: 23px;
    }
  }
  .reward-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 242px;
    height: 72px;
    margin-bottom: 16px;
    background: #57bf76;
    border-radius: 6px;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 50px;
      height: 52px;
      background-image: url($cdn + '/summer-promo/freespin.png');
      background-repeat: no-repeat;
      position: absolute;
      left: -20px;
      background-size: cover;
      background-position: center;
    }
    &__text {
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
    }
    &__title {
      font-size: 16px;
      line-height: 23px;
    }
  }
  .quit-btn {
    font-weight: 700;
    font-family: $f-jost;
    font-size: 18px;
    line-height: 26px;
    background: transparent;
    border: none;
    color: $c-white;
    outline: none;
  }
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .box {
    color: $c-white;
    text-align: center;
    margin-bottom: 16px;
    &__title {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    }
    &__text {
      font-size: 16px;
      line-height: 23px;
    }
  }
  &__close {
    position: absolute;
    top: -17px;
    right: -16px;
    width: 40px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
    backdrop-filter: blur(3px);
    height: 41px;
    z-index: 2;
    border: none;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    background: rgba(102, 207, 133, 0.6) url($cdn + '/easter/2023/icons/close.svg') no-repeat center;
    padding: 0;
    cursor: pointer;

    &:active {
      background-color: $green2;
    }

    @include breakpoint($bp3) {
      &:hover {
        background-color: $green2;
      }
    }
  }
  &__inner {
    width: 100%;
    position: relative;
    &.is-display {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  &__box {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: $c-white;
    margin-bottom: 21px;
  }
  &__collection {
    background: #57bf76;
    width: 100%;
    max-width: 318px;
    min-height: 110px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 12px;
    margin-bottom: 33px;
    position: relative;
  }
  .inner-step {
    &.is-bottom {
      margin-bottom: 16px;
    }
    &__info {
      width: 100%;
      max-width: 170px;
      padding: 20px 16px 20px 0;
    }
    &__number {
      position: absolute;
      top: -8px;
      left: -9px;
      width: 26px;
      background: rgba(102, 207, 133, 0.6);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
      backdrop-filter: blur(3px);
      height: 26px;
      z-index: 2;
      color: $c-white;
      border: none;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 0;
      @include text(19px, 18px, 700, $c-white);
    }
    &__img {
      position: absolute;
      width: 115px;
      left: 9px;
    }
    &__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 18px;
      color: $c-white;
      margin-bottom: 4px;
    }
    &__text {
      font-size: 14px;
      line-height: 16px;
      color: $c-white;
    }
  }
  &__img {
    transform: translateY(-90px) translateX(90px);
    position: absolute;
    width: 136px;
    &.is-small-device {
      @media screen and (max-height: 720px) {
        display: none;
      }
    }
  }
  &__terms {
    padding-bottom: 55px;
    &.is-low {
      padding-bottom: 0;
    }
  }
  &__terms-trigger {
    font-family: $f-jost;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: rgba(102, 207, 133, 0.6);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    border-radius: 8px;
    min-height: 48px;
    padding: 12px 16px;
    color: $c-white;
    outline: none;
    border: none;

    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-image: url($cdn + '/summer-promo/arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      transition: transform 0.2s ease;
    }
    span {
      margin-right: 7px;
    }
  }

  &__terms-inner {
    width: 100%;
    display: none;
    border-radius: 0 0 8px 8px;
    background: rgba(102, 207, 133, 0.6);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
    font-family: $f-jost;
  }
  &__terms-list {
    margin: 0;
    padding: 12px 16px 16px;
    color: $c-white;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;

    p {
      margin-top: 0;
    }
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    &.is-margin {
      margin-bottom: 20px;
    }
    &.is-side {
      position: absolute;
      bottom: -35px;
      left: 50%;
      transform: translateX(-50%);
    }
    &.is-reward {
      transform: translateY(100px);
      position: absolute;
      bottom: 0;
      right: 100px;
    }
    &.is-small {
      position: absolute;
      transform: translateY(65px);
      bottom: 0;
    }
    &.is-cash {
      margin-bottom: 16px;
    }
    &.is-onboard {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -33px;
    }
  }
  &__requirements {
    pointer-events: none;
    margin-bottom: 16px;
  }

  &__requirements-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
    background: #31974f;
    border-radius: 8px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__requirements-item-descr {
    margin-right: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba($c-white, 0.6);
    text-align: left;
  }

  &__requirements-item-val {
    font-weight: 600;
    color: $c-white;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
  }

  &__title {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 46px;
    line-height: 66px;
    text-align: center;
    position: relative;
    color: $c-white;
    &.is-size {
      font-weight: 800;
      margin-bottom: 0;
      font-size: 25px;
      line-height: 36px;
    }
  }
  &__row-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 41px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $c-white;
    opacity: 0.4;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    flex-shrink: 0;
    margin: 47px 0 0;

    span {
      height: 14px;
      display: flex;
      align-items: center;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 12px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba($c-white, 0.4);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }
  &__row-bonus {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    width: 100%;
    max-width: 132px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0 0 10px;
  }
  &__row-title {
    white-space: nowrap;
    text-align: center;
    width: 100%;
    max-width: 144px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 16px;
    line-height: 23px;
    color: $c-white;
  }
  &__row-radio {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  &__row-trigger-btn {
    display: flex;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 60px;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      width: 100%;
      height: 100%;
      top: -2px;
      left: -1px;
      background: #137931;
      box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);
      border-radius: 60px;
    }

    &::after {
      width: 100%;
      height: 100%;
      top: 0;
      left: -1px;
      background-image: url($cdn + '/summer-promo/circle.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transform: scale(0);
      opacity: 0;
      transition-property: transform, opacity;
      transition-duration: 0.2s;
    }
  }

  &__row-radio:checked + &__row-trigger-btn::before {
    background-color: #31974f;
  }

  &__row-radio:checked + &__row-trigger-btn::after {
    transform: scale(1);
    opacity: 1;
  }

  &__row-box {
    background: #31974f;
    box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.18);
    border-radius: 16px;
    position: relative;
    font-weight: 600;
    width: 144px;
    display: flex;
    text-align: center;
    color: $c-white;
    font-size: 12px;
    line-height: 16px;
    height: 123px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.is-active {
      background: rgba(102, 207, 133, 0.6);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
      &:before {
        content: '';
        display: block;
        background-image: url($cdn + '/summer-promo/polygon.svg ');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 22px;
        position: absolute;
        left: 60px;
        bottom: -17px;
        height: 12px;
      }
    }

    &.is-casino {
      background: rgba(102, 207, 133, 0.6);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
      width: auto;
      height: auto;
      margin-bottom: 16px;
      padding: 39px 31px 16px;
      margin-top: 49px;
      @include text(14px, 16px, 600, $c-white);

      #{$root}__row-icon {
        width: 60px;
        height: 64px;
      }
      #{$root}__row-bonus {
        max-width: 100%;
      }
    }
  }
  &__row-icon {
    position: absolute;
    width: 50px;
    height: 54px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    top: -40px;
  }
  &__row-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    &:first-child {
      order: 0;
    }

    &:nth-child(2) {
      order: 2;
    }

    &:only-child {
      width: 100%;
    }
  }

  &__selected {
    margin-bottom: 16px;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__text {
    font-weight: 700;
    color: $c-white;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 16px;
    text-align: center;
  }
  &__refil {
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: $c-white;
    margin-bottom: 16px;
  }
  &__bonus {
    &:after {
      content: '';
      display: block;
      border-bottom: 1px solid #ffffff;
      margin-bottom: 16px;
      opacity: 0.3;
    }
  }
  &__bonus-title {
    font-size: 16px;
    line-height: 23px;
    color: $c-white;
    margin-bottom: 8px;
    text-align: center;
  }
  &__bonus-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $c-white;
    position: relative;
    width: 100%;
    max-width: 270px;
    margin: 0 auto 16px;
  }
}
.is-summer {
  .popup {
    background: none;
    transition: none;
  }
  .main-view {
    filter: none;
  }
}
.popup.is-summer-reward {
  overflow-x: hidden;
  animation: none;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 55px 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-height: 600px) and (max-width: 1280px) and (orientation: landscape) {
    overflow-y: hidden;
    z-index: -1;
  }
  &.is-terms-open {
    align-items: flex-start;
    padding-top: 80px;

    .summer-popup__terms-trigger {
      border-radius: 12px 12px 0 0;
      box-shadow: none;

      &:after {
        transform: rotate(180deg);
      }
    }
    .summer-popup__terms-inner {
      display: block;
      box-shadow: none;
    }
  }
}
