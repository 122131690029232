.christmas-content {
    width: 100%;
    height: 100%;
    min-height: 500px;
    margin: 0 auto;
    flex-grow: 1;
    z-index: 1;
    box-sizing: border-box;
    @include breakpoint($bp1) {
        max-width: 1024px;
    }
}