$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-lvl{
  margin-top:16px;


  &__marks{
    display: flex;
    justify-content: space-between;
  }
  &__mark{
    @include text (16px, 24px, null, $color-grey);
    letter-spacing: 0.03em;
    text-transform: capitalize;
    @include text-dots;
    max-width: 50%;

    &.is-current{ color:$color-yellow;}
  }


  &__progress{
    position:relative;
    margin-top: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #868A92;

  }
  &__progress-i{
    position:absolute;
    left: 0;
    top: 0;
    height: 8px;
    border-radius: 4px;
    background-color: $color-yellow;
  }

}
