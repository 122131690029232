$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-points {
    width: 100%;
    @include bp1280 {
        width: 360px;
    }
    &__title {
        @include text(28px, 35px, bold, rgba(134, 138, 146, 0.6));
        font-family: var(--font-family-main);
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }
    &__item {
        padding: 20px 0  0 72px;
        position: relative;
        background-image:  var(--icon-profile-card), url( $cdn + '/fezbet/elements/small_circle.png');
        background-repeat: no-repeat, no-repeat;
        background-position: 26px 26px, 10px 10px;
        background-size: auto, 60px 60px;
        margin-top: 16px;
        @include bp1280 {
            margin-top: 24px;
        }
        &:nth-of-type(3) {
            background-image:  var(--icon-profile-bets), url( $cdn + '/fezbet/elements/small_circle.png');
            background-position: 30px 28px, 10px 10px;
        }
        &:nth-of-type(4) {
            background-image:  var(--icon-profile-tournament), url( $cdn + '/fezbet/elements/small_circle.png');
            background-position: 28px 28px, 10px 10px;
        }
    }
    &__item-link {
        display: block;
        width: fit-content;
    }
    &__item-title {
        @include text(32px, 38px, bold, $color-white);
        font-family: var(--font-family-main);
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-bottom: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &__item-text {
        @include text(16px, 24px, normal, $color-white);
        margin-bottom: 12px;
    }
}
