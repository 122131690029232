.summer-loader {
  min-height: 100vh;
  position: fixed;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: $f-jost;
  color: $c-white;
  background-color: #40a65e;
  background-image: url($cdn + '/summer-promo/game.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint($bp1) {
    background-image: url($cdn + '/summer-promo/back-desk.png');
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__img {
    height: 476px;
    margin-top: 20px;
    width: 428px;
    object-fit: cover;
  }
  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transform: translateY(-100px);
  }
  @keyframes spinner-loader {
    0% {
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  &__text {
    font-weight: 800;
    font-size: 56px;
    word-break: break-word;
    margin-bottom: 23px;
    line-height: 56px;
    text-align: center;
    width: 100%;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
    max-width: 252px;
    @include breakpoint($bp3) {
      margin-bottom: 58px;
    }
    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      width: 100%;
      max-width: 220px;
      height: 8px;
      right: 8px;
      bottom: 3px;
      background: #000000;
      opacity: 0.2;
      filter: blur(2px);
      border-radius: 6px;
    }
  }
  &__load {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 18px;
    line-height: 28px;
    text-align: center;
  }
  &__spinner {
    animation: spinner-loader 1.2s infinite linear;
    background-image: url($cdn + '/summer-promo/ellipse.svg');
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    width: 51px;
    height: 51px;
  }
}
