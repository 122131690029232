@mixin text($size: null, $lineHeight: null, $weight: null, $color: null) {
    @if $size  !=null {
        font-size: $size;
    }

    @if $lineHeight  !=null {
        line-height: $lineHeight;
    }

    @if $weight  !=null {
        font-weight: $weight;
    }

    @if $color  !=null {
        color: $color;
    }
}

@mixin breakpoint($width) {
    @media (min-width: #{$width}) {
        @content;
    }
}