.play-tomorrow {
  font-family: $f-jost;
  width: 100%;
  max-width: 350px;
  min-height: 56px;
  background: rgba(102, 207, 133, 0.6);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(3px);
  border-radius: 16px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 12px 10px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint($bp4){
    max-width: 510px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -13px;
    left: 0;
    width: 69px;
    height: 69px;
    background-image: url($cdn + '/summer-promo/Gift_yellow.png');
    background-size: cover;
  }

  &__text {
    @include text(12px, 14px, 700, $c-white);
  }
  &__name {
    @include text(16px, 23px, 800, $c-white);
    text-transform: uppercase;
  }
  &__countdown {
    border-radius: 3px;
    background-color: $darkRed;
    padding: 1px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    @include text(16px,23px,700,$c-white);
  }
}