$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.paymentcb {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: 38px;
    background: url($cdn + '/fezbet/elements/pattern.jpg') repeat;

		//? states
		&.is-success .show-fail, &.is-success .show-processing,
		&.is-processing .show-fail, &.is-processing .show-success,
		&.is-failed .show-success, &.is-failed .show-processing {
				display: none;
		}

    &.is-failed &__title {
        padding-bottom: 80px;
    }

    &__logo {
        display: block;
        width: 120px;
        height: 40px;
        background: var(--icon-logo);
        background-size: contain;
        margin: 0 auto;
        flex-shrink: 0;

        &:hover {
            opacity: 0.5;
        }
    }

    &__wrap {
        max-width: 440px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    &__title {
        @include text(28px, 35px, bold, $color-white);
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.03em;
        font-family: var(--font-family-main);
    }

    &__subtitle {
        @include text(14px, 20px, normal, $color-white);
        padding-top: 20px;
        padding-bottom: 40px;
    }

    &__table {
        width: 584px;
        padding-bottom: 40px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 24px;
        overflow: hidden;
        @include text-dots;
    }

    &__key {
        @include text(18px, 24px, bold, rgba(255, 255, 255, 0.5));
        font-family: var(--font-family-main);
    }

    &__val {
        @include text(16px, 24px, normal, $color-white);
    }

    &__text {
        @include text(14px, 20px, normal, $color-white);
        letter-spacing: 0.03em;
        margin-top: 20px;
        margin-bottom: 12px;
        text-align: center;
    }

    &__ptc {
        @include text(36px, 44px, bold, $color-white);
        text-shadow: 0px 4px 15px rgba(40, 39, 42, 0.1);
        font-family: var(--font-family-main);
        padding-bottom: 60px;
        display: flex;
        width: 96px;
        justify-content: center;
    }

    &__minutes,
    &__seconds {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__dots {
        width: 16px;
        text-align: center;
    }

    &__minutes-description,
    &__seconds-description {
        @include text(10px, 16px, normal, $color-white);
        @include text-dots;
        max-width: 40px;
    }

    &__chat {
        @include text(14px, 20px, normal, $color-white);
        letter-spacing: 0.03em;
        padding-bottom: 28px;
        text-align: center;
    }

    &__bottom {
        padding-top: 40px;
        @include text(14px, 24px, normal, null);
    }

    &__link {
        text-decoration: underline;
        color: $color-white;
        cursor: pointer;

        &:hover,
        &:active {
            color: $color-grey;
        }
    }

    @include bp1440 {
        &__wrap {
            min-height: 803px;
            justify-content: center;
        }
    }

    @include bp1680 {
        padding-top: 60px;
    }
}

.is-fail .show-success,
.is-fail .show-processing,
.is-success .show-fail,
.is-success .show-processing {
    display: none;
}
