$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-rules {
  position: relative;
  &__wrapper {
    display: flex;
    width: 384px;
    position: relative;
    height: 80px;
    justify-content: center;
    background-image: url('/joxi/fezbet/ferrari-promo/info-block-full.svg');
  }
}
