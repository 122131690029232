$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.opt-out-modal {
  &__wrap {
    padding: 40px 60px;
    background: #F6F6F4;
    text-align: center;
  }
  &__title {
    @include text(32px, 40px, 700, $color-black);
    font-family: $font-roboto;
  }
  &__text {
    @include text(14px, 20px, null, $color-black);
    letter-spacing: 0.42px;
  }
  &__close {
    position: absolute;
    inset-block-start: 12px;
    inset-inline-end: 15px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: $color-grey;
    transition: fill .15s ease-out;

    &:hover {
      fill: $color-grey-dark;
    }
  }
  &__link {
    @include text(14px, 20px, null, $color-grey);
    text-decoration-line: underline;
    transition: color .15s ease-out;

    &:hover {
      color: $color-grey-dark;
    }
  }
}
