.summer-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 63px;
  background: #a67700;
  box-shadow: 0 4.57286px 7.31658px rgba(50, 50, 50, 0.3);
  border-radius: 60px;
  position: relative;
  text-decoration: none;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  &.is-promo {
    transform: translateY(20px);
  }

  &__icon {
    position: absolute;
    right: -17px;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-size: cover;
    width: 52px;
    height: 54px;
    z-index: 1;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    padding: 0 58px;
    background: linear-gradient(149.95deg, #ffbe17 18.32%, #d89f0d 84.78%),
      linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
    box-shadow: inset 0.914572px 0.914572px 0 rgba(246, 243, 187, 0.4), inset -0.914572px -0.914572px 2.99462px #ffc635;
    border-radius: 60px;
    position: relative;
    &:active {
      &::after {
        background: linear-gradient(149.95deg, #ffc530 18.32%, #ecb320 84.78%),
          linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
        box-shadow: inset 0.914572px 0.914572px 0 rgba(246, 243, 187, 0.4), inset -1px -1px 2.99462px #ffc635;
      }
    }

    @include breakpoint($bp3){
      &:hover {
        &::after {
          background: linear-gradient(149.95deg, #ffc530 18.32%, #ecb320 84.78%),
          linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
          box-shadow: inset 0.914572px 0.914572px 0 rgba(246, 243, 187, 0.4), inset -1px -1px 2.99462px #ffc635;
        }
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      right: 6px;
      left: 4px;
      bottom: 4px;
      background: linear-gradient(149.95deg, #ffbe17 18.32%, #d89f0d 84.78%),
        linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
      box-shadow: inset 0.914572px 0.914572px 0px rgba(246, 243, 187, 0.4),
        inset -0.914572px -0.914572px 2.99462px #ffc635;
      border-radius: 60px;
      transition-property: background-image, box-shadow;
      transition-duration: 0.2s;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 12px;
      right: 17px;
      left: 17px;
      height: 9px;
      background-image: linear-gradient(180deg, rgba(240, 243, 177, 0) 0%, #cae480 100%);
      opacity: 0.3;
      border-radius: 50px;
      z-index: 2;
    }
    &.is-padding {
      padding: 0 30px;
    }
  }

  &__text {
    display: block;
    width: 100%;
    position: relative;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    z-index: 5;
    font-family: $f-ubuntu;
    color: $c-white;
    -webkit-text-stroke: 1px #a67700;
    text-shadow: 1px 2px 0 #a67700, -1px 2px 0 #a67700, 1px 2px 0 #a67700;
    white-space: nowrap;
  }
}
