$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-completion {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100px;
  justify-content: center;
  margin-top: 56px;
  &::before {
    content: '';
    position: absolute;
    height: 40px;
    width: 27px;
    background-image: url('/joxi/fezbet/ferrari-promo/union.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-12px);
    top: 0;
  }
  &__arrow {
    background-image: url('/joxi/fezbet/ferrari-promo/arrows.svg ');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 76px;
    width: 76px;
  }
  &__text {
    margin: 0 32px;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
}
