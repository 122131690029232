$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile{
  display: flex;
  margin:24px auto 0;
  width: calc(100% - 48px);
  max-width: 1544px;

  &__sidebar{
    width: 240px;
    margin-right:16px;
    flex-shrink: 0;

    &:lang(ar) {
      margin-left: 16px;
      margin-right: 0;
    }
  }

  &__content{
    position: relative;
    min-height: 400px;
    flex-grow: 1;
    width: calc(100% - 264px);
  }

  &__wrap {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    grid-column-gap: 16px;
    grid-row-gap: 24px;
    @include bp1280 {
        grid-template-columns: 1fr 360px;
        grid-row-gap: 0;
        .profile-box {
            padding: 26px 24px 40px;
        }
    }
    @include bp1440 {
        grid-column-gap: 24px;
        .profile-box {
            padding: 26px 32px 40px;
        }
    }
  }

  @include bp1440 {
    margin:32px auto 0;
    width: calc(100% - 74px);

    &__content {
      max-width: 100%;
    }

    &__sidebar{
      width: 280px;
      margin-right:32px;

      &:lang(ar) {
        margin-left: 32px;
        margin-right: 0;
      }
    }
  }
}
