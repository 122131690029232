.christmas-popup-terms {
    width: 100%;
    background: #f8e4c0;
    border-radius: 12px;
    padding: 20px 12px 20px 20px;
    color: #8d5224;
  
    &__wrapper {
      position: relative;
      max-height: 330px;
      overflow-y: auto;
      padding-right: 12px;
  
      &::-webkit-scrollbar {
        position: absolute;
        right: 12px;
        top: 0;
        width: 4px;
        margin-right: 12px;
        padding-right: 12px;
      }
  
      &::-webkit-scrollbar-track {
        width: 4px;
        margin-right: 12px;
      }
  
      &::-webkit-scrollbar-thumb {
        display: block;
        background: #94562f;
        border-radius: 8px;
        width: 4px;
      }
    }
  
    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      margin-bottom: 12px;
      color: #4d3522;
    }
  
    &__content {
      font-weight: 500;
      font-size: 12px;
      line-height: 19px;
      text-align: left;
  
      p {
        display: flex;
  
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
  
      strong {
        display: inline-block;
        font-weight: 500;
        min-width: 19px;
      }
  
      table {
        border-color: #ab6120;
        text-align: center;
      }
    }
}