$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.slider-bnr {
  position:relative;

  &__img {
    position: absolute;
    width: 554px;
    height: 512px;
    z-index: 10;
    right: 33px;
    top: 0;
    background-image: url( $cdn + '/fezbet/main/sport.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @include bp1280 {
      right: 94px;
    }

    @include bp1440 {
      width: 740px;
      height: 684px;
      right: 60px;
    }

    @include bp1680 {
      right: 100px;
    }
  }

  &__wrap {
    height: 512px;

    @include bp1440 {
      height: 684px;
    }

    @include bp1680 {
      width: 640px;
    }
  }

  &__content {
    position:relative;
    z-index: 20;
    height: 414px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 440px;
    overflow: hidden;

    @include bp1440 {
      height: 520px;
      width: 520px;
    }

    &:lang(ar) {
      align-items: flex-end;
    }

    &:lang(pt) {
      width: 450px;

      @include bp1440 {
        width: 550px;
      }
    }
  }

  &__title {
    @include text(36px, 42px, bold, null);

    font-family: var(--font-family-main);
    height: 42px;
    overflow: hidden;

    @include bp1440 {
      font-size: 44px;
      line-height: 56px;
      height: 56px;
    }
  }

  &__offer {
    @include text(64px, 72px, bold, $color-yellow);

    margin-top: 8px;
    font-family: var(--font-family-main);
    max-height: 144px;
    overflow: hidden;
    text-shadow: 0 4px 15px rgba(40, 39, 42, 0.1);

    &:lang(ar) {
      text-align: right;
    }

    &:lang(pl, ch, tr) {
      font-size: 58px;
    }

    &:lang(es-cl, hu, cs) {
      font-size: 56px;
    }

    &:lang(el, pt-br) {
      font-size: 48px;
    }

    &:lang(it) {
      font-size: 46px;
    }

    @include bp1440 {
      font-size: 80px;
      line-height: 88px;
      max-height: 176px;

      &:lang(pl, no, ch, es-pe, tr) {
        font-size: 70px;
      }

      &:lang(hu, cs, es-cl) {
        font-size: 66px;
      }

      &:lang(ar) {
        font-size: 60px;
      }

      &:lang(el, it, pt-br) {
        font-size: 56px;
      }
    }
  }

  &__text {
    margin-top:16px;
    font-size: 14px;
    line-height: 20px;
    max-height: 40px;
    overflow: hidden;

    &:lang(ar) {
      direction: rtl;
    }
  }

  &__btn {
    margin-top:28px;
    max-width: 100%;

    @include bp1440 {
      margin-top: 36px;
    }
  }
}
