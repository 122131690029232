.christmas-slider {
  position: relative;
  width: 483px;
  height: 435px;

  &__slide-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 800;
    text-align: center;
  }
  &__day {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 4px;
    color: #87f0fc;
    text-shadow: 0px 3px 3px rgba(2, 41, 40, 0.12);
    margin-top: 0;
  }

  &__title {
    font-size: 24px;
    line-height: 33px;
    color: #ffffff;
    text-shadow: 0px 3px 3px rgba(2, 41, 40, 0.25);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 8px;
    height: 68px;
  }

  &__img {
    display: block;
    width: 228px;
    height: 190px;
    object-fit: contain;
  }
  .has-margin-top-20 {
    margin-top: 20px;
  }
  .has-margin-top-10 {
    margin-top: 10px;
  }
  .has-margin-bottom-20 {
    margin-bottom: 20px;
  }

  &__expire {
    font-weight: 700;
    font-size: 14px;
    line-height: 33px;
    letter-spacing: -0.03em;
    color: rgba(255, 255, 255, 0.7);
    height: 33px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: -28px;
  }

  &__arr.slick-arrow {
    position: absolute;
    left: 0;
    top: 188px;

    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform ease-in 0.1s;
    background: rgba(4, 33, 59, 0.4);
    border-radius: 50px;
    border: none;
    transition: 0.2s;
    z-index: 1;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      width: 28px;
      height: 28px;
      background-image: url($cdn + '/holidays/christmas/arrow.svg');
      background-size: cover;
      filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(17deg) brightness(112%) contrast(101%);
      transform: rotate(180deg);
    }

    &.is-next {
      left: auto;
      right: 0;
      &:after {
        transform: rotate(0deg);
      }
    }

    &:hover {
      &::after {
        filter: invert(76%) sepia(55%) saturate(375%) hue-rotate(153deg) brightness(105%) contrast(98%);
      }
    }

    &:active {
      background: #52b0ad;
      &::after {
        filter: invert(76%) sepia(55%) saturate(375%) hue-rotate(153deg) brightness(105%) contrast(98%);
      }
    }

    &.slick-disabled {
      pointer-events: none;
      background: rgba(4, 33, 59, 0.4);
      opacity: 0.4;
    }
  }

  .slick-slider {
    max-width: 100%;
  }
  .slick-track {
    display: flex;
    align-items: flex-start;
  }

  &__slide {
    &.is-next {
      .christmas-slider__title {
        line-height: 101px;
        margin-top: 16px;
        height: 101px;
      }
      .christmas-btn {
        margin-top: 50px;
      }
      .christmas-slider__img{
        margin-top: 0;
      }
    }
    &.is-yesterday,
    &.is-previous{
      .christmas-slider__img{
        margin-bottom: 18px;
      }
    }
  }

  .slick-slide {
    visibility: hidden;
    &.slick-active {
      visibility: visible;
    }
  }
}
