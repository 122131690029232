$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sidebar {
  position: fixed;
  inset-inline-start: 0;
  top: 0;
  width: 56px;
  height: 100%;
  z-index: 200;
  background: #2d2d32;
  padding: 10px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &__burger {
    display: block;
    width: 36px;
    height: 36px;
    min-height: 36px;
    background: var(--icon-burger);
    margin: 0 auto 12px;
    filter: $filter-grey;

    &:hover {
      filter: $filter-white;
    }
  }

  &__btm {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 10px 0 10px 0;
    background-image: linear-gradient(to top, #2d2d32 calc(100% - 20px), rgba(45, 45, 50, 0) 100%);
  }

  &__ylw {
    display: block;
    margin: 12px auto 0;
    width: 44px;
    height: 44px;
    background-size: contain, contain;
    background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(45, 45, 50, 0) 100%);
    &:hover {
      background: radial-gradient(50% 50% at 50% 50%, rgba(255, 220, 38, 0.2) 55.73%, rgba(45, 45, 50, 0) 100%);
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      filter: $filter-yellow;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
    }

    &.is-chat::before {
      background-image: var(--icon-i-live-chat);
    }
    &.is-i::before {
      background-image: var(--icon-info);
    }
  }

  @include bp1440 {
    width: 72px;
    padding: 18px 0 0;

    &__burger {
      margin: 0 auto 18px;
    }

    &__btm {
      padding: 10px 0 20px 0;
    }

    &__wrap {
      padding: 18px 0;
    }
  }
}
