$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.gh {
  --gap: 16px;

  &__box {
    display: block;
    padding: 20px 20px 16px;
    border-bottom: 1px solid $color-black;
  }

  &__games {
    position: relative;
    margin-top: 60px;
    z-index: 20;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 1fr;
    gap: 24px;
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-gap: var(--gap);
  }

  &__header {
    --header-height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    height: var(--header-height);
  }

  &__title {
    @include text(24px, var(--header-height), bold, $color-white);
    font-family: var(--font-family-main);
    letter-spacing: 0.02em;
    text-transform: uppercase;

    &.has-bg {
      padding-left: 44px;
      background-size: 36px 36px;
      background-repeat: no-repeat;
      background-position: left center;
      height: 100%;

      &:lang(ar) {
        padding-left: 0;
        padding-right: 44px;
        background-position: right center;
      }
    }

    &.padding-left {
      padding-left: 52px;

      &:lang(ar) {
        padding-left: 0;
        padding-right: 52px;
      }
    }
  }

  &__category {
    --category-height: 120px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, var(--category-height));
    column-gap: var(--gap);
    row-gap: 12px;
  }

  &__category-item {
    background-color: rgba(255, 220, 38, 0.1);
    background-repeat: no-repeat;
    background-size: var(--category-height);
    background-position: left center;
    border-radius: 23px;
    padding-right: 8px;
    padding-left: var(--category-height);
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--category-height);
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: rgba(255, 220, 38, 0.3);
    }

    &:nth-child(5) {
      display: none;
    }
  }

  &__category-text {
    @include text(16px, 24px, bold, $color-white);

    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-family: var(--font-family-main);
    text-align: center;
    overflow: hidden;
    max-height: 60px;
  }

  &__providers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: var(--gap);
  }

  &__providers-item {
    position: relative;
    padding-left: 0;
    padding-right: 8px;
    height: 60px;
    background-size: 44px;
    background-position: left center;
    background-repeat: no-repeat;
    @include text-dots;
    @include text(16px, 60px, null, $color-white);
    letter-spacing: 0.02em;
    text-transform: capitalize;

    &:lang(ar) {
      padding-right: 0;
      padding-left: 0;
      white-space: unset;
    }

    &:hover {
      color: $color-yellow;
    }

    span {
      padding-left: 42px;

      &:lang(ar) {
        padding-left: 0;
        padding-right: 42px;
      }
    }

    img {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      padding: 6px;
      margin-right: 8px;
      background-color: rgba(65, 65, 65, 1);
      border-radius: 8px;

      &:lang(ar) {
        margin-right: 0;
        margin-left: 8px;
      }
    }
  }

  .game:nth-child(5),
  .game:nth-child(6),
  .game:nth-child(7){display: none;}
  @include bp1280 {
    --gap: 20px;
    &__box {padding: 20px 24px 16px;}
    &__item {
      grid-template-columns: repeat(5, 1fr);
    }
    .game:nth-child(5){display: block;}
    &__category {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: var(--category-height);
    }
    &__category-item:nth-child(4) {
      display: none;
    }
    &__providers {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  @include bp1440 {
    --gap: 24px;
    &__scroll-x {
      margin: 0;
      overflow: unset;
    }
    &__items {
      padding: 0;
    }
    &__box {
      padding: 20px 32px 24px;
    }
    &__header {--header-height: 44px;}
    &__category {
      grid-template-columns: repeat(4, 1fr);
    }
    &__category-item:nth-child(4) {
      display: flex;
    }
  }
  @include bp1680 {
    &__item {
      grid-template-columns: repeat(6, 1fr);
    }
    .game:nth-child(6){display: block;}
    &__category {
      grid-template-columns: repeat(5, 1fr);
    }
    &__category-item:nth-child(5) {
      display: flex;
    }
    &__providers {
      grid-template-columns: repeat(6, 1fr);
    }
  }
  @include bp1920 {
    &__item {grid-template-columns: repeat(7, 1fr);}
    .game:nth-child(7){display: block;}
    &__providers {
      grid-template-columns: repeat(7, 1fr);
    }
  }
}
