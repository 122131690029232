$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-events {
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  &__wrap{
    width: 100% ;
    height: 100%;
    background: #fff;
    border-radius: 20px;
  }

  &__collection-title {
    @include text(20px, 32px, bold, $color-black);
    text-transform: uppercase;
    font-family: var(--font-family-main);
    padding: 20px 24px 8px 24px;
  }

  &__content {
    width: calc(100% - 97px);
    height: 100%;
    margin-right: 12px;
  }

  &__sum {
    @include text(24px, 28px, bold, $color-yellow);
    font-family: var(--font-family-main);
    padding-top: 16px;
  }

  &__text {
    @include text(14px, 20px, normal, $color-black);
    padding-top: 8px;
    @include text-dots;
  }

  &__link {
    text-decoration: underline;
  }

  &__list {
    position: relative;
    height: calc(100% - 80px);
    width: calc(100% - 5px);
    padding-left: 24px;
    overflow-y: scroll;
    overscroll-behavior: none;
    display: flex;
    flex-direction: column;
  }

  &__item {
    height: 84px;
    background: linear-gradient(181deg,var(--grLineWhite)),linear-gradient(181deg,var(--grLineBlack));
    background-repeat: no-repeat,no-repeat;
    background-size: 100% 1px,100% 1px;
    background-position: center 83px, center 82px;
    width: calc(100% - 13px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__img {
    width: 100px;
    height: 66px;
    background: green;
    border-radius: 8px;
    background-size: contain;
  }

  &__grad {
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }

  &__stub {
    width: 100% ;
    height: 100%;
    border-radius: 12px;
    background-image: url($cdn + '/fezbet/elements/latest_win.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    text-transform: uppercase;
    letter-spacing: 0.02em;
  }

  &__info {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 24px;
    overflow: hidden;
  }

  &__title {
    @include text(18px, 28px, bold, $color-white);
    text-align: center;
    font-family: var(--font-family-main);
    @include text-dots;
    max-width: 100%;
  }

  &__description {
    @include text(24px, 32px, bold, $color-yellow);
    word-wrap: break-word;
    text-align: center;
    padding: 0 16px;
    letter-spacing: 0.02em;
    font-family: var(--font-family-main);
    max-height: 64px;
    overflow: hidden;
    margin-bottom: 8px;

  }
  @include bp1280 {
    &__info {
      padding: 24px;
    }
    &__title {
      padding-bottom: 40px;
    }
    &__description {
      margin-bottom: 60px;
    }
  }
}
