$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.notifications {
  position: fixed;
  z-index: 4000;
  top: 84px;
  right: 24px;
  width: 340px;

  &__item {
    position: relative;
    margin-bottom:12px;
    opacity: 1;
    transition: 0.3s;
    max-height: auto;
    background-color: $color-gray-light;
    min-height: 72px;
    padding:  12px 24px 12px 20px;
    box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
    border-radius: 20px;
    display: flex;
    text-align: left;
    align-items: center;

    &:lang(ar) {
      text-align: right;
    }

    &.ng-move,
    &.ng-enter {
      transition: 0.5s;
    }

    &.ng-leave {
      transition: 0.001s !important;
    }

    &.ng-leave.ng-leave-active,
    &.ng-move,
    &.ng-enter {
      max-height: 0px;
      transform: translateX(100%) scale(0.6);
    }

    &.ng-leave,
    &.ng-move.ng-move-active,
    &.ng-enter.ng-enter-active {
      transform: translateX(0%) scale(1);
      max-height: 100px;
    }
  }
  &__close {
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: 0.1s;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;
    filter: $filter-grey-light;
    margin-left: 8px;
  }
  &__text {
    flex-grow: 1;
    @include text(12px, 16px, null, $color-black);
  }
  &__code {
    font-weight: bold;
    text-decoration: underline;
  }
  &__icon {
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    border-radius: 50%;
    margin-inline-end: 12px;
    flex-shrink: 0;
    background-size: 22px;
    background-position: top 6px center;
  }

}


@keyframes moveNot {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 400px;
  }
}

@keyframes showNot {
  0% {
    opacity: 0;
  }
}

