$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-tooltip {
  display: none;
  @include text(12px, 20px);
  padding: 8px;
  background-color: $color-white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(25, 25, 29, 0.2);
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    background-color: $color-white;
    top: -4px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    display: block;
    position: absolute;
  }

  & > * {
    padding-inline-start: 20px;
  }
}

.has-tooltip.ng-invalid:focus + .form-label .form-tooltip {
  display:block;
}

.has-tooltip + .form-label{
  & .g-tooltip-min,
  & .g-tooltip-number,
  & .g-tooltip-low,
  & .g-tooltip-up{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYgNi42bDIuNyAyLjcuNi0uNkw2LjYgNmwyLjctMi43LS42LS42TDYgNS40IDMuMyAyLjdsLS42LjZMNS40IDYgMi43IDguN2wuNi42TDYgNi42eiIgZmlsbD0iI0JDMUYzOSIvPjwvc3ZnPg==');
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: left center;

    &:lang(ar) {
      background-position: right center;
    }
  }
}

.has-tooltip.ng-valid-min     + .form-label .g-tooltip-min,
.has-tooltip.ng-valid-number  + .form-label .g-tooltip-number,
.has-tooltip.ng-valid-low     + .form-label .g-tooltip-low,
.has-tooltip.ng-valid-up      + .form-label .g-tooltip-up{
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC44IDMuNkw0LjYgMTAgMS4yIDYuNWwxLTEgMi40IDIuNCA1LjItNS4zIDEgMXoiIGZpbGw9IiMyNkMwMDAiLz48L3N2Zz4=');

}
