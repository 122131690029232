.is-summer-html {
  @media (max-height: 600px) and (max-width: 1280px) and (orientation: landscape) {
    overflow: initial !important;
  }
}
.is-summer {
  overflow: hidden;
  height: 100dvh;
  min-height: 100dvh;
  @media (max-height: 600px) and (max-width: 1280px) and (orientation: landscape) {
    overflow: initial !important;
  }
}
.summer-portrait {
  display: block;
  @media (max-height: 600px) and (max-width: 1280px) and (orientation: landscape) {
    display: none;
  }
}
.summer-landscape {
  display: none;
  width: 100%;
  position: relative;
  z-index: 999999;
  height: 100vh;
  background-color: #40a65e;
  background-image: url($cdn + '/summer-promo/landscape-bg.png');
  background-repeat: no-repeat;
  background-position: center 20px;
  background-size: contain;

  @media (max-height: 600px) and (max-width: 1280px) and (orientation: landscape) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 90px;
    height: 89px;
    margin-bottom: 7px;
    background-image: url($cdn + '/summer-promo/landscape-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__text {
    font-family: $f-jost;
    @include text(18px, 26px, 700, $c-white);
  }
}

.summer-map {
  background-image: url($cdn + '/summer-promo/summer-bg-mobile.png');
  background-repeat: repeat;
  background-position: center 300px;
  background-size: $widthMobile 963px;
  margin: 0 auto;
  width: $widthMobile;
  padding: 60px 0;

  &.is-odd {
    background-position: center -100px;
  }
  &.is-loaded {
    transition: transform 1s ease-in-out;
  }

  @include breakpoint($bp1) {
    width: $widthBigMobile;
    background-size: contain;
    background-position: center 600px;
  }

  @include breakpoint($bp2) {
    background-image: url($cdn + '/summer-promo/summer-bg-tablet.png');
    background-size: $widthTablet 1513px;
    width: $widthTablet;
    background-position: center 450px;
  }

  @include breakpoint($bp3) {
    background-image: url($cdn + '/summer-promo/summer-bg-desktop.png');
    background-size: $widthDesktop 1513px;
    background-position: center 500px;
    width: $widthDesktop;
  }
  @include breakpoint($bp5) {
    width: 4233px;
    background-position: center 700px;
  }
}
