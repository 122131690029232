$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

.car-banner {
  margin-top: 40px;
  font-family: 'Roboto Condensed', sans-serif;
  min-height: 336px;
  padding: 42px;
  background-color: #121115;
  border: 1px solid rgba(255,255,255,.1);
  border-radius: 20px;
  position: relative;
  overflow: hidden;

  &__image {
    position: absolute;
    width: 80%;
    height: 100%;
    background-position: center;
    bottom: 0;
    right: -170px;
    background-size: cover;

    &:lang(ar) {
      right: unset;
      left: -150px;
    }

    @include bp1280 {
      width: 100%;
      height: 100%;
      right: -250px;

      &:lang(ar) {
        right: unset;
        left: -250px;
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }

  &__label {
    min-width: 111px;
    background-color: #FFDC26;
    border-radius: 8px;
    min-height: 20px;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    align-items: center;
    padding: 0 12px;
    @include text(14px, 20px, 700, #19191D);
    margin-bottom: 24px;
    position: relative;
    z-index: 2;
  }

  &__title {
    @include text(72px, 72px, 700, $color-white);
    max-width: 565px;
    margin-bottom: 32px;
    position: relative;
    z-index: 2;

    @include bp540 {
      font-size: 55px;
    }
    @include bp768 {
      font-size: 60px;
    }
    @include bp1024 {
      font-size: 62px;
      max-width: 630px;
    }
    @include bp1280 {
      font-size: 72px;
      max-width: 720px;
    }

    &.is-yellow {
      color: #FFDC26;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    @include text(42px, 42px, 700, $color-white);
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    @include text(24px, 36px, 700, $color-white);
    letter-spacing: .03px;
    background: linear-gradient(180deg, #FFC440 0%, #DD251A 82%);
    box-shadow: inset 0 6px 8px rgba(255, 246, 215, 0.6), inset 0 -6px 10px rgba(40, 13, 2, 0.5);
    padding: 0 58px;
    transition: 0.3s;
    min-height: 60px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    border-radius: 50px;
    text-transform: uppercase;

    &:hover {
      background: linear-gradient(180deg, #FFC440 0%, #DD251A 82%);
      box-shadow: 0 2px 8px rgba(25, 25, 29, 0.25), inset 0 6px 8px rgba(255, 246, 215, 0.6), inset 0 -6px 10px rgba(40, 13, 2, 0.5);
      transform: translateY(-2px);
      transition: 0.3s;
    }

    &:active {
      background: linear-gradient(180deg, #E64E24 0%, #F9A93A 100%);
      box-shadow: none;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
