$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.slider-wrap{
  position:relative;
  z-index: 20;

  &:lang(ar) {
    direction: ltr;
  }

  &__nav{
    margin-top: 72px;
    width: 160px;
    margin-right:20px;
    flex-shrink: 0;
  }

  &__top{
    position:relative;
    display: flex;
    z-index: 10;
    height: 414px;
  }


  &__bnr{
    flex-grow: 1;
    flex-shrink: 0;
    height: 414px;
    min-height: 414px;

    width: calc(100% - 180px);
  }

  &__bottom{
    position:relative;
    z-index: 30 ;
  }
  &__pnp-block {
    width: 640px;
    z-index: 20;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
  }

  &__arr{

    position:absolute;
    left: -180px;
    width: 30px;
    height: 30px;
    display: block;
    background-color: rgba(0,0,0,0);
    border:none;
    background-size: 100% auto , 100% 1px  , 0px;

    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-size: 16px;
      background-repeat: no-repeat;
      background-position: center center;
      filter: $filter-white;
    }
    &:hover{
      background-size: 100% auto , 100% 1px  , cover;
      &::before{ filter: $filter-yellow;}
    }
    &.is-prev{
      border-radius: 16px 16px 0 0;
      top: 281px;
      background-image: var(--icon-slider-arr-w) , linear-gradient( to right , var(--grLineBlack)) , linear-gradient(to bottom, rgba(255, 220, 38, 0.2) 0%, rgba(255, 220, 38, 0) 100%);
      background-repeat: no-repeat;
      background-position: center top , center bottom;
      &::before{ background-image: var(--icon-arr-t); }
    }
    &.is-next{
      border-radius: 0 0 16px 16px ;
      top: 311px;
      background-image: var(--icon-slider-arr-w) , linear-gradient( to right , var(--grLineWhite)) , linear-gradient(to top, rgba(255, 220, 38, 0.2) 0%, rgba(255, 220, 38, 0) 100%);

      background-repeat: no-repeat , no-repeat , repeat;
      background-position: center bottom , center top , center center;
      &::before{ background-image: var(--icon-arr-b); }
    }
  }

  &__video{
    position:absolute;
    z-index: 5;
    width: 730px;
    height: 538px;
    top: 0;
    right: -126px;

  }

  @include bp1280 {
    &__video{  right: -62px; }
  }

  @include bp1440 {
    &__top{ height: 520px; }
    &__nav{
      margin-right:32px;
      margin-top: 88px;
      height: 224px;
    }
    &__bnr{ width: calc(100% - 193px); height: 520px; }
    &__arr{
      left: -191px ;
      &.is-prev{ top:360px;}
      &.is-next{ top:390px;}
    }

    &__video{
      width: 978px;
      height: 720px;
      right: -155px;
    }
    &__pnp-block {
      margin-top: 75px;
    }
  }
  @include bp1680{
    &__nav{ margin-right:72px; }
    &__bnr{ width: calc(100% - 232px); }
    &__arr{ left: -231px ; }
    &__pnp-block {
      width: 640px;
    }

  }
  @include bp1920 {
    &__nav{ margin-right:76px; }
    &__bnr{ width: calc(100% - 237px); }
    &__arr{ left: -235px ; }
  }

}
