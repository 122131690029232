$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
body.linda-has-user .show-guest{ display:none;}
body:not(.linda-has-user) .show-user{ display:none;}

.has-header {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.has-bg-pattern{
  background-image: url( $cdn + '/fezbet/elements/pattern.png');
  background-size: 1000px auto;
}

.has-mb-20{
  margin-bottom: 20px;
}
.has-mt-16{
  margin-top: 16px;
}
.has-margins {
  margin: 0 24px;

  @include bp1440 {
    margin: 0 36px;
  }
}
.has-mt-40{
  margin-top: 40px !important;
}
.rtl-lang {
  .direction-ltr {
    display: inline-flex;
    direction: ltr;
  }
}

._no-wrap {
  white-space: nowrap;
}
