$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-bonuses {
  display: flex;
  flex-direction: column;

  &__item {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 16px;
    order: 99;

    &.status-active {
      order: 1;
    }

    &.status-locked {
      order: 2;
    }

    &.status-unlocked {
      order: 3;
    }
  }
  @include bp1440 {
    &__item{ margin:0 auto 24px ; }
  }
  @include bp1680{
    display: grid;
    grid-gap: 24px;
    grid-template-columns:  repeat(2, 1fr);

    &__item{
      width: 100%;
      margin:0;
    }
  }
}
