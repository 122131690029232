$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-preview {
  display: inline-flex;
  flex-direction: column;
  padding: 40px 28px 40px 28px;
  width: 100%;
  max-width: 454px;
  margin-bottom: 20px;

  background-color: $color-white;
  background-size: 32px;
  background-position: top 12px right 12px;
  background-repeat: no-repeat;
  border-radius: 20px;

  &_sport {
    background-image: var(--icon-promo-preview-sport);

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: inline-block;
      }
    }

    &.was-logged {
      display: none;
    }
  }
  &_casino {
    background-image: var(--icon-promo-preview-casino);
  }
  &_live-casino {
    background-image: var(--icon-promo-preview-live-casino);
  }

  &__title {
    display: block;
    font-family: var(--font-family-main);
    @include text(28px, 30px, 700, $color-grey-dark);
    text-transform: uppercase;
  }

  &__subtitle {
    @include text(40px, 48px, 700, $color-yellow-dark);

    display: block;
    font-family: var(--font-family-main);
    margin-bottom: 14px;

    &:lang(ar) {
      word-break: break-word;
    }

    &:lang(de),
    &:lang(at),
    &:lang(fi),
    &:lang(el),
    &:lang(fr-CA),
    &:lang(es-CL),
    &:lang(es) {
      font-size: 50px;

      @include bp1280 {
        font-size: 42px;
      }

      @include bp1440 {
        font-size: 48px;
      }

      @include bp1680 {
        font-size: 56px;
      }
    }

    &:lang(hu),
    &:lang(pt-BR),
    &:lang(es-PE) {
      @include bp1280 {
        font-size: 50px;
      }

      @include bp1440 {
        font-size: 58px;
      }
    }
  }

  .small-font {
    font-size: 58px;
    line-height: 72px;
  }

  .x-small-font {
    font-size: 44px;
    line-height: 44px;
  }

  .title-font {
    font-size: 32px;
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: .03em;
    color: $color-black;
    cursor: pointer;
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;

    margin-top: 24px;
  }

  &__btn {
    margin-right: 20px;

    &:lang(ar) {
      margin-left: 20px;
      margin-right: 0;
    }

    @include bp1280 {
      padding: 0 20px;
      min-width: auto;
    }

    @include bp1440 {
      padding: 0 28px;
    }

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: inline-block;
      }
    }

    &.was-logged {
      display: none;
    }
  }

  &__link {
    margin: 10px 0;
		border: none;
		background-color: unset;
  }

  @include bp1440 {
    margin-bottom: 24px;
  }

  @include bp1680 {
    padding: 48px 32px 60px 32px;

    &__title {
      font-size: 28px;
      line-height: 28px;
    }

    &__subtitle {
      font-size: 56px;
      line-height: 56px;
      margin-top: 8px;
    }

    &__text {
      margin-top: 16px;
    }

    &__btns {
      margin-top: 28px;
    }
  }
}
