@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap');

.easter-wrapper {
  background: url($cdn + '/easter/2023/elements/stars-bg.svg'), linear-gradient(180deg, #2A3D75 0%, #11194A 52.08%, #351D57 100%);
  font-family: $jost;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

body {
  &.is-anim {
    .easter-slider__item.is-today {
      .easter-box {
        animation: scaleInOut 1.5s linear forwards;
      }

      .easter-box__not-selected {
        animation-name: hideBlock;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        pointer-events: none;
      }

      .easter-box__selected,
      .easter-box__requirements {
        animation-name: showBlock;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        pointer-events: all;
      }
    }
  }

  &:not(.is-anim) {
    .easter-slider__item.is-selected-bonus {
      .easter-box__not-selected {
        display: none;
      }

      .easter-box__selected,
      .easter-box__requirements {
        transform: scale(1);
        opacity: 1;
        pointer-events: all;
        position: relative;
      }
    }
  }
}








