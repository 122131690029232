$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cash-crab-timer{

  margin-top:74px;
  text-align: center;
  &__title{
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #19191D;
  }
}