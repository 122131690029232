@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@800&display=swap');

christmas-promo {
  display: flex;
  background: #0f232d;
  position: relative;
  font-family: 'Jost';
  &::before {
    content: '';
    position: absolute;
    left: 26.56%;
    right: 28.01%;
    top: 13.44%;
    bottom: 13.83%;
    background: rgba(16, 129, 127, 0.8);
    opacity: 0.3;
    filter: blur(100px);
  }
  & * {
    font-family: 'Jost';
  }
}

.christmas-noise {
  background-image: url($cdn + '/holidays/christmas/noise.png');
  background-size: 200px;
  background-position: top;
  @media (-webkit-max-device-pixel-ratio: 2) {
    background-image: url($cdn + '/holidays/christmas/noise@2x.png');
  }
  @media (-webkit-max-device-pixel-ratio: 3) {
    background-image: url($cdn + '/holidays/christmas/noise@3x.png');
  }
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.christmas-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('#{$cdn}/holidays/christmas/bg-1024-left.png') left bottom no-repeat,
    url('#{$cdn}/holidays/christmas/bg-1024-right.png') right bottom no-repeat;
  background-size: 269px, 389px;

  @media (-webkit-max-device-pixel-ratio: 2) {
    background: url('#{$cdn}/holidays/christmas/bg-1024-left@2x.png') left bottom no-repeat,
      url('#{$cdn}/holidays/christmas/bg-1024-right@2x.png') right bottom no-repeat;
    background-size: 269px, 389px;
  }
  @media (-webkit-max-device-pixel-ratio: 3) {
    background: url('#{$cdn}/holidays/christmas/bg-1024-left@3x.png') left bottom no-repeat,
      url('#{$cdn}/holidays/christmas/bg-1024-right@3x.png') right bottom no-repeat;
    background-size: 269px, 389px;
  }

  @include breakpoint($bp1) {
    background: url('#{$cdn}/holidays/christmas/bg-1024-left.png') left bottom no-repeat,
      url('#{$cdn}/holidays/christmas/bg-1024-right.png') right bottom no-repeat,
      url('#{$cdn}/holidays/christmas/air-gift-left.png') calc(50% - 500px) 19px no-repeat,
      url('#{$cdn}/holidays/christmas/air-gift-right.png') calc(50% + 426px) 155px no-repeat;
    background-size: 269px, 389px, 200px, 164px;

    @media (-webkit-max-device-pixel-ratio: 2) {
      background: url('#{$cdn}/holidays/christmas/bg-1024-left@2x.png') left bottom no-repeat,
        url('#{$cdn}/holidays/christmas/bg-1024-right@2x.png') right bottom no-repeat,
        url('#{$cdn}/holidays/christmas/air-gift-left@2x.png') calc(50% - 500px) 19px no-repeat,
        url('#{$cdn}/holidays/christmas/air-gift-right@2x.png') calc(50% + 426px) 155px no-repeat;
      background-size: 269px, 389px, 200px, 164px;
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background: url('#{$cdn}/holidays/christmas/bg-1024-left@3x.png') left bottom no-repeat,
        url('#{$cdn}/holidays/christmas/bg-1024-right@3x.png') right bottom no-repeat,
        url('#{$cdn}/holidays/christmas/air-gift-left@3x.png') calc(50% - 500px) 19px no-repeat,
        url('#{$cdn}/holidays/christmas/air-gift-right@3x.png') calc(50% + 426px) 155px no-repeat;
      background-size: 269px, 389px, 200px, 164px;
    }
  }
  @include breakpoint($bp2) {
    background: url('#{$cdn}/holidays/christmas/bg-1920-left.png') left bottom -55px no-repeat,
      url('#{$cdn}/holidays/christmas/bg-1920-right.png') right bottom -55px no-repeat,
      url('#{$cdn}/holidays/christmas/air-gift-left.png') calc(50% - 500px) 19px no-repeat,
      url('#{$cdn}/holidays/christmas/air-gift-right.png') calc(50% + 426px) 155px no-repeat;
    background-size: 565px, 582px, 200px, 164px;

    @media (-webkit-max-device-pixel-ratio: 2) {
      background: url('#{$cdn}/holidays/christmas/bg-1920-left@2x.png') left bottom -55px no-repeat,
        url('#{$cdn}/holidays/christmas/bg-1920-right@2x.png') right bottom -55px no-repeat,
        url('#{$cdn}/holidays/christmas/air-gift-left@2x.png') calc(50% - 500px) 19px no-repeat,
        url('#{$cdn}/holidays/christmas/air-gift-right@2x.png') calc(50% + 426px) 155px no-repeat;
      background-size: 565px, 582px, 200px, 164px;
    }
    @media (-webkit-max-device-pixel-ratio: 3) {
      background: url('#{$cdn}/holidays/christmas/bg-1920-left@3x.png') left bottom -55px no-repeat,
        url('#{$cdn}/holidays/christmas/bg-1920-right@3x.png') right bottom -55px no-repeat,
        url('#{$cdn}/holidays/christmas/air-gift-left@3x.png') calc(50% - 500px) 19px no-repeat,
        url('#{$cdn}/holidays/christmas/air-gift-right@3x.png') calc(50% + 426px) 155px no-repeat;
      background-size: 565px, 582px, 200px, 164px;
    }
  }
  &.is-over {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #0f232d;
      opacity: 0.9;
    }
  }
}

.christmas-border {
  background: linear-gradient(202.6deg, #f5e2c2 6.67%, #997e58 32.47%, #856243 89.66%);
  box-shadow: inset 0px 4px 3px rgba(255, 255, 255, 0.4), inset 0px -3px 3px rgba(255, 255, 255, 0.26);
  & > div {
    background: linear-gradient(180deg, #357371 0%, #166b69 48.44%, #025855 100%);
    box-shadow: inset 6px 6px 6px rgba(0, 0, 0, 0.08), inset -6px 6px 6px rgba(0, 0, 0, 0.08),
      inset -6px -6px 6px rgba(0, 0, 0, 0.08);
  }
}
