.summer-modal {
  padding: 0;
}
.summer-box__image .spine-player {
  width: 100%;
}
.summer-story-tell {
  background-image: url($cdn + '/summer-promo/comming-bg.png');
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  background-color: #40a65e;
  display: flex;
  background-position: bottom;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 9999;
  left: 0;
  top:0;
  justify-content: center;
  color: $c-white;
  padding: 11px 15px;
  font-family: $f-jost;
  &__item {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &__radio {
    display: flex;
  }
  &__radio--circle {
    background-color: #ffffff;
    opacity: 0.3;
    height: 9px;
    margin-right: 11px;
    width: 9px;
    border-radius: 50%;
    &:last-child {
      margin-right: 0;
    }
    &.is-active {
      opacity: 1;
    }
  }
}

.summer-box {
  &__image {
    height: 350px;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include breakpoint($bp3) {
      height: 445px;
      max-width: 508px;
      width: 100%;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 138px;
    @include breakpoint($bp3) {
      min-height: 100%;
    }
  }
  &__image--island {
    height: 320px;
    width: 351px;
    object-fit: contain;
  }
  &__image--calendar {
    height: 240px;
    width: 320px;
    object-fit: contain;
  }
  &__image--chip {
    height: 350px;
    width: 350px;
    object-fit: contain;
    @include breakpoint($bp3) {
      height: 445px;
      width: 465px;
    }
  }
  &__btn {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 64px;
    background: #a67700;
    box-shadow: 0 4.57286px 7.31658px rgba(50, 50, 50, 0.3);
    border-radius: 50%;
    position: relative;
    text-decoration: none;
    transition: all;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    &::after {
      content: '';
      display: block;
      background-image: url($cdn + '/summer-promo/arrow-btn.svg ');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 21px;
      position: absolute;
      cursor: pointer;
      left: 20px;
      top: 17px;
      height: 29px;
    }
    &__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      padding: 30px;
      background: linear-gradient(149.95deg, #ffbe17 18.32%, #d89f0d 84.78%),
        linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
      box-shadow: inset 0.914572px 0.914572px 0 rgba(246, 243, 187, 0.4),
        inset -0.914572px -0.914572px 2.99462px #ffc635;
      border-radius: 50%;
      position: relative;
      &:active {
        &::after {
          background: linear-gradient(149.95deg, #ffc530 18.32%, #ecb320 84.78%),
            linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
          box-shadow: inset 0.914572px 0.914572px 0px rgba(246, 243, 187, 0.4), inset -1px -1px 2.99462px #ffc635;
        }
      }
      @include breakpoint($bp3) {
        &:hover {
          &::after {
            background: linear-gradient(149.95deg, #ffc530 18.32%, #ecb320 84.78%),
              linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
            box-shadow: inset 0.914572px 0.914572px 0px rgba(246, 243, 187, 0.4), inset -1px -1px 2.99462px #ffc635;
            background-size: 100px;
          }
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 4px;
        right: 6px;
        left: 4px;
        bottom: 4px;
        background: linear-gradient(149.95deg, #ffbe17 18.32%, #d89f0d 84.78%),
          linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
        box-shadow: inset 0.914572px 0.914572px 0px rgba(246, 243, 187, 0.4),
          inset -0.914572px -0.914572px 2.99462px #ffc635;
        border-radius: 50%;
        transition-property: background-image, box-shadow;
        transition-duration: 0.2s;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 18px;
        right: 38px;
        height: 6px;
        width: 11px;
        background-color: #ffffff;
        opacity: 0.5;
        transform: rotate(-46.22deg);
        border-radius: 100%;
        z-index: 2;
      }
    }
  }
}
.info-summer {
  &__title {
    font-weight: 800;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
  }
}
.summer-item {
  margin-bottom: 16px;
  &__title {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    line-height: 29px;
  }
}
