$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile__wins {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// BIGWIN
.profile__bigwins {
  position: relative;
  margin-bottom: 16px;

  &-wrap {
    width: 100%;
    background-color: $color-white;
    border-radius: 20px;
    letter-spacing: 0.02em;
  }

  &-content {
    width: calc(100% - 158px);
    height: 100%;
    margin-left: auto;
    padding: 20px 20px 28px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  &-title {
    @include text(18px, 32px, bold, $color-black);

    font-family: var(--font-family-main);
    width: 100%;
    overflow: hidden;
    padding-bottom: 16px;
    text-transform: uppercase;
  }

  &-sum {
    @include text(26px, 28px, bold, $color-yellow);
    @include text-dots;

    width: 100%;
    padding-bottom: 16px;
    text-transform: uppercase;
  }

  &-text {
    @include text(14px, 20px, normal, $color-black);

    margin-bottom: 16px;
    max-height: 40px;
    overflow: hidden;
  }

  &-image {
    width: 134px;
    height: 200px;
    position: absolute;
    bottom: 16px;
    left: 24px;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  }

  &-stub {
    width: 100%;
    height: 100%;
    background-image: url($cdn + '/fezbet/elements/latest_win.jpg');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    border-radius: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
    font-family: var(--font-family-main);
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-title {
      @include text(18px, 28px, bold, $color-white);
      @include text-dots;

      padding: 0 0 16px 0;
      margin: 0 auto;
      overflow: hidden;
    }

    &-description {
      @include text(24px, 32px, bold, $color-yellow);

      height: 64px;
      margin: 0 auto 22px;
      padding: 0 16px;
      word-wrap: break-word;
      overflow: hidden;
    }
  }

  @include bp1440 {
    &-sum {
      @include text(28px, 32px, bold, $color-yellow);

      padding-bottom: 8px;
    }
  }
}

// LASTWINS
.profile__lastwins {
  flex-grow: 1;

  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 20px 24px;
    border-radius: 20px;
  }

  &-collection-title {
    @include text(20px, 32px, bold, $color-black);

    text-transform: uppercase;
    font-family: var(--font-family-main);
    margin-bottom: 30px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-right: 12px;
  }

  &-sum {
    @include text(24px, 28px, bold, $color-yellow);

    font-family: var(--font-family-main);
    margin-bottom: 8px;
  }

  &-text {
    @include text(14px, 20px, normal, $color-black);
    @include text-dots;
  }

  &-link {
    color: $color-black;
    text-decoration: underline;
  }

  &-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overscroll-behavior: none;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-image: linear-gradient(181deg,var(--grLineWhite)), linear-gradient(181deg, var(--grLineBlack));
    background-repeat: no-repeat,no-repeat;
    background-size: 100% 1px,100% 1px;
    background-position: center 79px, center 79px;
    //width: calc(100% - 13px);
  }

  &-img {
    width: 100px;
    height: 66px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 8px;
  }

  &-grad {
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 0 0 20px 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }

  &-stub {
    width: 100% ;
    height: 100%;
    border-radius: 12px;
    background-image: url($cdn + '/fezbet/elements/latest_win.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    text-transform: uppercase;
    letter-spacing: 0.02em;
  }

  &-info {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 24px;
    overflow: hidden;
  }

  &-title {
    @include text(18px, 28px, bold, $color-white);
    @include text-dots;

    text-align: center;
    font-family: var(--font-family-main);
    max-width: 100%;
  }

  &-description {
    @include text(24px, 32px, bold, $color-yellow);

    word-wrap: break-word;
    text-align: center;
    padding: 0 16px;
    letter-spacing: 0.02em;
    font-family: var(--font-family-main);
    max-height: 64px;
    overflow: hidden;
    margin-bottom: 8px;
  }

  @include bp1280 {
    &-info {
      padding: 24px;
    }

    &-title {
      padding-bottom: 40px;
    }

    &-description {
      margin-bottom: 60px;
    }
  }
}
