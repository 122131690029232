$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-favourite {
    background-image: var(--icon-favourite-add);
    background-color: rgba(255, 220, 38, 0.1);
    background-repeat: no-repeat;
    background-position: 8px center;
    border-radius: 35px;
    border: none;

    padding: 0 16px 0 36px;

    @include text(14px, 32px, normal, $color-white);
    font-family: var(--font-family-main);
    text-transform: uppercase;
    &:hover {
        color: $color-yellow;
    }
    &.active {
        background-image: var(--icon-favourite-remove);
    }
}
