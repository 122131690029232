@mixin breakpoint($width) {
  @media ( max-width: #{$width} ) {
    @content;
  }
}
@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
  @media (hover: none) {
    &.ng-tap--start:not(.ng-tap--move) {
      @content;
    }
  }
}
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}