$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-item {
  min-width: 171px;
  padding: 20px 0;
  background-color: $color-white;
  border-radius: 20px;
  color: $color-black;
  height: fit-content;
  position: relative;

  &.active,
  body:not(.linda-has-user) &:first-of-type {
    background-color: $color-yellow;
  }

  &__top {
    padding-bottom: 4px;
    position: relative;
  }

  &__title {
    @include text(24px, 28px, bold);
    font-family: var(--font-family-main);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding: 0 16px;
    overflow: hidden;

    &:lang(el) {
      font-size: 14px;

      @include bp1280 {
        font-size: 16px;
      }

      @include bp1680 {
        font-size: 20px;
      }
    }
  }

  &__level {
    @include text(14px, 24px, null, $color-grey);
    letter-spacing: 0.03em;
    padding: 0 16px;
    .vip-item.active &,
    body:not(.linda-has-user) .vip-item:first-of-type & {
      color: $color-black;
    }
  }

  &__number {
    @include text(160px, 120px, bold, #F6F6F4);
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-family: var(--font-family-main);
    text-align: center;

    .vip-item.active &,
    body:not(.linda-has-user) .vip-item:first-of-type & {
      color: rgba(246, 246, 244, 0.5);
    }
  }
  &__img,
  &__shadow {
    position: absolute;
    bottom: -10px;
    right: 0;
    transform: translate(0, 0);
    background-size: 100%, 0 0;
    background-repeat: no-repeat;
    background-position: bottom center;
    width: 172px;
    height: 172px;

    &:lang(ar) {
      right: 30px;
      bottom: -18px;
      transform: scale(-0.9, 0.9);

      @include bp1280 {
        right: unset;
      }

      @include bp1440 {
        right: 24px;
      }

      .vip-item:hover & {
        transform: scale(-0.9, 0.9) translate(0, -20px);
      }
    }
  }

  &__img {
    z-index: 1;
    transition: 0.2s;

    .vip-item:hover & {
      transform: translate(0, -20px);
      transition: 0.4s;
    }

    .vip-item:first-of-type & {
      background-image: url($cdn + "/fezbet/vip/level1.png");
    }

    .vip-item:nth-of-type(2) & {
      background-image: url($cdn + "/fezbet/vip/level2.png");
    }

    .vip-item:nth-of-type(3) & {
      background-image: url($cdn + "/fezbet/vip/level3.png");
    }

    .vip-item:nth-of-type(4) & {
      background-image: url($cdn + "/fezbet/vip/level4.png");
    }

    .vip-item:nth-of-type(5) & {
      background-image: url($cdn + "/fezbet/vip/level5.png");
    }
  }

  &__shadow {
    opacity: 0;
    transition: 0.2s;

    .vip-item:hover & {
      transform: translate(0, -20px);
      opacity: 1;
      transition: transform 0.4s, opacity 0.5s;
    }

    .vip-item:first-of-type & {
      background-image: url($cdn + "/fezbet/vip/level1_shadow.png");
    }

    .vip-item:nth-of-type(2) & {
      background-image: url($cdn + "/fezbet/vip/level2_shadow.png");
    }

    .vip-item:nth-of-type(3) & {
      background-image: url($cdn + "/fezbet/vip/level3_shadow.png");
    }

    .vip-item:nth-of-type(4) & {
      background-image: url($cdn + "/fezbet/vip/level4_shadow.png");
    }

    .vip-item:nth-of-type(5) & {
      background-image: url($cdn + "/fezbet/vip/level5_shadow.png");
    }
  }
  &__bottom {
    background: linear-gradient(180deg, rgba(85, 85, 85, 0.15) 0%, rgba(85, 85, 85, 0) 37%);
    padding: 0 16px;
  }

  &__val {
    @include text(20px, 120%, bold);
    font-family: var(--font-family-main);
    padding-top: 16px;
    overflow: hidden;
  }

  &__key {
    @include text(12px, 16px);
    margin-top: 4px;
    overflow: hidden;
  }

  &__link {
    @include text(20px, 120%, bold);
    font-family: var(--font-family-main);
    text-transform: uppercase;
    margin-top: 12px;
    overflow: hidden;
    display: block;

    &:lang(el) {
      @include maxbp1280 {
        font-size: 18px;
      }
    }

    &_promo {
      display: none;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51%, rgba(0, 0, 0, 0) 100%), var(--icon-i-live-chat);
      background-repeat: no-repeat;
      background-position: right 30px center, right center;
      background-size: 1px 100%, 24px 24px;
      padding-right: 37px;

      &:hover {
        filter: $filter-grey;
      }

      .vip-item:last-of-type & {
        display: block;
      }
    }
  }

  &__btn {
    position: absolute;
    bottom: -84px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    max-width: 100%;

    .vip-item:first-of-type & {
      display: block ;
      transform: translate(-50%, 0px);

      &:hover {
        transform: translate(-50%, -2px);
      }
    }
    body.linda-has-user &.show-guest { //увеличила вес селектора
      display: none;
    }
  }

  @include bp1280 {
    padding: 24px 0;

    &__title {
      @include text(28px, 35px);
      margin-bottom: 4px;
      padding: 0 20px;
    }
    &__level {
      @include text(18px, 24px);
      padding: 0 20px;
    }
    &__img,
    &__shadow {
      width: 240px;
      height: 240px;

      .vip-item:hover & {
        transform: translate(0, -20px);
      }
    }
    &__number {
      @include text(200px, 200px);
    }
    &__bottom {
      padding: 0 20px;
    }
    &__val {
      @include text(24px, 28px);
    }
    &__key {
      @include text(14px, 20px);
    }
    &__link {
      margin-top: 18px;
    }
  }

  @include bp1440 {
    &__link {
      @include text(24px, 28px);
    }
    &__link_promo {
      background-position: right 44px center, right center;
      background-size: 1px 100%, 32px 32px;
      padding-right: 57px;
    }
  }

  @include bp1680 {
    padding-top: 28px;
    padding-bottom: 24px;

    &__bottom,
    &__title,
    &__level {
      padding: 0 28px;
    }

    &__number {
      font-size: 260px;
    }
  }

}
