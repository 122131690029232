$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-stage-final {
  border-radius: 20px;
  height: 340px;
  width: 100%;
  padding: 32px 25px 34px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  isolation: isolate;

  @include bp1280 {
    padding: 40px 32px 44px;
  }

  @include bp1440 {
    padding: 40px 64px 44px;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background-image: url('/joxi/fezbet/ferrari-promo/banner-1280.jpg');
    background-position: 90%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: inherit;

    @include bp1280 {
      background-image: url('/joxi/fezbet/ferrari-promo/banner-1024.jpg');
    }

    @include bp1440 {
      background-image: url('/joxi/fezbet/ferrari-promo/banner.png');
    }
  }

  &:lang(ar)::before {
    scale: -1 1;
  }

  &__active {
    border-radius: 8px;
    background: #ffdc26;
    display: flex;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    color: #19191d;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    position: absolute;
    top: -10px;
    inset-inline-start: 30px;
  }

  &__leaderboard {
    width: 240px;

    @include bp1280 {
      width: 320px;
    }

    @include bp1440 {
      width: 424px;
    }

    .is-3-column .table__head {
      grid-template-columns: 32px 1fr 1fr;
    }

    .is-3-column .table__item {
      grid-template-columns: 32px 1fr 1fr;
    }
  }

  &__title {
    color: #ffdc26;
    text-shadow: 0 4px 14px rgba(40, 39, 42, 0.1);
    font-size: 48px;
    font-weight: 700;
    line-height: 72px;
    text-transform: uppercase;
    margin-bottom: 24px;
    width: 100%;
    max-width: 320px;
  }

  &__name {
    color: #fff;
    font-size: 17px;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px;
  }

  &__terms {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration-line: underline;
    border: none;
    outline: none;
    background: transparent;
  }

  .is-margin-right {
    margin-inline-end: 24px;
  }
}
