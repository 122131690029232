$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.trustly-form {

  padding-bottom: 48px;

  &__title {
    @include text(32px, 44px, bold);
    background-color: $color-yellow;
    @include text-dots;
    padding: 10px 48px;
    border-radius: 20px 20px 0 0;
    font-family: var(--font-family-main);
  }
  &__logo {
    height: 36px;
    margin-top: 18px;
  }
  &__text {
    letter-spacing: 0.03em;
    margin-top: 14px;
    @include text(14px, 20px);
  }
  &__btn-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 20px;
    margin: 8px 76px 32px;
  }
  &__btn-item {
    width: 132px;
    border: 2px solid $color-grey-middle;
    border-radius: 12px;
    @include text(24px, 52px, bold);
    font-family: var(--font-family-main);
    padding: 0 12px;
    background: $color-white;
    max-height: 52px;

    &.is-active {
      background: $color-yellow;
      box-shadow: inset 0 12px 15px rgba(255, 255, 255, 0.8), inset 0px -12px 15px $color-orange, inset 0px 1px 1px rgba(233, 145, 16, 0.2);
      border: none;
    }

  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__btn {
    margin-top: 32px;
  }

}
