.summer-countdown {
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
  font-family: $f-jost;
  color: $c-white;

  &__title {
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    margin-right: 5px;
    opacity: .6;
  }

  .summer-timer {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }
}
