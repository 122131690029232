$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
@font-face {
  font-family: 'Fez';
  src: url($cdn + '/fezbet/fonts/PFDinTextCondPro-Bold.woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;

}
@font-face {
  font-family: 'Fez';
  src: url($cdn + '/fezbet/fonts/PFDinTextCondPro-Regular.woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
