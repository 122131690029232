$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.gh-home {
  margin-top: 16px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(5, 1fr);

  &__item:nth-child(19) ~ .gh-home__item {
    display: none;
  }

  @include bp1280 {
    grid-template-columns: repeat(6, 1fr);

    &__item:nth-child(19) ~ .gh-home__item {
      display: block;
    }
  }
}
