$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup__wrap.shop-has-no-money {
  padding: 56px 40px 60px;
}

.shop-has-no-money {
  .popup__title {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    text-transform: none;
    margin-bottom: 16px;
  }

  .popup__content {
    color: $color-black;
    animation: none;
  }

  &__info {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 28px;
    color: $color-grey;

    span {
      font-weight: 700;
      margin-left: 4px;
      color: $color-black;
    }
  }

  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.4px;
    margin-bottom: 16px;
    text-align: center;
  }

  &__icon {
    display: block;
    width: 150px;
    height: 150px;
    background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto 12px;
  }

  &__btns {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;

    .btn-44-yellow {
      width: fit-content;
      padding: 0 35px;
      margin-bottom: 16px;
    }

    a {
      width: fit-content;
    }
  }
}
