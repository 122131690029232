$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.wgt-last-deposit{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(to left, rgba(246,246,244, 0.6),  rgba(246,246,244, 0.6)), url( $cdn + '/fezbet/profile/fastdep.png' );
    background-position: center, right -40px bottom -40px;
    background-repeat: no-repeat;
    background-size: 100%, 200px 160px;
    background-color: $color-gray-light;
    border-radius: 20px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.is-stub {
        background-image: url( $cdn + '/fezbet/profile/fastdep.png' );
        background-position: right bottom;
        background-size: 200px 160px;
        background-color: $color-yellow;
        padding: 24px 40px 48px 24px;
        justify-content: space-between;

        &:lang(ar) {
            background-position: -24% bottom;
        }
    }
    &__title {
        @include text(18px, 24px, bold, $color-grey);
        font-family: var(--font-family-main);
        text-transform: uppercase;
        max-height: 48px;
        overflow: hidden;

        &.is-stub {
            @include text(24px, 32px, bold, $color-black);
            max-height: 128px;
        }
    }
    &__pay {
        display: flex;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 32px;
    }
    &__icon-pay {
        width: 120px;
        height: auto;
        margin-right: 16px;
        flex-shrink: 0;
    }
    &__sum {
        background: #FFFFFF;
        border: 2px solid #E7E7E4;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 0 12px;

        @include text(24px, 40px, bold, $color-black);
        @include text-dots;
        font-family: var(--font-family-main);
        min-width: 120px;
        text-align: center;
        &::placeholder{
            color: $color-black;
        }
    }


    @include bp1440 {
        padding: 36px 32px;
        justify-content: space-between;

        &.is-stub {
            padding: 40px 32px 48px;
        }
        &__title.is-stub {
            @include text(28px, 40px, bold, $color-black);
            max-height: 120px;
        }
        &__icon-pay {
            width: 168px;
            margin-right: 16px;
        }
        &__pay {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    @include bp1680 {
        background-position: right -28px bottom -40px;
    }
  }
