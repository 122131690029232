.christmas-link {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 800;
  line-height: 21px;
  color: #87f0fc;
  text-decoration: none;
  border: none;
  background-color: transparent;
  transition: opacity 0.1s;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.8;
  }

  &.is-terms {
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
  }

  &.has-arrow {
    &::after {
      content: '';
      display: block;
      padding-right: 8px;
      width: 16px;
      height: 21px;
      background-image: url($cdn + '/holidays/christmas/arrow.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      filter: invert(76%) sepia(55%) saturate(375%) hue-rotate(153deg) brightness(105%) contrast(98%);
    }
  }
}
