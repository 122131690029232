$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-select {
  background-color: #fff;

  select {
    @include text(16px, 40px, null, $color-black);

    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    max-width: 100%;
    padding: 0;
    padding-inline-start: 16px;
    padding-inline-end: 36px;
    -moz-appearance: none;
    -webkit-appearance: none;
    position: relative;
    border-radius: 4px;
    border: 1px solid #868a92;
    background-color: transparent;

    .form-box:hover &,
    &:focus {
      border: 1px solid $color-black;
    }

    &.ng-untouched.ng-empty {
      color: #A7AFBE;
    }

    &[disabled] {
      background-color: rgba(134, 138, 146, 0.3);
      color: $color-grey;
    }

    &:lang(ar) {
      .long-name {
        direction: ltr;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 12px;
    inset-inline-end: 12px;
    background-image: var(--icon-arrow-down);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
    width: 18px;
    height: 18px;
    display: block;
    filter: $filter-grey;
  }

  &:hover::before {
    filter: none;
  }
}
