$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-headers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__title {
    color: #19191d;
    display: inline;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 8px;
    background-color: #ffdc26;
    margin-bottom: 12px;
    padding: 0 15px;
  }
  &__name {
    color: #ffdc26;
    text-shadow: 0px 3.913043260574341px 14.673912048339844px rgba(40, 39, 42, 0.1);
    font-size: 60px;
    font-weight: 700;
    line-height: 72px;
    text-transform: uppercase;
    margin-bottom: 12px;
    text-align: center;
  }
  &__subtitle {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    text-align: center;
  }
}
