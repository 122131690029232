.easter-btn {
  display: flex;
  align-items: flex-start;
  height: 88px;
  background: $c-green;
  box-shadow: 0px 5px 8px rgba(50, 50, 50, 0.3);
  border-radius: 66px;
  position: relative;
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  &.is-height {
    height: 64px;
    .easter-btn__inner {
      height: 60px;
    }
    .easter-btn__text{
      font-size: 28px;
    }
  }
  &:hover {
    text-decoration: none;

    .easter-btn__inner {
      &:after {
        background: linear-gradient(150.67deg, #77EB41 17.99%, #4EA226 51.48%), linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
        box-shadow: inset 1px 1px 0px rgba(246, 243, 187, 0.4), inset -1px -1px 1px #319641, inset 0px -2px 8px #ACC83C;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    background-image: url($cdn + '/easter/2023/elements/shine-btn.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 14px;
    width: 30px;
    top: 8px;
    left: 14px;
    pointer-events: none;
    z-index: 4;
  }

  .easter-info & {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -35px;
    margin: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 84px;
    padding: 0 43px;
    background-image: linear-gradient(149.95deg, #77EB41 18.32%, #4EA226 84.78%), linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
    box-shadow: inset 1px 1px 0px rgba(246, 243, 187, 0.4), inset -1px -1px 1px rgba(106, 210, 54, 0.4);
    border-radius: 66px;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      right: 6px;
      left: 4px;
      bottom: 4px;
      background-image: linear-gradient(149.95deg, #77EB41 18.32%, #4EA226 84.78%), linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
      box-shadow: inset 1px 1px 0px rgba(246, 243, 187, 0.4), inset -1px -1px 1px #76D846, inset 0px -10px 8px #69C83C;
      border-radius: 66px;
      transition-property: background-image, box-shadow;
      transition-duration: .2s;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 12px;
      right: 17px;
      left: 17px;
      height: 9px;
      background-image: linear-gradient(180deg, rgba(240, 243, 177, 0) 0%, #CAE480 100%);
      opacity: 0.3;
      border-radius: 50px;
      z-index: 2;
    }
  }

  &__text {
    display: block;
    width: 100%;
    position: relative;
    text-align: center;
    z-index: 5;
    font-family: $ubuntu;
    font-weight: 700;
    font-size: 40px;
    line-height: 1em;
    color: $c-white;
    -webkit-text-stroke: 1px $c-green1;
    text-shadow: 1px 2px 0 $c-green1, -1px 2px 0 $c-green1, 1px 2px 0 $c-green1;

    .easter-info & {
      font-size: 24px;
    }
  }
}
.swiper-button-disabled {
  display: none;
}