$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.article-popup {
  overflow: auto;
  max-height: calc(100vh - 277px);
  margin-top: 20px;
  @include text(16px, 24px, null, $color-black);
  position: relative;

  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0;
  }
  & h2 {
    background: none;
    text-align: center;
    margin-left: 0;
    padding-top: 0;
  }
  .ferrari-terms-title {
    font-family: $ff;
    text-align: center;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    background-image: none;
    margin: 0 0 15px;
    padding-top: 48px;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }
  & p {
    margin: 8px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  & ul {
    list-style: disc;
    padding-inline-start: 24px;
  }

  & a {
    text-decoration: underline;
    color: $color-black;

    &:hover {
      color: $color-grey;
    }
  }

  & table {
    width: 520px;
    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  & tr:not(:last-child) {
    border-bottom: solid 1px $color-grey;

  }

  & td {
    @include text(16px, 18px);
    padding-top: 4px;
    padding-bottom: 4px;

    &:first-child {
      font-weight: bold;
      width: 70%;
      padding-right: 10px;
    }

    &:nth-child(2) {
      text-align: right;
    }
  }

  & tr:first-child {
    & td {
      padding-bottom: 8px;
    }
    & td:first-child {
      font-size: 18px;
    }
  }
}
.is-car-padding {
  padding-bottom: 20px;
}
.holiday-terms .article-popup {
  ol {
    counter-reset: index;
    list-style-position: inside;

    ::marker {
      display: none;
      font-size: 0;
    }

    li {
      margin-bottom: 0.5em;

      &::before {
        font-weight: bold;
        counter-increment: index;
        content: counters(index, '.') '. ';
      }
    }

    ol {
      margin: 0.5em 0 0 2em;
    }
  }
}
