$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-promotion {
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: -60px;
}
.ferrari-promo {
  background-color: #19191d;
  &__banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 48px 0 32px 0;
    height: 700px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .is-active-banner-head {
    background-image: url('/joxi/fezbet/ferrari-promo/Desktop-Active.jpg');
  }
  .is-inactive-banner-head {
    background-image: url('/joxi/fezbet/ferrari-promo/Desktop-Coming-soon.jpg');
  }

  .show-right-tooltip .ferrari-tooltip.is-right {
    animation: fadeInOutRight 0.8s linear forwards;
    display: block;
    opacity: 1;

    &:lang(ar) {
      animation: fadeInOutRightAr 0.8s linear forwards;
    }
  }
  .show-center-tooltip .ferrari-tooltip.is-center {
    animation: fadeInOutCenter 0.8s linear forwards;
    display: block;
    opacity: 1;
    &:lang(ar) {
      animation: fadeInOutCenterAr 0.8s linear forwards;
    }
  }
  @keyframes fadeInOutCenter {
    0% {
      opacity: 0;
      transform: translate(80px, -15px);
    }
    20% {
      opacity: 1;
      transform: translate(80px, -30px);
    }
    40% {
      transform: translate(80px, -15px);
    }
    60% {
      transform: translate(80px, -25px);
    }
    80% {
      transform: translate(80px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(80px, -15px);
    }
  }
  @keyframes fadeInOutRight {
    0% {
      opacity: 0;
      transform: translate(192px, -15px);
    }
    20% {
      opacity: 1;
      transform: translate(192px, -30px);
    }
    40% {
      transform: translate(192px, -15px);
    }
    60% {
      transform: translate(192px, -25px);
    }
    80% {
      transform: translate(192px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(192px, -15px);
    }
  }
  @keyframes fadeInOutCenterAr {
    0% {
      opacity: 0;
      transform: translate(-90px, -15px);
    }
    20% {
      opacity: 1;
      transform: translate(-90px, -30px);
    }
    40% {
      transform: translate(-90px, -15px);
    }
    60% {
      transform: translate(-90px, -25px);
    }
    80% {
      transform: translate(-90px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(-90px, -15px);
    }
  }
  @keyframes fadeInOutRightAr {
    0% {
      opacity: 0;
      transform: translate(-199px, -15px);
    }
    20% {
      opacity: 1;
      transform: translate(-199px, -30px);
    }
    40% {
      transform: translate(-199px, -15px);
    }
    60% {
      transform: translate(-199px, -25px);
    }
    80% {
      transform: translate(-199px, -15px);
    }
    100% {
      opacity: 1;
      transform: translate(-199px, -15px);
    }
  }
}
