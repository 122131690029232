$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.bet-history {
  &__wrap {
    padding: 20px 0 0 24px;
    background-color: #F6F6F4;
    border-radius: 20px;

    &::after {
      content: "";
      position: absolute;
      border-radius: 0 20px 20px 0;
      width: 52px;
      height: 100%;
      right: 0;
      top: 0;
      display: block;
      background-image: linear-gradient(270deg, #F6F6F4 0%, transparent 100%), linear-gradient(270deg, #F6F6F4, #F6F6F4);
      background-size: 32px 100%, 20px 100%;
      background-repeat: no-repeat;
      background-position: left center, right center;
    }

  }
  &__top {
    padding: 0 52px 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__link {
    margin-right: 16px;
    flex-shrink: 0;
    margin-left: auto;
    @include text-dots;
    max-width: 30%;
  }
  .slick-slide {
    width: 300px !important;
    margin-right: 24px;
  }
  &__item {
    background: $color-white;
    box-shadow: 0 4px 8px rgba(25, 25, 29, 0.2);
    border-radius: 20px;
    color: $color-black;
    padding: 14px 20px 0;
    width: 300px !important;
    margin-bottom: 28px;
  }
  &__item-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  &__date-block {
    letter-spacing: 0.03em;
    display: flex;
    flex-direction: column;
    @include text-dots;
  }
  &__date {
    @include text(14px, 20px, bold);
  }
  &__time {
    @include text(24px, 28px, bold);
    font-family: var(--font-family-main);
  }
  &__logos {
    margin-left: 40px;
    flex-shrink: 0;
    display: flex;
  }
  &__logo {
    width: 49px;
    height: 49px;
    border: solid 1px $color-grey;
    background-color: #F6F6F4;
    border-radius: 50%;
    padding: 9px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;

    &:first-child {
        margin-right: -7px;
    }
  }
  &__league {
    @include text(12px, 16px, null, $color-grey);
    margin-bottom: 8px;
    @include text-dots;
  }
  &__league-span {
    padding-left: 5px;

    &::before {
      content: "\2022";
      padding-right: 5px;
    }
  }
  &__comand {
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.03em;
    @include text(16px, 20px, bold);
    margin-bottom: 8px;

    span{
      @include text-dots;
    }
  }
  &__comand-span {
    flex-shrink: 0;
    padding-left: 5px;
  }
  &__row {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 16px;
    @include text(12px, 24px);
  }
  &__key {
    color: $color-grey;
    @include text-dots;
  }
  &__value {
    font-weight: bold;
    text-align: right;
    @include text-dots;
  }
  &__total {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 7px;
    background: #E7E7E4;
    border-radius: 0 0 20px 20px;
    padding: 12px 20px;
    @include text(16px, 20px, bold);
    letter-spacing: 0.03em;
    margin: 4px -20px 0;

    &.is-win {
      background: $color-yellow;
    }

    span {
      @include text-dots;
    }

  }
  &__total-sum {
    text-align: right;
  }

  &__empty {
    padding: 0 24px;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F6F6F4;
    border-radius: 20px;
  }
  &__title {
    @include text(24px, 28px, bold, $color-black);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-family: var(--font-family-main);
    margin-right: 16px;
    @include text-dots;
  }
  &__btn {
    flex-shrink: 0;
    max-width: 50%;
  }
  &__slider {
    cursor: pointer;
  }

  //arrows

  &__arr {
    position: absolute;
    top: 109px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(45, 45, 50, 0.8);
    border: none;
    z-index: 1;
    opacity: 0;
    transition: 0.3s;

    &.slick-disabled {
      display: none !important;
    }

    &:hover {
      background: rgba(25, 25, 29, 0.8);
    }

    linda-slick:hover & {
      opacity: 1;
      transition: 0.3s;
    }

    &::before {
      content: "";
      width: 24px;
      height: 24px;
      filter: $filter-yellow;
      background-image: var(--icon-arrow-left);
      background-size: 24px;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.is-prev {
      left: 0px;
    }
    &.is-next {
      right: 20px;

      &::before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }

  @include bp1680 {
    &__wrap {
      padding: 24px 0 0 32px;
    }
    &__item {
      margin-bottom: 40px;
    }
    &__empty {
      padding: 0 32px;
      height: 84px;
    }
    &__link,
    &__title {
      margin-right: 20px;
    }
  }
}
