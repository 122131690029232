$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-search{
  position: relative;
  display: block;
  width: 100%;
  height: 48px;
  border-radius: 24px;
  background: rgba(255, 220, 38, 0.3);
  padding-inline-start: 28px;
  padding-inline-end: 44px;

  font-weight: bold;
  font-size: 15px;
  line-height: 48px;
  color: #FFFFFF;

  @include text-dots;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 48px;
    height: 48px;
    top: 0;
    inset-inline-end: 2px;
    background-image: var(--icon-search);
    filter: $filter-white;
  }

  &:hover{
    background: rgba(255, 220, 38, 0.1);

    &::before {
      filter: $filter-yellow;
    }
  }
}
