$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-timer {
  display: flex;

  .rtl-lang & {
    direction: ltr;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.is-accent {
      margin-right: 16px;

      &._grey {
        opacity: 0.5;
      }
    }
  }

  &__time {
    @include text(36px, 44px, 700, $color-white);
  }

  &__label {
    @include text(12px, 16px, 400, $color-white);
  }

  &__colon {
    width: 16px;
    height: 40px;
    background-position: center top;
    background-repeat: no-repeat;
    margin-top: 4px;
  }
}
