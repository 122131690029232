$build: 1739274415624;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.coming-soon {
  position: relative;
  background-position: left center;
  background-size: 62%;
  background-repeat: no-repeat;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: Roboto, sans-serif;
  padding: 30px 0;

  &:lang(ar) {
    justify-content: flex-start;
  }

  @include bp1280 {
    min-height: 450px;
    padding: 40px 0;
  }

  @include bp1680 {
    min-height: 500px;
    padding: 55px 22px;
  }

  &--content {
    width: 40%;

    &_subtitle {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.15;
      text-transform: uppercase;
      color: rgba($color-white, .6);
      letter-spacing: 0.1em;
      margin-bottom: 7px;

      @include bp1280 {
        font-size: 28px;
      }

      @include bp1440 {
        font-size: 32px;
      }

      [lang="de"] &,
      [lang="no"] & {
        font-size: 22px;

        @include bp1280 {
          font-size: 26px;
        }
      }

      [lang="it"] &,
      [lang="cl"] &,
      [lang="pe"] & {
        font-size: 18px;

        @include bp1280 {
          font-size: 22px;
        }

        @include bp1440 {
          font-size: 26px;
        }
      }

      [lang="fr"] & {
        font-size: 16px;

        @include bp1280 {
          font-size: 20px;
        }

        @include bp1440 {
          font-size: 22px;
        }
      }

      &-date {
        position: relative;
        display: inline-block;
        padding-left: 16px;

        @include bp1280 {
          padding-left: 30px;
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 4px;
          transform: translateY(-50%);
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: rgba($color-white, .6);

          @include bp1280 {
            left: 10px;
          }
        }
      }
    }

    &_title {
      font-size: 52px;
      line-height: 1;
      font-weight: 700;
      margin-bottom: 40px;
      font-family: var(--font-family-main);
      display: grid;

      @include bp1280 {
        font-size: 60px;
      }

      @include bp1440 {
        font-size: 72px;
      }
    }
  }

  &--action {
    position: absolute;
    inset: 0;
		background: none;
		border: none;
  }

  .text-yellow {
    color: #FFDC26;
  }

  .btn {
    position: relative;
    z-index: 1;
  }
}
